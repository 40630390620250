var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-9"},[_c('div',{staticClass:"card m-b-20"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label",attrs:{"for":"pageName"}},[_vm._v("Sayfa Başlığı")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":"pageName","placeholder":"Sayfa Başlığı","autocomplete":"off"},on:{"keyup":_vm.shortLinkGenerator}})])]),_vm._m(1)]),_c('vue-bottom-sheet',{ref:"myBottomSheet",attrs:{"max-width":"95%"}},[_c('FileUploadComponent')],1),_c('br'),_vm._m(2)],1)])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"card m-b-20"},[_vm._m(3),_c('div',{staticClass:"card-body"},[_c('button',{staticClass:"btn btn-primary w-100 mb-2",attrs:{"id":"saveButton"},on:{"click":_vm.save}},[_c('i',{staticClass:"fa fa-save"}),_vm._v(" Kaydet ")]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-12"},[_vm._m(4),_c('TagListComponent',{attrs:{"tags":_vm.tags},on:{"tagList":function($event){return _vm.tagL($event)}}})],1),_vm._m(5)]),_vm._m(6)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h3',{staticClass:"card-title"},[_vm._v("Yeni Sayfa Ekle")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label",attrs:{"for":"shortName"}},[_vm._v("URL")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":"shortName","placeholder":"URL","disabled":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row row-cards"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-title"},[_vm._v("Sayfa İçeriği")])]),_c('div',{staticClass:"card-body"},[_c('div',{attrs:{"id":"content"}},[_c('p')])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h3',{staticClass:"card-title"},[_vm._v("Sayfa Ayarları")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label",attrs:{"for":"shortName"}},[_vm._v("Açıklama")]),_c('textarea',{staticClass:"form-control",attrs:{"type":"text","name":"pageDescription","placeholder":"Sayfa Açıklaması"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label",attrs:{"for":"shortName"}},[_vm._v("Etiket")]),_c('small',[_vm._v("*Lütfen virgül ile ayırınız")]),_c('textarea',{staticClass:"form-control",attrs:{"type":"text","name":"pageTags","placeholder":"Sayfa Etiketleri"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('div',{staticClass:"form-label"},[_vm._v("Sayfa Durumu")]),_c('label',{staticClass:"form-switch"},[_c('input',{staticClass:"form-switch-input",attrs:{"type":"checkbox","name":"status"}}),_c('span',{staticClass:"form-switch-indicator"}),_c('span',{staticClass:"form-switch-description"},[_vm._v("Sayfanın durumunu yayınlandı olarak değiştir")])])])
}]

export { render, staticRenderFns }