<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="panel panel-primary">
                <div>
                    <div class="user-tabs mb-4">
                        <!-- Tabs -->
                        <ul class="nav panel-tabs">
                            <li class=""><a href="#tab1" data-bs-toggle="tab" class="active">Tümü ({{ totalsize }})</a>
                            </li>
                        </ul>
                    </div>

                </div>

            </div>
            <div class="card">
                <div class="card-body">
                    <div class="tab-content">
                        <div class="tab-pane active " id="tab1">
                            <div class="mail-option">
                                <div class="chk-all">
                                    <div class="btn-group">
                                        <a data-bs-toggle="dropdown" href="#" class="btn mini all"
                                            aria-expanded="false">
                                            Toplu İşlemler
                                            <i class="fa fa-angle-down "></i>
                                        </a>
                                        <ul class="dropdown-menu">
                                            <li><a href="#">Seçilileri Sil</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive border-top">
                                <table class="table card-table table-bordered table-hover table-vcenter text-nowrap">
                                    <tbody>
                                        <tr>
                                            <th class="w-1">
                                                <label class="custom-control form-checkbox">
                                                    <input type="checkbox" class="custom-control-input" name="checkbox"
                                                        value="checkbox">
                                                    <span class="custom-control-label"></span>
                                                </label>
                                            </th>
                                            <th class="w-1">Hizmet Başlığı</th>
                                            <th class="w-1">Ekleyen</th>
                                            <th>Oluşturulma</th>
                                            <th>İşlem</th>
                                        </tr>
                                        <tr v-for="item, i in list" :key="i">
                                            <th>
                                                <label class="custom-control form-checkbox">
                                                    <input type="checkbox" class="custom-control-input" name="checkbox"
                                                        value="checkbox">
                                                    <span class="custom-control-label"></span>
                                                </label>
                                            </th>
                                            <td>{{ item.title }}</td>
                                            <td>{{ item.fullname }}</td>
                                            <td>{{ item.created_at }}</td>
                                            <td>
                                                <div class="btn-list"><button
                                                        class="btn bg-primary-transparent text-primary"
                                                        @click="update(item.id)">Güncelle</button>
                                                    <button class="btn bg-danger-transparent text-primary"
                                                        @click="remove(item.id)">Sil</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ul class="unstyled inbox-pagination">
                                    <li><span>{{ (currentpage - 1) * perpage }}-{{ (currentpage - 1) * perpage + perpage
                                    }} arasında,
                                            Toplam {{ totalsize }} kayıt</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ul class="pagination mb-5">
                <li :class="'page-item page-prev ' + (currentpage == 1 ? 'disabled' : '')">
                    <a class="page-link" href="#" tabindex="-1">Önceki</a>
                </li>
                <li v-for="s, i in pagesize" :key="i" :class="'page-item ' + (i + 1 == currentpage ? 'active' : '')">
                    <button class="page-link">{{ i + 1 }}</button>
                </li>
                <li :class="'page-item page-next ' + (currentpage == pagesize || currentpage == 1 ? 'disabled' : '')">
                    <a class="page-link" href="#">Sonraki</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
export default {
    name: 'ServiceRequestList',
    components: {
    },
    created() {
        document.title = "Hizmet Talep Listesi"
        this.$store.state.header = true;
        this.$store.state.bread_crumb_title = "Hizmet Talep Listesi"
        this.$store.state.bread_crumb_items = []
        this.$store.state.bread_crumb_items.push({ url: '/dashboard', name: "Gösterge Paneli" });
        this.$store.state.bread_crumb_items.push({ url: '', name: "Hizmet Talep Listesi" });
        this.getServiceList();
    },
    data() {
        return {
            list: [],
            currentpage: 1,
            perpage: 50,
            totalsize: 0,
            pagesize: 0,
        }
    },
    methods: {
        getServiceList() {
            let blogInfo = {
                begin: (this.currentpage - 1) * this.perpage,
                perpage: this.perpage
            }
            this.$store.dispatch("serviceRequestList", blogInfo).then((value) => {
                this.list = value.list
                this.totalsize = value.size
                this.pagesize = value.pages
            })
        },
        update(id) {
            this.$router.push("/service/request/update/"+ id);
        },
        remove(id) {
            Swal.fire({
                title: 'Hizmet silinecek?',
                text: "Bu işlem geri dönüşü olmayan sonuçlar ortaya çıkarabilir!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sil',
                cancelButtonText: 'İptal'
            }).then((result) => {
                if (result.isConfirmed) {
                    let blogInfo = {
                        id: id
                    }
                    this.$store.dispatch("serviceRequestRemove", blogInfo).then((value) => {
                        Swal.fire(
                            'Silindi!',
                            'Hizmet silme işlemi tamamlandı',
                            'success'
                        )
                        this.getServiceList();
                    })

                }
            })

        },
    }
}
</script>
