<template>
  <div class="form-group col-md-12">
    <div id="append" @click="dialog"></div>
    <label for="subCategory" class="col-form-label">{{ title }}</label>
    <div class="form-control select2" style="cursor: pointer" @click="dialog">
      {{ placeholder }}
    </div>
    <div class="selectedFind" v-if="find">
      <div class="form-group col-md-12">
        <label for="sort" class="col-form-label"></label>
        <input
          type="text"
          placeholder="Sonuçlarda Arayın"
          @keyup="search"
          class="form-control"
          id="sort"
          name="sort"
        />
      </div>
      <ul class="list-group">
        <template v-if="multiple">
          <li class="list-group-item" v-for="(item, i) in data" :key="i">
            <label class="custom-control form-checkbox">
              <input
                @click="list(item[valueItem], item[showItem])"
                type="checkbox"
                class="custom-control-input"
                name="checkbox"
                value="checkbox"
                :checked="
                  findItemSelected(item[valueItem], valueItem) == 1
                    ? true
                    : false
                "
              />
              <span class="custom-control-label"></span>
              <div>{{ item[showItem] }}</div>
            </label>
          </li>
        </template>
        <template v-else>
          <li
            class="list-group-item"
            v-for="(item, i) in data"
            :key="i"
            @click="select(item[valueItem], item[showItem])"
          >
            <span>{{ item[showItem] }}</span>
          </li>
        </template>
      </ul>

      <div class="btn-group float-right m-3">
        <template v-if="multiple">
          <button class="btn btn-warning" @click="sendList">
            Seçilenleri Onayla
          </button>
        </template>
        <button class="btn btn-danger" id="removeButton" @click="dialog">
          Kapat
        </button>
      </div>
    </div>
  </div>
</template>
<style scoped>
#append {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  opacity: 0.2;
  display: none;
  background: black;
  height: -webkit-fill-available;
}

.selectedFind {
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #ececec;
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 5001;
  box-shadow: 0px 0px 10px 0px #b9b9b9;
  border-radius: 10px;
}

.blank {
  margin-top: 10px;
}

.list-group {
  max-height: 300px;
  overflow-y: scroll;
}
.list-group-item {
  cursor: pointer;
  background: #eaeaea;
  margin-left: 12px;
  margin-right: 12px;
}

.float-right {
  float: right;
  margin-left: 5px;
}
</style>
<script>
export default {
  props: [
    "title",
    "placeholder",
    "action",
    "selected",
    "showItem",
    "valueItem",
    "searchItem",
    "multiple",
    "alternateValueItem",
  ],
  created() {},
  data() {
    return {
      find: false,
      data: [],
      yedek: [],
      sList: [],
      sListShowItems: [],
    };
  },
  methods: {
    list(valueItem, value) {
      let bool = true;
      this.sList.forEach((e, index) => {
        if (e == valueItem) {
          let s = 0;
          let c = 0;
          if (
            this.selected &&
            this.selected != null &&
            this.selected.lenght > 0
          ) {
            this.selected.forEach((i) => {
              if (i[this.valueItem] == valueItem) {
                c = s;
              }
              s++;
            });
            this.selected.splice(c, 1);
          }
          this.sList.splice(index, 1);
          this.sListShowItems.splice(index, 1);
          bool = false;
        }
      });
      if (bool) {
        let b = true;
        if (
          this.selected &&
          this.selected != null &&
          this.selected.lenght > 0
        ) {
          this.selected.forEach((i) => {
            if (i[this.valueItem] == valueItem) {
              b = false;
            }
          });
          if (b) {
            this.selected.push({ id: valueItem });
          }
        }
        this.sList.push(valueItem);
        this.sListShowItems.push(value);
      }
    },
    getList() {
      let info = {
        begin: 0,
        ///perpage: 10,
      };
      this.$store.dispatch(this.action, info).then((value) => {
        if (this.selected != null && this.selected.lenght > 0) {
          this.placeholder == this.selected;
          value.list.forEach((element) => {
            if (element.id == this.selected) {
              this.$emit("response", [
                element[this.valueItem],
                element[this.searchItem],
              ]);
            }
          });
        }
        this.data = value.list;
        this.yedek = value.list;
      });
    },
    dialog() {
      this.getList();
      if (this.selected && this.selected != null && this.selected.lenght > 0) {
        this.sList = [];
        this.sListShowItems = [];
        this.selected.forEach((e) => {
          this.data.forEach((i) => {
            if (i[this.valueItem] == e[this.valueItem]) {
              this.sList.push(e[this.valueItem]);
              this.sListShowItems.push(i[this.showItem]);
            }
          });
        });
      }
      if (this.find) {
        this.find = false;
        document.getElementById("append").style.display = "none";
      } else {
        this.find = true;
        document.getElementById("append").style.display = "block";
      }
    },
    search(e) {
      let _this = this;
      let searchText = e.target.value;
      if (searchText == null || searchText == "") {
        this.data = this.yedek;
      } else {
        this.data = this.yedek.filter((e) => e[_this.searchItem].toUpperCase().includes(searchText.toUpperCase()));
      }
    },
    sendList() {
      console.log(this.sList);
      this.$emit("response", [this.sList, this.sListShowItems]);
      this.sList = [];
      this.sListShowItems = [];
      this.find = false;
      document.getElementById("append").style.display = "none";
    },
    findItemSelectedItem(item, index) {
      let i;
      this.selected.forEach((e) => {
        if (e[index] == item) {
          i = e;
        }
      });
      return i;
    },
    findItemSelected(item, index) {
      let i = 0;
      if (this.selected && this.selected != null) {
        this.selected.forEach((e) => {
          if (e[index] == item) {
            i++;
          }
        });
      }
      if (i == 0) {
        return 0;
      } else {
        return 1;
      }
    },
    select(valueItem, value) {
      this.$emit("response", [valueItem, value]);
      this.find = false;
      document.getElementById("append").style.display = "none";
    },
  },
};
</script>