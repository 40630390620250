<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="panel panel-primary">
        <div>
          <div class="user-tabs mb-4">
            <!-- Tabs -->
            <ul class="nav panel-tabs">
              <li class="" @click="approvedProfileChange(2)">
                <a class="btn btn-primary btn-sm" data-bs-toggle="tab">
                  Tümü ({{ totalsize[0] }})
                </a>
              </li>
              <li class="" @click="approvedProfileChange(1)">
                <a class="btn btn-success btn-sm" data-bs-toggle="tab">
                  Onaylanmış ({{ totalsize[1] }})
                </a>
              </li>
              <li class="" @click="approvedProfileChange(0)">
                <a class="btn btn-warning btn-sm" data-bs-toggle="tab">
                  Onay Bekleyen ({{ totalsize[2] }})
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="tab-content">
            <div class="tab-pane active" id="tab1">
              <div class="mail-option">
                <div class="chk-all">
                  <div class="btn-group">
                    <a
                      data-bs-toggle="dropdown"
                      href="#"
                      class="btn mini all"
                      aria-expanded="false"
                    >
                      Toplu İşlemler
                      <i class="fa fa-angle-down"></i>
                    </a>
                    <ul class="dropdown-menu">
                      <li><a href="#">Seçilileri Sil</a></li>
                    </ul>
                  </div>
                </div>

                <ul class="unstyled inbox-pagination">
                  <li>
                    <div
                      class="form-inline nav-search"
                      style="
                        padding: 0 !important;
                        padding-right: 10px !important;
                      "
                    >
                      <input
                        type="search"
                        class="form-control header-search"
                        placeholder="Yorumlarda Ara"
                        aria-label="Ara"
                        @keyup="getPageFilter"
                        v-model="search"
                      />
                      <button
                        class="btn btn-primary"
                        @click="getCommentList"
                        type="button"
                      >
                        <i class="fa fa-search"></i>
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="table-responsive border-top">
                <table
                  class="
                    table
                    card-table
                    table-bordered table-hover table-vcenter
                    text-nowrap
                  "
                >
                  <tbody>
                    <tr>
                      <th class="w-1">
                        <label class="custom-control form-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            name="checkbox"
                            value="checkbox"
                          />
                          <span class="custom-control-label"></span>
                        </label>
                      </th>
                      <th class="w-1">Eğitim Başlığı</th>
                      <th class="w-1">Yorumu Yapan</th>
                      <th>Yorum</th>
                      <th>Durum</th>
                      <th>İşlem</th>
                    </tr>
                    <tr v-for="(item, i) in list" :key="i">
                      <th>
                        <label class="custom-control form-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            name="checkbox"
                            value="checkbox"
                          />
                          <span class="custom-control-label"></span>
                        </label>
                      </th>
                      <td>
                        {{ item.title }}
                      </td>
                      <td>{{ item.fullname }}</td>
                      <td>{{ item.content }}</td>
                      <td>
                        <template v-if="item.approved == 0"
                          ><span class="status-icon bg-danger"></span>
                          Onaysız</template
                        >
                        <template v-if="item.approved == 1"
                          ><span class="status-icon bg-success"></span>
                          Onaylı</template
                        >
                      </td>
                      <td>
                        <div class="btn-list">
                          <button
                            class="btn bg-primary-transparent text-primary"
                            @click="update(item.id)"
                          >
                            Onayla
                          </button>
                          <button
                            class="btn bg-danger-transparent text-primary"
                            @click="remove(item.id)"
                          >
                            Sil
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <ul class="unstyled inbox-pagination">
                  <li>
                    <span
                      >{{ (currentpage - 1) * perpage }}-{{
                        (currentpage - 1) * perpage + perpage
                      }}
                      arasında, Toplam {{ totalsize[approved] }} kayıt</span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul class="pagination mb-5">
        <li
          :class="'page-item page-prev ' + (currentpage == 1 ? 'disabled' : '')"
        >
          <a class="page-link" href="#" tabindex="-1">Önceki</a>
        </li>
        <li
          v-for="(s, i) in pagesize"
          :key="i"
          :class="'page-item ' + (i + 1 == currentpage ? 'active' : '')"
        >
          <router-link
            :to="'/comment/education/list/' + (i + 1)"
            class="page-link"
            >{{ i + 1 }}</router-link
          >
        </li>
        <li
          :class="
            'page-item page-next ' +
            (currentpage == pagesize || currentpage == 1 ? 'disabled' : '')
          "
        >
          <a class="page-link" href="#">Sonraki</a>
        </li>
      </ul>
    </div>
  </div>
</template>
  
  <script>
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "MemberList",
  components: {},
  created() {
    document.title = "Eğitim Yorumları Listesi";
    this.$store.state.header = true;
    this.$store.state.bread_crumb_title = "Eğitim Yorumları Listesi";
    this.$store.state.bread_crumb_items = [];
    this.$store.state.bread_crumb_items.push({
      url: "/dashboard",
      name: "Gösterge Paneli",
    });
    this.$store.state.bread_crumb_items.push({
      url: "",
      name: "Eğitim Yorumları Listesi",
    });
    if (this.$route.params.page && this.$route.params.page != null) {
      this.currentpage = this.$route.params.page;
    }
    this.getCommentList();
  },
  data() {
    return {
      list: [],
      currentpage: 1,
      perpage: 25,
      totalsize: 0,
      pagesize: 0,
      approved: 1,
      search: "",
    };
  },
  methods: {
    approvedProfileChange(i) {
      this.approved = i;
      this.getCommentList();
    },
    getCommentList() {
      this.list = [];
      let memberInfo = {
        begin: (this.currentpage - 1) * this.perpage,
        perpage: this.perpage,
        approved: this.approved,
        search: this.search,
      };
      this.$store.dispatch("educationCommentList", memberInfo).then((value) => {
        this.list = value.list;
        this.totalsize = value.size;
        this.pagesize = value.pages;
      });
    },
    update(id) {
      Swal.fire({
        title: "Yorum onaylanacak!",
        text: "Yorumu onaylamak istediğine emin misin?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Onayla",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          let memberInfo = {
            id: id,
          };
          this.$store
            .dispatch("educationCommentApproved", memberInfo)
            .then((value) => {
              Swal.fire(
                "Onaylandı!",
                "Yorum onaylama işlemi tamamlandı",
                "success"
              );
              this.getCommentList();
            });
        }
      });
    },
    remove(id) {
      Swal.fire({
        title: "Yorum silinecek!",
        text: "Bu işlem geri dönüşü olmayan sonuçlar ortaya çıkarabilir! Devam etmek istiyor musun?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sil",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          let memberInfo = {
            id: id,
          };
          this.$store
            .dispatch("educationCommentRemove", memberInfo)
            .then((value) => {
              Swal.fire("Silindi!", "Yorum silme işlemi tamamlandı", "success");
              this.getCommentList();
            });
        }
      });
    },
    getPageFilter() {
      if (event.key === "Enter") {
        this.getCommentList();
      }
    },
  },
  watch: {
    "$route.params.page": function () {
      if (this.$route.params.page && this.$route.params.page != null) {
        this.currentpage = this.$route.params.page;
      }
      this.getCommentList();
    },
  },
};
</script>
  