<template>
    <div class="app-header1 header py-1 d-flex">
        <div class="container-fluid">
            <div class="d-flex">
                <router-link class="header-brand" to="/">
                    <img src="https://terapivitrini.com/assets/images/brand/logo-primary.svg" class="header-brand-img light-logo" alt="Pinlist logo">
                </router-link>
                <a aria-label="Hide Sidebar" class="app-sidebar__toggle" data-bs-toggle="sidebar" href="#"></a>
                <div class="header-navsearch">
                    <a href="#" class=" "></a>
                    <form class="form-inline me-auto" v-if="$store.state.search">
                        
                    </form>
                </div>
                <div class="d-flex order-lg-2 ms-auto header-right">

                    <button class="navbar-toggler navresponsive-toggler d-md-none" type="button"
                        data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent-4"
                        aria-controls="navbarSupportedContent-4" aria-expanded="false" aria-label="Toggle navigation">
                        <i class="fe fe-more-vertical header-icons navbar-toggler-icon"></i>
                    </button>
                    <div class="p-0 mb-0 navbar navbar-expand-lg  responsive-navbar navbar-dark  ">
                        <div class="navbar-collapse collapse" id="navbarSupportedContent-4">
                            <div class="d-flex">
                                <div class="dropdown d-flex">
                                    <a class="nav-link icon full-screen-link">
                                        <i class="fe fe-maximize floating" id="fullscreen-button"></i>
                                    </a>
                                </div>
                                <div class="dropdown d-flex">
                                    <a class="nav-link icon" data-bs-toggle="dropdown">
                                        <i class="fa fa-bell-o"></i>
                                        <!--<span class=" nav-unread badge bg-danger  rounded-pill">4</span>-->
                                    </a>
                                    <!--<div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                        <a href="#" class="dropdown-item text-center">4 adet bildiriminiz var</a>
                                        <div class="dropdown-divider"></div>
                                        <a href="#" class="dropdown-item d-flex pb-3">
                                            <div class="notifyimg">
                                                <i class="fa fa-envelope-o"></i>
                                            </div>
                                            <div>
                                                <strong>2 new Messages</strong>
                                                <div class="small text-muted">17:50 Pm</div>
                                            </div>
                                        </a>
                                        <a href="#" class="dropdown-item d-flex pb-3">
                                            <div class="notifyimg">
                                                <i class="fa fa-calendar"></i>
                                            </div>
                                            <div>
                                                <strong> 1 Event Soon</strong>
                                                <div class="small text-muted">19-10-2019</div>
                                            </div>
                                        </a>
                                        <a href="#" class="dropdown-item d-flex pb-3">
                                            <div class="notifyimg">
                                                <i class="fa fa-comment-o"></i>
                                            </div>
                                            <div>
                                                <strong> 3 new Comments</strong>
                                                <div class="small text-muted">05:34 Am</div>
                                            </div>
                                        </a>
                                        <a href="#" class="dropdown-item d-flex pb-3">
                                            <div class="notifyimg">
                                                <i class="fa fa-exclamation-triangle"></i>
                                            </div>
                                            <div>
                                                <strong> Application Error</strong>
                                                <div class="small text-muted">13:45 Pm</div>
                                            </div>
                                        </a>
                                        <div class="dropdown-divider"></div>
                                        <a href="#" class="dropdown-item text-center">Tümünü gör</a>
                                    </div>-->
                                </div>
                                <div class="dropdown ">
                                    <a href="#" class="nav-link pe-sm-0 p-0 leading-none user-img"
                                        data-bs-toggle="dropdown">
                                        <img :src="'https://storage.terapivitrini.com/'+$store.state.profile_image" alt="profile-img"
                                            class="avatar avatar-md brround">
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow ">
                                        <router-link class="dropdown-item" :to="'/user/myprofile'">
                                            <i class="dropdown-icon si si-user"></i>Profilim
                                        </router-link>
                                        <a @click="logout" class="dropdown-item">
                                            <i class="dropdown-icon si si-power"></i> Çıkış Yap
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        logout() {
            this.$store.dispatch("logout")
        }
    },
}
</script>