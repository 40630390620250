<template>
    <div class="row">

        <div class="col-md-12">
            <div class="card m-b-20">
                <div class="card-header">
                    <h3 class="card-title">Dosya Yükle</h3>

                </div>
                <div class=" card-body">
                    <div class="row">
                        <div class="col-lg-12 col-sm-12">
                            <input type="file" @change="fileChange" class="dropify" name="file" data-height="180" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="card ">
                <div class="card-header">
                    <h3 class="card-title">Dosya Bilgileri</h3>
                </div>
                <div class="card-body">
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="exampleInputEmail1">Dosya Adı</label>
                                <input type="text" :value="name" class="form-control" disabled id="test" name="test"
                                    placeholder="Dosya Adı" autocomplete="off">
                            </div>
                        </div>
                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="exampleInputEmail1">Türü</label>
                                <input type="text" :value="type" class="form-control" disabled id="test" name="test"
                                    placeholder="Dosya Türü" autocomplete="off">
                            </div>
                        </div>
                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="exampleInputEmail1">Boyut</label>
                                <input type="text" :value="(size / 1024/1024).toFixed(2)" disabled class="form-control"
                                    id="test" name="test" placeholder="Boyut" autocomplete="off">
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-primary" id="saveButton" @click="fileUpload">Yükle</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    name: 'MediaAdd',
    components: {
    },
    created() {
        document.title = "Yeni Dosya Ekle"
        this.$store.state.header = true;
        this.$store.state.bread_crumb_title = "Yeni Dosya"
        this.$store.state.bread_crumb_items = []
        this.$store.state.bread_crumb_items.push({ url: '/dashboard', name: "Gösterge Paneli" });
        this.$store.state.bread_crumb_items.push({ url: '', name: "Yeni Dosya Ekle" });
    },
    data() {
        return {
            name: "",
            type: "",
            size: ""
        }
    },
    methods: {
        fileChange() {
            this.name = document.querySelector("input[name=file]").files[0].name
            this.size = document.querySelector("input[name=file]").files[0].size
            this.type = document.querySelector("input[name=file]").files[0].type
        },
        fileUpload() {
            document.getElementById("saveButton").innerText = "Dosya Yükleniyor...";
            document.getElementById('saveButton').disabled = true
            let file = document.querySelector("input[name=file]").files[0]
            let formData = new FormData();
            formData.append("file", file);
            formData.append("name", this.name);
            formData.append("size", this.size);
            formData.append("type", this.type);
            this.$store.dispatch("mediaUpload", formData).then(() => {
                document.getElementById("saveButton").innerText = "Yükle";
                document.getElementById('saveButton').disabled = false
            });
        }
    },
    mounted() {
        $(function () {
            'use strict'
            $('.dropify').dropify({
                messages: {
                    'default': 'Dosyayı sürükle bırak yada seç',
                    'replace': 'Değişiklik için sürükle bırak yada seç',
                    'remove': 'İptal',
                    'error': 'Ooops, bişeyler yanlış.'
                },
                error: {
                    'fileSize': 'Dosya çok büyük (2M max).'
                }
            });
        });
    }
}
</script>
