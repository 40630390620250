<template>
  <div class="row" style="width: 100%; margin: 0 auto">
    <div class="panel panel-primary">
      <div class="tab_wrapper first_tab">
        <div class="content_wrapper">
          <div class="tab_content active">
            <div class="col-md-12">
              <button
                class="btn btn-primary w-100"
                id="saveButton2"
                @click="fileUpload"
              >
                Yükle
              </button>
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title">
                    Dosya Yükle<br /><br />
                    <small
                      >*Lütfen sadece 1000x600 dosya boyutunda resimler
                      yükleyin</small
                    >
                  </h3>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-12 col-sm-12">
                      <croppa
                        v-model="myCroppa"
                        :width="1000"
                        :height="600"
                        :placeholder="'Resim Seçin 1000x600'"
                        :placeholder-font-size="15"
                        :accept="'image/*'"
                        :file-size-limit="0"
                        :quality="1"
                        :zoom-speed="3"
                        :disabled="false"
                        :disable-drag-and-drop="false"
                        :disable-click-to-choose="false"
                        :disable-drag-to-move="false"
                        :disable-scroll-to-zoom="false"
                        :disable-rotation="false"
                        :prevent-white-space="false"
                        :reverse-scroll-to-zoom="false"
                        :show-remove-button="true"
                        :remove-button-color="'#c58aa6'"
                        :remove-button-size="20"
                        @file-choose="fileChange"
                        canvas-color="transparent"
                      ></croppa>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.select {
  background-color: green !important;
}
</style>

<script>
export default {
  name: "FileUpload",
  created() {
    this.getFileList();
  },
  data() {
    return {
      name: "",
      type: "",
      size: "",
      link: "",
      list: [],
      currentpage: 1,
      myCroppa: null,
      perpage: 50,
      totalsize: 0,
      pagesize: 0,
      selectedImage: [],
      selectedImageIndex: -1,
    };
  },
  methods: {
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    openImageInfos(item, i) {
      this.selectedImage = item;
      this.selectedImageIndex = i;
    },
    getFileList() {
      let mediaInfo = {
        begin: (this.currentpage - 1) * this.perpage,
        perpage: this.perpage,
      };
      this.$store.dispatch("mediaList", mediaInfo).then((value) => {
        this.list = value.list;
        this.totalsize = value.size;
        this.pagesize = value.pages;
      });
    },
    copyItem() {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(this.link);
        return;
      }
      let a = this;
      navigator.clipboard.writeText(this.link).then(
        function () {
          a.$toast("Link kopyalandı", {
            timeout: 1000,
          });
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
    fileChange() {
      this.name = document.querySelector("input[name=file]").files[0].name;
      this.size = document.querySelector("input[name=file]").files[0].size;
      this.type = document.querySelector("input[name=file]").files[0].type;
    },
    shortLinkGenerator() {
      let blogName = "";
      if(document.querySelector("input[name=blogName]")){
        blogName = document.querySelector("input[name=blogName]").value;
      }
      if(document.querySelector("input[name=serviceName]")){
        blogName = document.querySelector("input[name=serviceName]").value;
      }
      blogName = blogName.toLowerCase();
      return blogName
        .replaceAll("ğ", "g")
        .replaceAll("ü", "u")
        .replaceAll("ş", "s")
        .replaceAll("ı", "i")
        .replaceAll("ö", "o")
        .replaceAll("ç", "c")
        .replaceAll("?", "")
        .replaceAll(" ", "-");
    },
    fileUpload() {
      document.getElementById("saveButton2").innerText = "Dosya Yükleniyor...";
      document.getElementById("saveButton2").disabled = true;
      //let file2 = document.querySelector("input[name=file2]").files[0];
      let title = this.shortLinkGenerator();
      if(title==null || title==''){
        title="cover_image";
      }
      let base64img = this.myCroppa.generateDataUrl();
      let file = this.dataURLtoFile(base64img, this.myCroppa.chosenFile.name);
      let formData = new FormData();
      formData.append("file", file);
      formData.append("name", title);
      formData.append("size", this.myCroppa.chosenFile.size);
      formData.append("type", this.myCroppa.chosenFile.type);

      this.$store.dispatch("mediaUpload", formData).then((response) => {
        this.$emit("input", response.link);

        document.getElementById("saveButton2").innerText = "Yükle";
        document.getElementById("saveButton2").disabled = false;
      });
    },
  },
  mounted() {
    $(function (e) {
      $(".first_tab").champ();
      $(".accordion_example").champ({
        plugin_type: "accordion",
        side: "left",
        active_tab: "3",
        controllers: "true",
      });

      $(".second_tab").champ({
        plugin_type: "tab",
        side: "right",
        active_tab: "1",
        controllers: "false",
      });
    });
    $(function () {
      "use strict";
      $(".dropify").dropify({
        messages: {
          default: "Dosyayı sürükle bırak yada seç",
          replace: "Değişiklik için sürükle bırak yada seç",
          remove: "İptal",
          error: "Ooops, bişeyler yanlış.",
        },
        error: {
          fileSize: "Dosya çok büyük (2M max).",
        },
      });
    });
  },
};
</script>
