<template>
  <div class="page" v-if="$store.state.header">
    <div class="page-main">
      <Header />
      <!-- Sidebar menu-->
      <div class="app-sidebar__overlay" data-bs-toggle="sidebar"></div>
      <Sidebar />
      <div class="app-content  my-3 my-md-5">
        <div class="side-app">
          <BreadCrumb />
          <router-view />
        </div>
      </div>
    </div>

    <!--footer-->
    <Footer />
    <!-- End Footer-->
  </div>
  <div class="page" v-else>
    <router-view />
  </div>
</template>

<script>
// @ is an alias to /src

import Sidebar from '@/components/Sidebar.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import BreadCrumb from '@/components/BreadCrumb.vue'

export default {
  components: {
    Sidebar,
    Header,
    Footer,
    BreadCrumb
  },
  created() {
    this.$store.state.header = true;
    this.$store.dispatch("TokenControl")
  },
  mounted() {

    (function ($) {
      "use strict";


      // ______________Loader
      $(window).on("load", function (e) {
        $("#global-loader").fadeOut("slow");
      })


      // ______________Full screen
      $("#fullscreen-button").on("click", function toggleFullScreen() {
        if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
          if (document.documentElement.requestFullScreen) {
            document.documentElement.requestFullScreen();
          } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen();
          } else if (document.documentElement.webkitRequestFullScreen) {
            document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
          } else if (document.documentElement.msRequestFullscreen) {
            document.documentElement.msRequestFullscreen();
          }
        } else {
          if (document.cancelFullScreen) {
            document.cancelFullScreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          }
        }
      })
      // ______________Cover Image
      $(".cover-image").each(function () {
        var attr = $(this).attr('data-bs-image-src');
        if (typeof attr !== typeof undefined && attr !== false) {
          $(this).css('background', 'url(' + attr + ') center center');
        }
      });

      // ______________ Back to Top
      $(window).on("scroll", function (e) {
        if ($(this).scrollTop() > 0) {
          $('#back-to-top').fadeIn('slow');
        } else {
          $('#back-to-top').fadeOut('slow');
        }
      });
      $("#back-to-top").on("click", function (e) {
        $("html, body").animate({
          scrollTop: 0
        }, 0);
        return false;
      });

      // ______________Global Search
      $(document).on("click", "[data-bs-toggle='search']", function (e) {
        var body = $("body");

        if (body.hasClass('search-gone')) {
          body.addClass('search-gone');
          body.removeClass('search-show');
        } else {
          body.removeClass('search-gone');
          body.addClass('search-show');
        }
      });
      var toggleSidebar = function () {
        var w = $(window);
        if (w.outerWidth() <= 1024) {
          $("body").addClass("sidebar-gone");
          $(document).off("click", "body").on("click", "body", function (e) {
            if ($(e.target).hasClass('sidebar-show') || $(e.target).hasClass('search-show')) {
              $("body").removeClass("sidebar-show");
              $("body").addClass("sidebar-gone");
              $("body").removeClass("search-show");
            }
          });
        } else {
          $("body").removeClass("sidebar-gone");
        }
      }
      toggleSidebar();
      $(window).resize(toggleSidebar);

      // ______________Chart-circle
      if ($('.chart-circle').length) {
        $('.chart-circle').each(function () {
          let $this = $(this);
          $this.circleProgress({
            fill: {
              color: $this.attr('data-color')
            },
            size: $this.height(),
            startAngle: -Math.PI / 4 * 2,
            emptyFill: '#e7e9f1',
            lineCap: ''
          });
        });
      }

      const DIV_CARD = 'div.card';
      // ______________Tooltip
      var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
      })

      // ______________Popover
      var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
      var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        return new bootstrap.Popover(popoverTriggerEl)
      })

      // ______________Remove Card
      $(document).on('click', '[data-bs-toggle="card-remove"]', function (e) {
        let $card = $(this).closest(DIV_CARD);
        $card.remove();
        e.preventDefault();
        return false;
      });

      // ______________Card Collapse
      $(document).on('click', '[data-bs-toggle="card-collapse"]', function (e) {
        let $card = $(this).closest(DIV_CARD);
        $card.toggleClass('card-collapsed');
        e.preventDefault();
        return false;
      });

      // ______________Card Fullscreen
      $(document).on('click', '[data-bs-toggle="card-fullscreen"]', function (e) {
        let $card = $(this).closest(DIV_CARD);
        $card.toggleClass('card-fullscreen').removeClass('card-collapsed');
        e.preventDefault();
        return false;
      });

    })(jQuery);
  }
}
</script>
