<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card m-b-20">
        <div class="card-header">
          <h3 class="card-title">Fatura Detayları</h3>
          <ul
            class="unstyled inbox-pagination"
            style="position: absolute; right: 10px"
          >
            <li>
              <div class="input-group">
                <select
                  class="form-select form-select-lg"
                  aria-label=".form-select-lg example"
                  name="paymentStatus"
                >
                  <option selected disabled value="">
                    Ödeme Durumunu Değiştir
                  </option>
                  <option value="1">Ödendi</option>
                  <option value="2">Ödeme Bekliyor</option>
                  <option value="3">Fatura İptal</option>
                </select>
                <button class="btn btn-primary" @click="paymentStatusChange">
                  Değiştir
                </button>
              </div>
            </li>
          </ul>
        </div>
        <div class="card-body">
          <div class="form-row">
            <div class="col-6">
              <b>Fatura Carisi:</b>
              <p>{{ data.account_title }}</p>
            </div>
            <div class="col-6">
              <h2>
                <b>Durum :</b>
                <template v-if="data.payment_status == 1"
                  >Ödeme Yapıldı</template
                >
                <template v-if="data.payment_status == 2"
                  >Ödeme Bekliyor</template
                >
                <template v-if="data.payment_status == 3"
                  >Fatura İptal Edildi</template
                >
              </h2>
            </div>
            <hr style="border-top: 1px dotted #ddd; width: 100%" />
            <div class="col-4">
              <b>Vergi Numarası:</b>
              <p>{{ data.tax_number }}</p>
            </div>
            <div class="col-4">
              <b>Vergi Dairesi:</b>
              <p>{{ data.tax_office }}</p>
            </div>
            <div class="col-4">
              <b>Şirket Türü:</b>
              <p>{{ data.company_type == 1 ? "ŞAHIS" : "ANONİM/LİMİTED" }}</p>
            </div>
            <hr style="border-top: 1px dotted #ddd; width: 100%" />
            <div class="col-12">
              <b>Adres:</b>
              <p>{{ data.address }}</p>
              <p>{{ data.city }}/{{ data.province }}</p>
            </div>
            <hr style="border-top: 1px dotted #ddd; width: 100%" />
            <div class="col-6 text-center">
              <button class="btn btn-primary" @click="billingUploadStart">
                <i
                  class="fa fa-file-pdf-o"
                  style="font-size: 50px"
                  aria-hidden="true"
                ></i>
                <br />
                Fatura Yükle
              </button>
              <button class="btn btn-primary w-100 mt-5" @click="fileUpload" id="saveButton2" v-if="billingButton">Yüklemeye Başla</button>
              <input type="file" name="file" id="fileinput"  accept="application/pdf" @change="fileChange" hidden="true"/>
            </div>
            <div class="col-6">
              <b>Fiyatı:</b>
              <template v-if="data.dumping_mode == 1">
                <del>{{ data.price }} TL</del>
                <input
                  type="number"
                  name="price"
                  class="form-control"
                  :value="data.dumping_price"
                />
              </template>

              <template v-if="data.dumping_mode == 0">
                <input
                  type="number"
                  name="price"
                  class="form-control"
                  :value="data.price"
                />
              </template>
              <hr style="border-top: 1px dotted #ddd; width: 100%" />
              <b>Vergi Oranı :</b>
              <input
                type="number"
                name="tax"
                class="form-control"
                :value="data.tax"
              />
              <hr style="border-top: 1px dotted #ddd; width: 100%" />
              <b>Vergi : </b>{{ data.tax_price }} TL
              <hr style="border-top: 1px dotted #ddd; width: 100%" />
              <button
                v-if="data.payment_status == 2"
                @click="paymentPriceChange"
                class="btn btn-primary"
              >
                Fiyat Değiştir
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "UserUpdate",
  components: {},
  created() {
    document.title = "Fatura Detayları";
    this.$store.state.header = true;
    this.$store
      .dispatch("billingGet", { id: this.$route.params.id })
      .then((value) => {
        if (value.length == 0) {
          this.$router.push("/billing/list");
        } else {
          this.data = value[0];
          this.$store.state.bread_crumb_title = "Fatura Detayları";
          this.$store.state.bread_crumb_items = [];
          this.$store.state.bread_crumb_items.push({
            url: "/dashboard",
            name: "Gösterge Paneli",
          });
          this.$store.state.bread_crumb_items.push({
            url: "/billing/list",
            name: "Fatura Listesi",
          });
          this.$store.state.bread_crumb_items.push({
            url: "",
            name: value[0].fullname,
          });
        }
      });
  },
  data() {
    return {
      data: [],
      generatePassword: "",
      billingButton:false,
    };
  },
  methods: {
    fileChange() {
      this.billingButton=true
    },
    fileUpload() {
      document.getElementById("saveButton2").innerText = "Dosya Yükleniyor...";
      document.getElementById("saveButton2").disabled = true;
      let file= document.querySelector("input[name=file]").files[0];
      let formData = new FormData();
      formData.append("file", file);
      formData.append("id", this.$route.params.id);

      this.$store.dispatch("billingUploadFile", formData).then((response) => {
        document.getElementById("saveButton2").innerText = "Yüklemeye Başla";
        document.getElementById("saveButton2").disabled = false;
        this.billingButton=false;
      });
    },
    billingUploadStart(){
      $('#fileinput').trigger('click');
    },
    paymentStatusChange() {
      /** Payment Change*/
      let paymentStatus = document.querySelector(
        "select[name=paymentStatus]"
      ).value;
      if (paymentStatus == "") {
        this.$toast("Ödenme durumu seçilmedi!", {
          timeout: 1000,
        });
      } else {
        let billingInfo = {
          paymentStatus: paymentStatus,
          id: this.$route.params.id,
        };
        this.$store
          .dispatch("billingStatusUpdate", billingInfo)
          .then((value) => {
            this.data = value[0];
            this.$toast("Fatura durumu değiştirildi", {
              timeout: 2000,
            });
          });
      }
    },
    paymentPriceChange() {
      /** Payment Change*/
      let paymentPrice = document.querySelector("input[name=price]").value;
      let paymentTax = document.querySelector("input[name=tax]").value;
      if (paymentPrice == "") {
        this.$toast("Fiyat boş olamaz!", {
          timeout: 1000,
        });
      } else if (paymentTax == "") {
        this.$toast("Vergi oranı boş olamaz!", {
          timeout: 1000,
        });
      } else {
        let billingInfo = {
          price: paymentPrice,
          tax: paymentTax,
          id: this.$route.params.id,
        };
        this.$store
          .dispatch("billingPriceUpdate", billingInfo)
          .then((value) => {
            this.data = value[0];
            this.$toast("Fatura fiyat durumu değiştirildi", {
              timeout: 2000,
            });
          });
      }
    },
    remove() {
      Swal.fire({
        title: "Üye silinecek?",
        text: "Bu işlem geri dönüşü olmayan sonuçlar ortaya çıkarabilir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sil",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          let pageInfo = {
            id: this.$route.params.id,
          };
          this.$store.dispatch("userRemove", pageInfo).then((value) => {
            Swal.fire("Silindi!", "Üye silme işlemi tamamlandı", "success");
            setTimeout(() => {
              this.$router.push("/member/list");
            }, 2000);
          });
        }
      });
    },
    passwordGenerator() {
      var length = 10,
        charset =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      this.generatePassword = retVal;
    },
    copyPassword() {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(this.generatePassword);
        return;
      }
      let a = this;
      navigator.clipboard.writeText(this.generatePassword).then(
        function () {
          a.$toast("Parola kopyalandı", {
            timeout: 1000,
          });
          document.querySelector("input[name=password]").value =
            a.generatePassword;
          document.querySelector("input[name=re-password]").value =
            a.generatePassword;
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
  },
};
</script>