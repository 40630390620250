<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="">
        <div class="card-body">
          <div class="tab-content">
            <div class="tab-pane active" id="tab1">
              <ul class="unstyled inbox-pagination">
                <li class="input-group">
                  <select
                    class="form-select"
                    v-on:change="cityChange"
                    id="city"
                  >
                    <option selected disabled>İl Seçin</option>
                    <option
                      v-for="(list, i) in city"
                      :key="i"
                      :selected="list.name == selectedCity ? true : false"
                      :value="list.name"
                      :data-districts="JSON.stringify(list.districts)"
                    >
                      {{ list.name }}
                    </option>
                  </select>
                  <select
                    class="form-select"
                    v-on:change="districtsChange"
                    id="district"
                  >
                    <option selected disabled>İlçe Seçin</option>
                    <option
                      v-for="(list, i) in districts"
                      :selected="list.name == selectedDistrict ? true : false"
                      :value="list.name"
                      :key="i"
                    >
                      {{ list.name }}
                    </option>
                  </select>
                  <select
                    class="form-select"
                    v-on:change="expertTypeChange"
                    id="expertType"
                  >
                    <option selected disabled>Uzmanlık Seçin</option>
                    <option
                      v-for="(list, i) in serviceList"
                      :selected="list.id == expertType ? true : false"
                      :value="list.id"
                      :key="i"
                    >
                      {{ list.experience_name }}
                    </option>
                  </select>
                  <input
                      type="search"
                      class="form-control"
                      placeholder="Uzmanlarda Ara"
                      aria-label="Ara"
                      style="height:auto ;"
                      @keyup="getPageFilter"
                      v-model="search"
                    />
                    <button
                      class="btn btn-primary"
                      @click="getMemberList"
                      type="button"
                      style="border-top-right-radius: 3px;border-bottom-right-radius:3px;"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  <div
                    class=""
                    style="
                      padding: 0 !important;
                      padding-right: 10px !important;
                      border-radius:0px;
                    "
                  >
                    
                  </div>
                </li>
                <li>
                  
                </li>
                <li>
                  
                </li>
              </ul>
            </div>
            <div class="table-responsive container">
              <div class="row">
                <div class="col" v-for="(item, i) in list" :key="i">
                  <div
                    class="row mt-2 card card-body card-shadow"
                  >
                    <div class="col-12">
                      <img
                        :src="
                          'https://storage.terapivitrini.com/' +
                          item.profile_image_url
                        "
                        class="avatar avatar-lg brround"
                      />
                    </div>
                    <div class="col-12">
                      {{ item.fullname }}
                    </div>
                    <div class="col-12">
                      <template v-if="item.approved_profile == 0">
                        <span class="status-icon bg-black"></span> Talep Yok
                      </template>
                      <template v-if="item.approved_profile == 1">
                        <span class="status-icon bg-success"></span> Onaylı
                      </template>
                      <template v-if="item.approved_profile == 2">
                        <span class="status-icon bg-warning"></span> Onay
                        Bekliyor
                      </template>
                      <template v-if="item.approved_profile == 3">
                        <span class="status-icon bg-danger"></span>
                        Reddedildi
                      </template>
                    </div>
                    <div class="col-12">
                      <button
                        class="btn btn-primary"
                        v-on:click="openDialog(item.id)"
                      >
                        Randevu Durumu
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <ul class="unstyled inbox-pagination">
                <li>
                  <span
                    >{{ (currentpage - 1) * perpage }}-{{
                      (currentpage - 1) * perpage + perpage
                    }}
                    arasında, Toplam
                    {{ totalsize[approved_profile] }} kayıt</span
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ul class="pagination mb-5">
      <li
        :class="'page-item page-prev ' + (currentpage == 1 ? 'disabled' : '')"
      >
        <a class="page-link" href="#" tabindex="-1">Önceki</a>
      </li>
      <li
        v-for="(s, i) in pagesize"
        :key="i"
        :class="'page-item ' + (i + 1 == currentpage ? 'active' : '')"
      >
        <a v-on:click="changePage(i + 1)" class="page-link">{{ i + 1 }}</a>
      </li>
      <li
        :class="
          'page-item page-next ' +
          (currentpage == pagesize || currentpage == 1 ? 'disabled' : '')
        "
      >
        <a class="page-link" href="#">Sonraki</a>
      </li>
    </ul>
    <div
      class="modal fade"
      id="appointmentStatus"
      tabindex="-1"
      role="dialog"
      aria-labelledby="appointmentStatusLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <DateCalendar
              :userid="selectedUserId"
              @response="handleSelectedDate($event)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <style scoped>
  .card-shadow{
    text-align: center;box-shadow: rgb(0, 0, 0) 0px 13px 8px -13px;
    transition: ease-in-out 0.5s;
  }
  .card-shadow:hover{
    box-shadow: rgb(0, 0, 0) 0px 17px 12px -13px;
  transition: ease-in-out 0.5s;
  }
.memberItem {
  width: 100%;
  background: #ddd;
  border-radius: 10px;
  min-height: 80px;
  border-left: 15px solid orange;
  box-shadow: 0px 3px 10px -5px #474747;
}
.selected_item {
  box-shadow: 0 0px 0px 4px #5a338082;
}
.panel-tabs > li {
  border-left: 0;
  margin: 2px;
}
</style>
  <script>
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import DateCalendar from "./components/DateCalander.vue";
export default {
  name: "MemberList",
  components: { DateCalendar },
  created() {
    document.title = "Uzman Listesi";
    this.$store.state.header = true;
    this.$store.state.bread_crumb_title = "Uzman Listesi";
    this.$store.state.bread_crumb_items = [];
    this.$store.state.bread_crumb_items.push({
      url: "/dashboard",
      name: "Gösterge Paneli",
    });
    this.$store.state.bread_crumb_items.push({
      url: "",
      name: "Uzman Listesi",
    });
    if (this.$route.query.page && this.$route.query.page != null) {
      this.currentpage = this.$route.query.page;
    }
    if (this.$route.query.begin && this.$route.query.begin != null) {
      this.begin = this.$route.query.begin;
    }
    if (this.$route.query.end && this.$route.query.end != null) {
      this.end = this.$route.query.end;
    }
    if (this.$route.query.search && this.$route.query.search != null) {
      this.search = this.$route.query.search;
    }
    if (
      this.$route.query.approved_profile &&
      this.$route.query.approved_profile != null
    ) {
      this.approved_profile = 1;
    }
    if (this.$route.query.city && this.$route.query.city != null) {
      this.selectedCity = this.$route.query.city;
    }
    if (this.$route.query.districts && this.$route.query.districts != null) {
      this.selectedDistrict = this.$route.query.districts;
    }
    if (this.$route.query.experttype && this.$route.query.experttype != null) {
      this.expertType = this.$route.query.experttype;
    }
    this.$store.dispatch("cityList").then((value) => {
      this.city = value.list;
    });
    this.$store.dispatch("serviceListAll").then((value) => {
      this.serviceList = value.list;
    });
    this.getMemberList();
  },
  data() {
    return {
      list: [],
      currentpage: 1,
      perpage: 16,
      totalsize: 0,
      pagesize: 0,
      userType: "EXPERT",
      approved_profile: 1,
      search: "",
      begin: "",
      end: "",
      selectedDate: "",
      selectedDateId: "",
      city: [],
      districts: [],
      serviceList:[],
      selectedCity: "",
      selectedDistrict: "",
      selectedUserId: 0,
      expertType:0,
    };
  },
  methods: {
    openDialog(userid) {
      this.selectedUserId = userid;
      $("#appointmentStatus").modal("show");
    },
    expertTypeChange(){
      this.expertType = $("#expertType option:selected").val();
      this.getMemberList();
    },
    cityChange() {
      this.selectedCity = $("#city option:selected").val();
      this.selectedDistrict="";
      this.districts = JSON.parse(
        $("#city option:selected").attr("data-districts")
      );
      this.getMemberList();
    },
    districtsChange() {
      this.selectedDistrict = $("#district option:selected").val();
      this.getMemberList();
    },
    handleSelectedDate: function (e) {
      this.dateControl(this.$route.query.uid, e[0]);
      $("#appointmentStatus").modal("hide");
      this.selectedDate = e[0];
      this.selectedDateId = e[1];
      this.$router.push("/member/expert/appointment/new?uid="+this.selectedUserId+"&did="+this.selectedDateId+"&date="+this.selectedDate);
    },
    dateControl(id, date) {
      let appointmentControl = {
        id: id,
        date: date,
      };
      this.$store
        .dispatch("appointmentControl", appointmentControl)
        .then((value) => {
          if (value.type == "success") {
            this.user = value.user;
            this.experiences = value.experiences;
            if (this.selected_experience_id > 0) {
              this.experiences.forEach((e) => {
                if (e.experience_id == this.selected_experience_id) {
                  this.selectedExperiences = e;
                  this.selectedExperiences.payment_methods.forEach((e) => {
                    if (e.id == this.selected_payment_methods) {
                      this.selectedPaymentMethod = e;
                    }
                  });
                }
              });
            }
            if (!this.$route.query.aid) {
              this.mail = "";
            }

            this.loading = false;
          } else {
            if (value.message == "ERRxUDF") {
              this.$toast("Hatalı tarih formatı", "Uyarı!");
              this.$router.go(-1);
            }
            if (value.message == "ERRxDEX") {
              this.$toast("Randevu süresi geçmişten bir gün olamaz", "Uyarı!");
            }
            if (value.message == "ERRxDNF") {
              this.$vToastify.warning("Randevu tarihi bulunamadı", "Uyarı!");
            }
            if (value.message == "ERRxHNF") {
              this.$vToastify.warning("Randevu tarihi bulunamadı", "Uyarı!");
            }
          }
        });
    },
    changePage(i) {
      this.$router.replace({
        query: {
          search: this.search,
          page: i,
          begin: this.begin,
          end: this.end,
          approved_profile: this.approved_profile,
          city: this.selectedCity,
          district: this.selectedDistrict,
        },
      });
    },
    approvedProfileChange(i) {
      this.currentpage = 1;
      this.approved_profile = i;
      this.getMemberList();
    },
    filter(item) {
      this.userType = item;
      this.getMemberList();
    },
    getMemberList() {
      this.list = [];
      this.$router.replace({
        query: {
          search: this.search,
          page: this.currentpage,
          begin: this.begin,
          end: this.end,
          approved_profile: this.approved_profile,
          city: this.selectedCity,
          district: this.selectedDistrict,
          experttype:this.expertType==0?"":this.expertType,
        },
      });
      let memberInfo = "";
      if (this.approved_profile < 0) {
        memberInfo = {
          begin: (this.currentpage - 1) * this.perpage,
          perpage: this.perpage,
          type: this.userType,
          search: this.search,
          beginD: this.begin,
          endD: this.endD,
          city: this.selectedCity,
          district: this.selectedDistrict,
          experttype:this.expertType==0?"":this.expertType,
        };
      } else {
        memberInfo = {
          begin: (this.currentpage - 1) * this.perpage,
          perpage: this.perpage,
          type: this.userType,
          approved_profile: this.approved_profile,
          search: this.search,
          beginD: this.begin,
          endD: this.endD,
          city: this.selectedCity,
          district: this.selectedDistrict,
          experttype:this.expertType==0?"":this.expertType,
        };
      }

      this.$store.dispatch("memberList", memberInfo).then((value) => {
        this.list = value.list;
        this.totalsize = value.size;
        this.pagesize = value.pages;
      });
    },
    preview(id) {
      this.$router.push("/member/expert/preview/" + id);
    },
    update(id) {
      this.$router.push("/member/update/" + id);
    },
    getPageFilter() {
      if (event.key === "Enter") {
        this.getMemberList();
      }
    },
    remove(id) {
      Swal.fire({
        title: "Kullanıcı silinecek?",
        text: "Bu işlem geri dönüşü olmayan sonuçlar ortaya çıkarabilir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sil",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          let memberInfo = {
            id: id,
          };
          this.$store.dispatch("memberRemove", memberInfo).then((value) => {
            Swal.fire("Silindi!", "Üye silme işlemi tamamlandı", "success");
            this.getMemberList();
          });
        }
      });
    },
  },
  watch: {
    "$route.query.page": function () {
      if (this.$route.query.page && this.$route.query.page != null) {
        this.currentpage = this.$route.query.page;
      }
      this.getMemberList();
    },
  },
};
</script>
  