<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="panel panel-primary">
        <div class="user-tabs mb-4">
          <!-- Tabs -->
          <ul class="nav panel-tabs">
            <li class="">
              <a
                href="#tab1"
                @click="selectTab(1, 1)"
                data-bs-toggle="tab"
                :class="'active '+(publish == 1 ? 'selected_item' : '')"
                >Tümü ({{ totalsize[0] }})</a
              >
            </li>
            <li class="">
              <a
                href="#tab1"
                @click="selectTab(1, 1)"
                data-bs-toggle="tab"
                :class="'active '+(publish == 1 ? 'selected_item' : '')"
                >Yayınlandı ({{ totalsize[1] }})</a
              >
            </li>
            <li class="">
              <a
                href="#tab1"
                @click="selectTab(0, 2)"
                data-bs-toggle="tab"
                :class="'active '+(publish == 0 ? 'selected_item' : '')"
                >Bekliyor ({{ totalsize[2] }})</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="tab-content">
            <div class="tab-pane active" id="tab1">
              <div class="mail-option">
                <ul class="unstyled inbox-pagination">
                  <li>
                    <div
                      class="form-inline nav-search"
                      style="
                        padding: 0 !important;
                        padding-right: 10px !important;
                      "
                    >
                      <input
                        type="search"
                        class="form-control header-search"
                        placeholder="Testlerde Ara"
                        aria-label="Ara"
                        @keyup="enterEvent"
                        v-model="search"
                      />
                      <button
                        class="btn btn-primary"
                        @click="filter"
                        type="button"
                      >
                        <i class="fa fa-search"></i>
                      </button>
                    </div>
                  </li>
                  <li>
                    <router-link
                      to="/survey/create"
                      class="btn bg-primary-transparent text-primary"
                    >
                      <i class="fa fa-plus"></i> Yeni Test Ekle</router-link
                    >
                  </li>
                </ul>
              </div>
              <div class="table-responsive border-top">
                <table
                  class="
                    table
                    card-table
                    table-bordered table-hover table-vcenter
                    text-nowrap
                  "
                >
                  <tbody>
                    <tr>
                      <th>Test Başlığı</th>
                      <th>Durum</th>
                      <th>İşlem</th>
                    </tr>
                    <tr v-for="(item, i) in list" :key="i">
                      <td>
                        {{ item.title }}
                      </td>
                      <td>
                        <template v-if="item.status == 0"
                          ><span class="status-icon bg-secondary"></span>
                          Taslak</template
                        >
                        <template v-if="item.status == 1"
                          ><span class="status-icon bg-success"></span>
                          Yayında</template
                        >
                      </td>
                      <td>
                        <div class="btn-list">
                          <button
                            class="btn bg-primary-transparent text-primary"
                            @click="update(item.id)"
                          >
                            Güncelle
                          </button>
                          <button
                            class="btn bg-danger-transparent text-primary"
                            @click="remove(item.id, i)"
                          >
                            Sil
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <ul class="unstyled inbox-pagination">
                  <li>
                    <span
                      >{{ (currentpage - 1) * perpage }}-{{
                        (currentpage - 1) * perpage + perpage
                      }}
                      arasında, Toplam {{ totalsize[selectedItem] }} kayıt</span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul class="pagination mb-5">
        <li
          :class="'page-item page-prev ' + (currentpage == 1 ? 'disabled' : '')"
        >
          <a class="page-link" href="#" tabindex="-1">Önceki</a>
        </li>
        <li
          v-for="(s, i) in pagesize"
          :key="i"
          :class="'page-item ' + (i + 1 == currentpage ? 'active' : '')"
        >
          <router-link :to="'/survey/list/' + (i + 1)" class="page-link">{{
            i + 1
          }}</router-link>
        </li>
        <li
          :class="
            'page-item page-next ' +
            (currentpage == pagesize || currentpage == 1 ? 'disabled' : '')
          "
        >
          <a class="page-link" href="#">Sonraki</a>
        </li>
      </ul>
    </div>
  </div>
</template>
<style scoped>
.adsItem{
  width: 100%;
    height: 50px;
    background: #f1f1f1;
    border-radius: 10px;
    border-left: 10px solid #ddd;
    box-shadow: 0px 5px 5px -5px #999999;
    padding: 10px;
}
.selected_item {
  box-shadow: 0 0px 0px 4px #5a338082;
}
.panel-tabs > li {
  border-left: 0;
  margin: 2px;
}
</style>
  <script>
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "SurveyList",
  components: {},
  created() {
    document.title = "Test Listesi";
    this.$store.state.header = true;
    this.$store.state.bread_crumb_title = "Test Listesi";
    this.$store.state.bread_crumb_items = [];
    this.$store.state.bread_crumb_items.push({
      url: "/dashboard",
      name: "Gösterge Paneli",
    });
    this.$store.state.bread_crumb_items.push({
      url: "",
      name: "Test Listesi",
    });
    if (this.$route.query.page && this.$route.query.page != null) {
      this.currentpage = this.$route.query.page;
    }
    if (this.$route.query.begin && this.$route.query.begin != null) {
      this.begin = this.$route.query.begin;
    }
    if (this.$route.query.end && this.$route.query.end != null) {
      this.end = this.$route.query.end;
    }
    if (this.$route.query.search && this.$route.query.search != null) {
      this.search = this.$route.query.search;
    }
    this.$store.dispatch("surveyList", {begin:0,perpage:this.perpage}).then((value) => {
      this.service_list = value.list;
    });
    this.filter();
  },
  data() {
    return {
      selectedItem: 0,
      list: [],
      currentpage: 1,
      perpage: 20,
      totalsize: 0,
      pagesize: 0,
      publish: 5,
      service_list: [],
      selectedTags: [],
      begin: "",
      end: "",
      search:"",
    };
  },
  methods: {
    addTags(item) {
      let exist = false;
      let findedIndex = 0;
      let index = 0;
      this.selectedTags.forEach((e) => {
        if (e == item) {
          exist = true;
          findedIndex = index;
        }
        index++;
      });
      if (!exist) {
        this.selectedTags.push(item);
      } else {
        this.selectedTags.splice(findedIndex, 1);
      }
    },
    selectTab(item, index) {
      this.selectedItem = index;
      this.publish = item;
      this.filter();
    },
    enterEvent() {
      if (event.key === "Enter") {
        this.filter();
      }
    },
    filter() {
      this.$router.replace({
        query: {
          begin: (this.currentpage - 1) * this.perpage,
          perpage: this.perpage,
          publish: this.publish,
          start: this.begin,
          end: this.end,
          tags: this.selectedTags.toString(),
          search:this.search
        },
      });
      let blogInfo = {
        begin: (this.currentpage - 1) * this.perpage,
        perpage: this.perpage,
        publish: this.publish,
        start: this.begin,
        end: this.end,
        tags: this.selectedTags.toString(),
        search:this.search
      };
      this.$store.dispatch("surveyList", blogInfo).then((value) => {
        this.list = value.list;
        this.totalsize = value.size;
        this.pagesize = value.pages;
      });
    },
    update(id) {
      this.$router.push("/survey/update/" + id);
    },
    remove(id, index) {
      Swal.fire({
        title: "Test silinecek?",
        text: "Bu işlem geri dönüşü olmayan sonuçlar ortaya çıkarabilir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sil",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          let surveyInfo = {
            id: id,
          };
          this.$store.dispatch("surveyRemove", surveyInfo).then((value) => {
            if (value.type == "error") {
              if (value.message == "ERRxIPH") {
                /**ONAYLI REKLAM SİLİNEMEZ */
                Swal.fire("Hata!", "Onaylı test silinemez", "error");
              }
              if (value.message == "ERRxINF") {
                /* REKLAM BULUNAMADI */
                Swal.fire("Hata!", "Test bulunamadı", "error");
              }
            }
            if (value.type == "success") {
              if (value.message == "SCCx001") {
                /**ONAYLI REKLAM SİLİNEMEZ */
                Swal.fire("Başarılı!", "Test silindi!", "success");
                this.list.splice(index, 1);
              }
            }
          });
        }
      });
    },
  },
  watch: {
    "$route.params.page": function () {
      if (this.$route.params.page && this.$route.params.page != null) {
        this.currentpage = this.$route.params.page;
      }
      this.filter();
    },
  },
};
</script>
  