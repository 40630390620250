import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Toast from "vue-toastification";
import Croppa from "vue-croppa";
import "vue-croppa/dist/vue-croppa.css";
Vue.use(Croppa);
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import  VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)
Vue.config.productionTip = false

import VueMask from 'v-mask'
Vue.use(VueMask)

Vue.use(Toast).use(VueBottomSheet);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
