import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import Dashboard from "../views//Dashboard/Dashboard.vue";

import CategoryAdd from "../views/Category/CategoryAdd.vue";
import CategoryUpdate from "../views/Category/CategoryUpdate.vue";
import CategoryList from "../views/Category/CategoryList.vue";

import BlogAdd from "../views/Blog/BlogAdd.vue";
import BlogUpdate from "../views/Blog/BlogUpdate.vue";
import BlogList from "../views/Blog/BlogList.vue";

import AdsCreate from "../views/Ads/AdsCreate.vue";
import AdsList from "../views/Ads/AdsList.vue";
import AdsUpdate from "../views/Ads/AdsUpdate.vue";

import SurveyCreate from "../views/Survey/SurveyCreate.vue";
import SurveyList from "../views/Survey/SurveyList.vue";
import SurveyUpdate from "../views/Survey/SurveyUpdate.vue";

import JobCreate from "../views/Jobs/JobCreate.vue";
import JobList from "../views/Jobs/JobList.vue";
import JobUpdate from "../views/Jobs/JobUpdate.vue";

import MobileSliderCreate from "../views/MobileApp/Slider/SliderCreate.vue";
import MobileSliderList from "../views/MobileApp/Slider/SliderList.vue";
import MobileSliderUpdate from "../views/MobileApp/Slider/SliderUpdate.vue";

import MobileSearchCreate from "../views/MobileApp/SearchableArea/SearchCreate.vue";
import MobileSearchList from "../views/MobileApp/SearchableArea/SearchList.vue";
import MobileSearchUpdate from "../views/MobileApp/SearchableArea/SearchUpdate.vue";

import MobileSurveySliderCreate from "../views/MobileApp/SurveySlider/SurveySliderCreate.vue";
import MobileSurveySliderList from "../views/MobileApp/SurveySlider/SurveySliderList.vue";
import MobileSurveySliderUpdate from "../views/MobileApp/SurveySlider/SurveySliderUpdate.vue";

import PageAdd from "../views/Page/PageAdd.vue";
import PageUpdate from "../views/Page/PageUpdate.vue";
import PageList from "../views/Page/PageList.vue";

import UserAdd from "../views/User/UserAdd.vue";
import UserUpdate from "../views/User/UserUpdate.vue";
import MyProfile from "../views/User/MyProfile.vue";
import EditProfile from "../views/User/EditProfile.vue";
import UserList from "../views/User/UserList.vue";

import ServiceAdd from "../views/Service/ServiceAdd.vue";
import ServiceUpdate from "../views/Service/ServiceUpdate.vue";
import ServiceRequestList from "../views/Service/ServiceRequestList.vue";
import ServiceList from "../views/Service/ServiceList.vue";

import BillingList from "../views/Billing/BillingList.vue";
import BillingDetail from "../views/Billing/BillingDetail.vue";

import MemberAdd from "../views/Member/MemberAdd.vue";
import MemberUpdate from "../views/Member/MemberUpdate.vue";
import EditMemberProfile from "../views/Member/EditMemberProfile.vue";
import MemberList from "../views/Member/MemberList.vue";
import MemberExpertList from "../views/Member/MemberExpertList.vue";
import MemberExpertPreview from "../views/Member/MemberExpertPreview.vue";

import MemberExpertAppointmentList from "../views/Appointment/AppointmentList.vue";
import MemberExpertAppointmentCreate from "../views/Appointment/AppointmentCreate.vue";
import ExpertAppointmentList from "../views/Appointment/ExpertAppointmentList.vue";
import MemberExpertAppointmentNew from "../views/Appointment/AppointmentNew.vue";

import PreAppointmentList from "../views/Appointment/PreAppointmentList.vue";
import PreAppointmentPreview from "../views/Appointment/PreAppointmentPreview.vue";

import CenterList from "../views/Center/CenterList.vue";
import CenterPreview from "../views/Center/CenterPreview.vue";

import MemberEducationList from "../views/Member/MemberEducationList.vue";
import MemberEducationPreview from "../views/Member/MemberEducationPreview.vue";
import MemberEducationWaitingPayList from "../views/Member/MemberEducationWaitingPayList.vue";

import MemberExpertChangeBillingTypeList from "../views/Member/MemberExpertChangeBillingTypeList";
import MemberEducationChangeBillingTypeList from "../views/Member/MemberEducationChangeBillingTypeList";

import SettingGet from "../views/Setting/SettingGet.vue";

import MenuList from "../views/Menu/MenuList.vue";
import MenuContentAdd from "../views/Menu/MenuContentAdd.vue";
import MenuContentUpdate from "../views/Menu/MenuContentUpdate.vue";

import FaqList from "../views/Faq/FaqList.vue";

import MediaAdd from "../views/Media/MediaAdd.vue";
import MediaList from "../views/Media/MediaList.vue";

import CommentBlogList from "../views/Comment/CommentBlogList.vue";
import CommentProfileList from "../views/Comment/CommentProfileList.vue";
import CommentEducationList from "../views/Comment/CommentEducationList.vue";

import ERR400 from "../views/ErrorViews/400.vue";
import ERR401 from "../views/ErrorViews/401.vue";
import ERR403 from "../views/ErrorViews/403.vue";
import ERR404 from "../views/ErrorViews/404.vue";
import ERR500 from "../views/ErrorViews/500.vue";
import ERR503 from "../views/ErrorViews/503.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
  { path: "/dashboard", name: "Dashboard", component: Dashboard },

  { path: "/category", name: "CategoryList", component: CategoryList },
  { path: "/category/list", name: "CategoryList", component: CategoryList },
  { path: "/category/create", name: "CategoryAdd", component: CategoryAdd },
  {
    path: "/category/update/:shortname/:id",
    name: "CategoryUpdate",
    component: CategoryUpdate,
  },

  { path: "/page", name: "PageList", component: PageList },
  { path: "/page/list", name: "PageList", component: PageList },
  { path: "/page/list/:page", name: "PagePaginationList", component: PageList },
  { path: "/page/create", name: "PageAdd", component: PageAdd },
  {
    path: "/page/update/:shortname/:id",
    name: "PageUpdate",
    component: PageUpdate,
  },

  { path: "/blog", name: "BlogList", component: BlogList },
  { path: "/blog/list", name: "BlogsList", component: BlogList },
  { path: "/blog/list/:page", name: "BlogPaginationList", component: BlogList },
  { path: "/blog/create", name: "BlogAdd", component: BlogAdd },
  {
    path: "/blog/update/:shortname/:id",
    name: "BlogUpdate",
    component: BlogUpdate,
  },

  { path: "/ads", name: "AdsList", component: AdsList },
  { path: "/ads/list", name: "AdssList", component: AdsList },
  { path: "/ads/list/:page", name: "AdsPaginationList", component: AdsList },
  { path: "/ads/create", name: "AdsCreate", component: AdsCreate },
  { path: "/ads/update/:id", name: "AdsUpdate", component: AdsUpdate },

  { path: "/survey", name: "SurveyList", component: SurveyList },
  { path: "/survey/list", name: "SurveyyList", component: SurveyList },
  {
    path: "/survey/list/:page",
    name: "SurveyPaginationList",
    component: SurveyList,
  },
  { path: "/survey/create", name: "SurveyCreate", component: SurveyCreate },
  { path: "/survey/update/:id", name: "SurveyUpdate", component: SurveyUpdate },

  { path: "/jobs", name: "JobList", component: JobList },
  { path: "/job/list", name: "JobList", component: JobList },
  { path: "/job/list/:page", name: "JobPaginationList", component: JobList },
  { path: "/job/create", name: "JobCreate", component: JobCreate },
  { path: "/job/update/:id", name: "JobUpdate", component: JobUpdate },

  {
    path: "/mobile/slider",
    name: "MobileSliderList",
    component: MobileSliderList,
  },
  {
    path: "/mobile/slider/list",
    name: "MobileSliderList",
    component: MobileSliderList,
  },
  {
    path: "/mobile/slider/list/:page",
    name: "MobileSliderList",
    component: MobileSliderList,
  },
  {
    path: "/mobile/slider/create",
    name: "MobileSliderCreate",
    component: MobileSliderCreate,
  },
  {
    path: "/mobile/slider/update/:id",
    name: "MobileSliderUpdate",
    component: MobileSliderUpdate,
  },

  {
    path: "/mobile/search",
    name: "MobileSearchList",
    component: MobileSearchList,
  },
  {
    path: "/mobile/search/list",
    name: "MobileSearchList",
    component: MobileSearchList,
  },
  {
    path: "/mobile/search/list/:page",
    name: "MobileSearchList",
    component: MobileSearchList,
  },
  {
    path: "/mobile/search/create",
    name: "MobileSearchCreate",
    component: MobileSearchCreate,
  },
  {
    path: "/mobile/search/update/:id",
    name: "MobileSearchUpdate",
    component: MobileSearchUpdate,
  },

  {
    path: "/mobile/slider/survey",
    name: "MobileSliderList",
    component: MobileSurveySliderList,
  },
  {
    path: "/mobile/slider/survey/list",
    name: "MobileSliderList",
    component: MobileSurveySliderList,
  },
  {
    path: "/mobile/slider/survey/list/:page",
    name: "MobileSliderList",
    component: MobileSurveySliderList,
  },
  {
    path: "/mobile/slider/survey/create",
    name: "MobileSliderCreate",
    component: MobileSurveySliderCreate,
  },
  {
    path: "/mobile/slider/survey/update/:id",
    name: "MobileSliderUpdate",
    component: MobileSurveySliderUpdate,
  },

  { path: "/user", name: "UserList", component: UserList },
  { path: "/user/list", name: "UserList", component: UserList },
  { path: "/user/list/:page", name: "UserPaginationList", component: UserList },
  { path: "/user/create", name: "UserAdd", component: UserAdd },
  { path: "/user/update/:id", name: "UserUpdate", component: UserUpdate },
  { path: "/user/myprofile", name: "MyProfile", component: MyProfile },
  { path: "/user/myprofile/edit", name: "EditProfile", component: EditProfile },

  { path: "/menu", name: "MenuList", component: MenuList },
  { path: "/menu/list", name: "MenuList", component: MenuList },
  {
    path: "/menu/content/:id/create",
    name: "MenuContentAdd",
    component: MenuContentAdd,
  },
  {
    path: "/menu/content/update/:id",
    name: "MenuContentUpdate",
    component: MenuContentUpdate,
  },

  { path: "/billing", component: BillingList },
  { path: "/billing/list", component: BillingList },
  { path: "/billing/detail/:id", component: BillingDetail },

  { path: "/member", name: "MemberList", component: MemberList },
  { path: "/member/list", name: "MemberList", component: MemberList },
  {
    path: "/member/list/:page",
    name: "MemberPaginationList",
    component: MemberList,
  },
  {
    path: "/member/expert/list",
    name: "MemberExpertList",
    component: MemberExpertList,
  },

  {
    path: "/member/expert/appointment",
    name: "MemberExpertAppointmentList",
    component: MemberExpertAppointmentList,
  },
  {
    path: "/member/expert/appointment/create",
    name: "MemberExpertAppointmentCreate",
    component: MemberExpertAppointmentCreate,
  },
  {
    path: "/member/expert/appointment/list",
    name: "MemberExpertAppointmentList",
    component: MemberExpertAppointmentList,
  },
  {
    path: "/member/expert/list/appointment",
    name: "MemberExpertAppointmentList",
    component: ExpertAppointmentList,
  },
  {
    path: "/member/expert/appointment/new",
    name: "MemberExpertAppointmentNew",
    component: MemberExpertAppointmentNew,
  },
  {
    path: "/member/expert/preappointment/list",
    name: "PreAppointmentList",
    component: PreAppointmentList,
  },

  {
    path: "/member/expert/preappointment/preview",
    name: "PreAppointmentGet",
    component: PreAppointmentPreview,
  },

  {
    path: "/member/expert/list/:page",
    name: "MemberExpertPaginationList",
    component: MemberExpertList,
  },
  {
    path: "/member/expert/preview/:id",
    name: "MemberExpertPreview",
    component: MemberExpertPreview,
  },

  { path: "/center/list", name: "CenterList", component: CenterList },
  {
    path: "/center/list/:page",
    name: "CenterPaginationList",
    component: CenterList,
  },
  {
    path: "/center/preview/:id",
    name: "CenterPreview",
    component: CenterPreview,
  },

  {
    path: "/member/expert/education/list",
    name: "MemberEducationList",
    component: MemberEducationList,
  },
  {
    path: "/member/expert/education/list/:page",
    name: "MemberEducationPaginationList",
    component: MemberEducationList,
  },
  {
    path: "/member/expert/education/preview/:id",
    name: "MemberEducationPreview",
    component: MemberEducationPreview,
  },
  {
    path: "/member/expert/education/waiting/pay",
    name: "MemberEducationWaitPayList",
    component: MemberEducationWaitingPayList,
  },
  {
    path: "/member/expert/education/waiting/pay/:page",
    name: "MemberEducationWaitPayPaginationList",
    component: MemberEducationWaitingPayList,
  },

  { path: "/member/create", name: "MemberAdd", component: MemberAdd },
  { path: "/member/update/:id", name: "MemberUpdate", component: MemberUpdate },
  {
    path: "/member/expert/billing_chage/request",
    component: MemberExpertChangeBillingTypeList,
  },
  {
    path: "/member/education/billing_chage/request",
    component: MemberEducationChangeBillingTypeList,
  },

  {
    path: "/member/member_profile/edit",
    name: "EditMemberProfile",
    component: EditMemberProfile,
  },

  { path: "/media", name: "MediaList", component: MediaList },
  { path: "/media/list", name: "MediaList", component: MediaList },
  { path: "/media/list/:page", name: "MediaList", component: MediaList },
  { path: "/media/create", name: "MediaAdd", component: MediaAdd },

  { path: "/faq", name: "FaqList", component: FaqList },

  { path: "/setting", name: "SettingGet", component: SettingGet },

  { path: "/comment", name: "CommentBlogList", component: CommentBlogList },
  {
    path: "/comment/blog/list",
    name: "CommentBlogAllList",
    component: CommentBlogList,
  },
  {
    path: "/comment/blog/list/:page",
    name: "CommentBlogPaginationAllList",
    component: CommentBlogList,
  },
  {
    path: "/comment/profile/list",
    name: "CommentProfileList",
    component: CommentProfileList,
  },
  {
    path: "/comment/profile/list/:page",
    name: "CommentProfilePaginationList",
    component: CommentProfileList,
  },
  {
    path: "/comment/education/list",
    name: "CommentEducationList",
    component: CommentEducationList,
  },
  {
    path: "/comment/education/list/:page",
    name: "CommentEducationPaginationList",
    component: CommentEducationList,
  },

  { path: "/service", name: "ServiceList", component: ServiceList },
  { path: "/service/list", name: "ServiceList", component: ServiceList },
  {
    path: "/service/list/:page",
    name: "ServicePageList",
    component: ServiceList,
  },
  { path: "/service/create", name: "ServiceAdd", component: ServiceAdd },
  {
    path: "/service/update/:id",
    name: "ServiceUpdate",
    component: ServiceUpdate,
  },
  {
    path: "/service/request/list",
    name: "ServiceRequestList",
    component: ServiceRequestList,
  },

  { path: "/400", name: "400", component: ERR400 },
  { path: "/401", name: "401", component: ERR401 },
  { path: "/403", name: "403", component: ERR403 },
  { path: "/404", name: "404", component: ERR404 },
  { path: "/500", name: "500", component: ERR500 },
  { path: "/503", name: "503", component: ERR503 },
  { path: "*", component: ERR404 },
];

const router = new VueRouter({
  mode: "history",
  history: true,
  base: process.env.BASE_URL,
  routes,
});

export default router;
