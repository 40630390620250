<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card m-b-20">
        <div class="card-header">
          <h3 class="card-title">Yeni Reklam Ekle</h3>
        </div>
        <div class="card-body">
          <div class="form-row">
            <div class="form-group col-md-12">
              <div class="form-group">
                <label class="form-label" for="blogName">Üst Başlık</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="title"
                  placeholder="Üst Başlık"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="form-group col-md-12">
              <div class="form-group">
                <label class="form-label" for="blogName">Alt Başlık</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="sub_title"
                  placeholder="Alt Başlık"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="form-group col-md-12">
              <div class="form-group">
                <label class="form-label" for="blogName">URL</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="url"
                  placeholder="URL"
                  autocomplete="off"
                />
              </div>
            </div>

            <div class="form-group col-md-12">
              <button class="btn btn-primary" @click="newCon">Yeni Bağlantı Ekle</button>
            </div>
            
            <div class="form-group col-md-12" v-for="item,i in conList" :key="i">
              <button class="btn btn-primary" @click="remCon(i)">Sil</button>
              <div class="form-group">
                <label class="form-label" for="blogName">Bağlantı Başlığı</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="item.title"
                  placeholder="URL"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <label class="form-label" for="blogName">URL</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="item.link"
                  placeholder="URL"
                  autocomplete="off"
                />
              </div>
            </div>

            <div class="form-group col-md-6">
              <div class="form-group">
                <label class="form-label" for="blogName"
                  >Başlangıç Tarihi</label
                >
                <input
                  type="date"
                  class="form-control"
                  v-model="begin_date"
                  placeholder="Başlangıç Tarihi"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="form-group col-md-6">
              <div class="form-group">
                <label class="form-label" for="blogName">Bitiş Tarihi</label>
                <input
                  type="date"
                  class="form-control"
                  v-model="end_date"
                  placeholder="Başlangıç Tarihi"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="form-group col-md-12">
              <div class="form-group">
                <label class="form-label" for="blogName">Bağlantılar</label>
                <select
                  type="text"
                  class="form-control"
                  autocomplete="off"
                  @change="selectedItem"
                >
                  <option selected disabled>Seçiniz</option>
                  <option value="Terapist">Terapist</option>
                  <option value="Merkez">Merkez</option>
                  <option value="Blog">Blog</option>
                  <option value="Blog Detay">Blog Detay</option>
                  <option value="Eğitim">Eğitim</option>
                  <option value="Eğitim Detay">Eğitim Detay</option>
                  <option
                    v-for="(item, i) in service_list"
                    :key="i"
                    :value="item.experience_name"
                  >
                    {{ item.experience_name }}
                  </option>
                </select>
                <div class="mt-2">
                  <span
                    v-for="(a, b) in selected_tags"
                    :key="b"
                    class="badge badge-primary m-1"
                    >{{ a }} <i class="fa fa-close" @click="removeItem(b)"></i
                  ></span>
                </div>
              </div>
            </div>
            <div class="form-group col-md-12">
              <div class="form-group">
                <label class="form-label" for="blogName"
                  >Reklam Resimleri</label
                >
                <div class="input-group">
                  <input
                    type="file"
                    name="filePath"
                    class="form-control"
                    autocomplete="off"
                  />
                  <input
                    @click="fileUpload"
                    type="button"
                    class="btn btn-primary"
                    id="fileUpload"
                    name="fileUpload"
                    value="Yükle"
                    placeholder="Bağlantılar"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-3" v-for="(img, i) in image_list" :key="i">
                  <img
                    width="150"
                    :src="'https://storage.terapivitrini.com/' + img"
                  />
                  <button class="btn btn-primary w-100" @click="removeImage(i)">
                    Sil
                  </button>
                </div>
              </div>

              <div class="form-group">
                <div class="form-label">Reklam Durumu</div>
                <label class="form-switch">
                  <input
                    type="checkbox"
                    name="publish"
                    class="form-switch-input"
                    v-model="publish"
                  />
                  <span class="form-switch-indicator"></span>
                  <span class="form-switch-description"
                    >Reklam Taslak/Yayınlandı</span
                  >
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button id="saveButton" class="btn btn-primary" @click="save">
            Kaydet
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
    <style>
html {
  scroll-behavior: smooth;
}
</style>
<script>

export default {
  name: "BlogAdd",
  components: {},
  created() {
    document.title = "Reklam Güncelle";
    this.$store.state.header = true;
    this.$store.state.search = false;
    this.$store.state.bread_crumb_title = "Reklam Güncelle";
    this.$store.state.bread_crumb_items = [];
    this.$store.state.bread_crumb_items.push({
      url: "/dashboard",
      name: "Gösterge Paneli",
    });
    this.$store.state.bread_crumb_items.push({
      url: "/ads/list",
      name: "Reklam Listesi",
    });
    this.$store.state.bread_crumb_items.push({
      url: "",
      name: "Reklam Güncelle",
    });
    this.$store.dispatch("serviceList", {}).then((value) => {
      this.service_list = value.list;
    });
    this.$store
      .dispatch("adsGet", { id: this.$route.params.id })
      .then((value) => {
        this.title = value.title;
        this.sub_title = value.sub_title;
        this.begin_date = value.begin_date;
        this.end_date = value.end_date;
        this.publish = value.publish;
        this.image_list = value.images;
        this.url=value.link;
        this.selected_tags = value.tags;
        this.conList=value.cons
      });
  },
  data() {
    return {
      title: "",
      sub_title: "",
      begin_date: "",
      end_date: "",
      publish: 0,
      url:"",
      service_list: [],
      selected_tags: [],
      image_list: [],
      conList:[]
    };
  },
  methods: {
    newCon(){
      this.conList.push({title:"",link:""});
    },
    remCon(i){
      this.conList.splice(i,1);
    },
    removeImage(i) {
      this.image_list.splice(i, 1);
    },
    selectedItem(e) {
      this.selected_tags.push(e.target.value);
    },
    removeItem(i) {
      this.selected_tags.splice(i, 1);
    },
    fileUpload() {
      document.getElementById("fileUpload").innerText = "Dosya Yükleniyor...";
      document.getElementById("fileUpload").disabled = true;
      let file = document.querySelector("input[name=filePath]").files[0];
      let formData = new FormData();
      let name = document.querySelector("input[name=filePath]").files[0].name;
      let size = document.querySelector("input[name=filePath]").files[0].size;
      let type = document.querySelector("input[name=filePath]").files[0].type;
      formData.append("file", file);
      formData.append("name", name);
      formData.append("size", size);
      formData.append("type", type);
      this.$store.dispatch("mediaAdsUpload", formData).then((response) => {
        this.image_list.push(response.link);
        document.getElementById("fileUpload").innerText = "Yükle";
        document.getElementById("fileUpload").disabled = false;
      });
    },
    save() {
      /** BLOG SAVE */
      if (this.title == "") {
        this.$toast("Reklam başlığı boş olamaz", {
          timeout: 1000,
        });
      } else if (this.begin_date == "") {
        this.$toast("Başlangıç tarihi boş olamaz", {
          timeout: 1000,
        });
      } else if (this.end_date == "") {
        this.$toast("Bitiş tarihi boş olamaz", {
          timeout: 1000,
        });
      }else if (this.url == "" && this.conList==null) {
        this.$toast("URL boş olamaz", {
          timeout: 1000,
        });
      } else if (this.selected_tags.length == 0) {
        this.$toast("Bağlantı seçimleri yapılmadı", {
          timeout: 1000,
        });
      } else if (this.image_list.length == 0) {
        this.$toast("Resim Eklenmedi!", {
          timeout: 1000,
        });
      } else {
        document.getElementById("saveButton").disabled = true;
        document.getElementById("saveButton").innerHTML = "Kaydediliyor...";
        let publish = document.querySelector("input[name=publish]").checked
          ? 1
          : 0;
        let adsCreate = {
          id: this.$route.params.id,
          title: this.title,
          sub_title: this.sub_title,
          begin_date: this.begin_date,
          end_date: this.end_date,
          url:this.url,
          selected_tags: this.selected_tags.toString(),
          image_list: this.image_list.toString(),
          publish: publish,
          extra:JSON.stringify(this.conList)
        };
        this.$store.dispatch("adsUpdate", adsCreate).then((value) => {
          document.getElementById("saveButton").disabled = false;
          document.getElementById("saveButton").innerHTML = "Kaydet";
          this.$toast("Reklam kayıt başarılı", {
            timeout: 2000,
          });
        });
      }
    },
  },
  mounted() {
    let _this = this;
    var MediaButton = function (context) {
      var ui = $.summernote.ui;
      var button = ui.button({
        contents: '<i class="fa fa-image"/>',
        tooltip: "Highlight text with red color",
        click: function () {
          _this.$refs.myBottomSheet.open();
        },
      });

      return button.render();
    };
    $.extend($.summernote.plugins, {
      tableofcontent: function (context) {
        var ui = $.summernote.ui;
        var $note = context.layoutInfo.note;
        var $editable = context.layoutInfo.editable;
        context.memo("button.tableofcontent", function () {
          var button = ui.button({
            contents: "İçindekiler",
            click: function () {
              $("#exampleModal3").modal("show");
              _this.$editable = $editable;
              _this.$note = $note;
            },
          });
          var $tableofcontent = button.render();
          return $tableofcontent;
        });
      },
    });
    $(document).ready(function () {
      $("#content").summernote({
        toolbar: [
          [
            "style",
            ["highlight", "bold", "italic", "underline", "clear", "style"],
          ],
          ["font", ["strikethrough", "superscript", "subscript", "fontname"]],
          ["color", ["color"]],
          ["table", ["table"]],
          ["para", ["ul", "ol", "paragraph", "hr"]],
          ["insert", ["tableofcontent"]],
          ["link", ["linkDialogShow", "unlink"]],
          ["view", ["fullscreen", "codeview", "help"]],
        ],
        buttons: {
          highlight: MediaButton,
        },
      });
    });
  },
};
</script>
    