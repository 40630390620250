<template>
  <div class="row">
    <div class="col-lg-4">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Benim Profilim</h3>
        </div>
        <div class="card-body">
          <form>
            <div class="row mb-2">
              <div class="col-auto">
                <img
                  class="avatar brround avatar-xxl"
                  :src="
                    'https://storage.terapivitrini.com/' + data.profile_image_url
                  "
                  alt="Avatar-img"
                />
              </div>
              <div class="col">
                <h3 class="mb-1">
                  <input type="text" v-model="fullname" class="form-control" />
                </h3>
                <p class="mb-1 mt-1">
                  <input type="file" class="form-control" />
                </p>
                <button class="btn btn-primary btn-block">Yükle</button>
              </div>
            </div>
            <div class="form-group">
              <label class="form-label">Hakkımda</label>
              <textarea
                class="form-control"
                v-model="description"
                rows="5"
              ></textarea>
            </div>
            <div class="form-group">
              <label class="form-label">Email</label>
              <input
                class="form-control"
                disabled
                placeholder="your-email@domain.com"
                v-model="email"
              />
            </div>
            <div class="form-group">
              <label class="form-label">Parola</label>
              <input
                type="password"
                v-model="password"
                class="form-control"
                value="password"
              />
            </div>
            <div class="form-group">
              <label class="form-label">Parola (Yeniden)</label>
              <input
                type="password"
                v-model="repassword"
                class="form-control"
                value="password"
              />
            </div>
            <div class="form-footer">
              <button class="btn btn-primary btn-block">Kaydet</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-lg-8">
      <form class="card">
        <div class="card-header">
          <h3 class="card-title">Profili Güncelle</h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-5">
              <div class="form-group">
                <label class="form-label">Facebook</label>
                <input type="text" class="form-control" v-model="facebook" />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group">
                <label class="form-label">Twitter</label>
                <input type="text" class="form-control" v-model="twitter" />
              </div>
            </div>
            <div class="col-sm-6 col-md-4">
              <div class="form-group">
                <label class="form-label">Instagram</label>
                <input type="text" class="form-control" v-model="instagram" />
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="form-group">
                <label class="form-label">YouTube</label>
                <input type="text" class="form-control" v-model="youtube" />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  name: "EditProfile",
  components: {},
  created() {
    document.title = "Profil Güncelle";
    this.$store.state.header = true;
    this.$store.state.bread_crumb_title = "Profil Güncelle";
    this.$store.state.bread_crumb_items = [];
    this.$store.state.bread_crumb_items.push({
      url: "/dashboard",
      name: "Gösterge Paneli",
    });
    this.$store.state.bread_crumb_items.push({
      url: "/user/myprofile",
      name: "Profilim",
    });
    this.$store.state.bread_crumb_items.push({
      url: "",
      name: "Profil Güncelle",
    });
    this.$store.dispatch("profileGet").then((value) => {
      this.data = value[0];

      this.fullname=this.data.fullname,
      this.description=this.data.about,
      this.facebook=this.data.facebook,
      this.twitter=this.data.twitter,
      this.instagram=this.data.instagram,
      this.linkedin=this.data.linkedin

      this.$store.state.bread_crumb_title = "Kullanıcı Güncelle";
      this.$store.state.bread_crumb_items = [];
      this.$store.state.bread_crumb_items.push({
        url: "/dashboard",
        name: "Gösterge Paneli",
      });
      this.$store.state.bread_crumb_items.push({
        url: "/user/list",
        name: "Kullanıcı Listesi",
      });
      this.$store.state.bread_crumb_items.push({
        url: "/user/myprofile",
        name: value[0].email,
      });
      this.$store.state.bread_crumb_items.push({
        url: "",
        name: "Güncelle",
      });
    });
  },

  data() {
    return {
      data: [],
      fullname:"",
      description:"",
      password:"",
      repassword:"",
      facebook:"",
      twitter:"",
      instagram:"",
      linkedin:""
    };
  },
  mounted() {},
};
</script>