<template>
  <div class="row">
    <div class="col-lg-6">
      <div class="card">
        <div class="card-body">
          <div class="tab-content">
            <div class="tab-pane active" id="tab1">
              <div class="mail-option">
                <ul class="unstyled inbox-pagination">
                  <li>
                    <button
                      @click="menuScopeAdd"
                      class="btn bg-primary-transparent text-primary"
                    >
                      <i class="fa fa-plus"></i> Yeni Kapsam Ekle
                    </button>
                  </li>
                </ul>
              </div>
              <div class="table-responsive border-top">
                <table
                  class="
                    table
                    card-table
                    table-bordered table-hover table-vcenter
                    text-nowrap
                  "
                >
                  <tbody>
                    <tr>
                      <th class="w-1">Başlık</th>
                      <th class="w-1">Tür</th>
                      <th>İşlem</th>
                    </tr>
                    <tr
                      v-for="(item, i) in scopeList"
                      :key="i"
                      @click="selectedScope(i, item)"
                    >
                      <td>
                        <input
                          type="text"
                          :value="item.title"
                          class="form-control"
                          id="scopeName"
                          :name="'scopeName' + i"
                          placeholder="Kapsam Başlığı"
                          autocomplete="off"
                        />
                      </td>
                      <td>
                        <select
                          class="form-control select2"
                          :name="'scopeLocation' + i"
                        >
                          <option
                            :selected="item.location == 'TOP' ? true : false"
                            value="TOP"
                          >
                            UST
                          </option>
                          <option
                            :selected="
                              item.location == 'BOTTOM1' ? true : false
                            "
                            value="BOTTOM1"
                          >
                            ALT1
                          </option>
                          <option
                            :selected="
                              item.location == 'BOTTOM2' ? true : false
                            "
                            value="BOTTOM2"
                          >
                            ALT2
                          </option>
                          <option
                            :selected="
                              item.location == 'BOTTOM3' ? true : false
                            "
                            value="BOTTOM3"
                          >
                            ALT3
                          </option>
                          <option
                            :selected="
                              item.location == 'BOTTOM4' ? true : false
                            "
                            value="BOTTOM4"
                          >
                            ALT4
                          </option>
                          <option
                            :selected="item.location == 'FOOTER' ? true : false"
                            value="FOOTER"
                          >
                            FOOTER
                          </option>
                        </select>
                      </td>
                      <td style="width: 20%">
                        <div class="btn-list">
                          <button
                            class="btn bg-primary-transparent text-primary"
                            @click="updateScope(i, item.id)"
                            :id="'scopeSave' + i"
                          >
                            Kaydet
                          </button>
                          <button
                            class="btn bg-danger-transparent text-primary"
                            @click="removeScope(i, item.id)"
                          >
                            Sil
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card">
        <div class="card-body">
          <div class="tab-content" v-if="selectedScopeItem.id != null">
            <div class="tab-pane active" id="tab1">
              <div class="mail-option">
                <h3 style="float: left">{{ selectedScopeItem.title }}</h3>
                <ul class="unstyled inbox-pagination">
                  <li>
                    <router-link
                      :to="'/menu/content/' + selectedScopeItem.id + '/create'"
                      class="btn bg-primary-transparent text-primary"
                    >
                      <i class="fa fa-plus"></i> Yeni Eleman Ekle
                    </router-link>
                  </li>
                </ul>
              </div>
              <div class="table-responsive border-top">
                <table
                  class="
                    table
                    card-table
                    table-bordered table-hover table-vcenter
                    text-nowrap
                  "
                >
                  <tbody>
                    <tr>
                      <th class="w-1">ID</th>
                      <th class="w-1">Başlık</th>
                      <th class="w-1">Tür</th>
                      <th class="w-1">ÜstID</th>
                      <th class="w-1">Sıra</th>
                      <th>İşlem</th>
                    </tr>
                    <tr v-for="(item, i) in contentList" :key="i">
                      <td>{{ item.id }}</td>
                      <td>{{ item.title }}</td>
                      <td>{{ item.type }}</td>
                      <td>{{ item.parent_id }}</td>
                      <td>{{ item.sort }}</td>
                      <td style="width: 20%">
                        <div class="btn-list">
                          <router-link
                            :to="'/menu/content/update/' + item.id"
                            class="btn bg-primary-transparent text-primary"
                            >Güncelle</router-link
                          >
                          <button
                            class="btn bg-danger-transparent text-primary"
                            @click="removeItem(selectedScopeItem.id,item.id)"
                          >
                            Sil
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "MenuList",
  components: {},
  created() {
    document.title = "Menu Listesi";
    this.$store.state.header = true;
    this.$store.state.bread_crumb_title = "Menu Listesi";
    this.$store.state.bread_crumb_items = [];
    this.$store.state.bread_crumb_items.push({
      url: "/dashboard",
      name: "Gösterge Paneli",
    });
    this.$store.state.bread_crumb_items.push({ url: "", name: "Menu Listesi" });
    this.getMenuScopeList();
  },
  data() {
    return {
      scopeList: [],
      selectedScopeItem: {},
      contentList: [],
    };
  },
  methods: {
    menuScopeAdd() {
      this.scopeList.push({ title: "", location: "" });
    },
    selectedScope(i, item) {
      if (item.id != null) {
        console.log(item);
        this.selectedScopeItem = item;
        this.getMenuContentList(item.id);
      }
    },
    getMenuScopeList() {
      let menuInfo = {};
      this.$store.dispatch("menuScopeList", menuInfo).then((value) => {
        this.scopeList = value.list;
      });
    },
    getMenuContentList(id) {
      let menuInfo = { id: id };
      this.$store.dispatch("menuContentList", menuInfo).then((value) => {
        this.scopeList = value.list;
      });
    },
    getMenuContentList(id) {
      let menuInfo = {
        id: id,
      };
      this.$store.dispatch("menuContentList", menuInfo).then((value) => {
        this.contentList = value.list;
      });
    },
    updateScope(i, id) {
      /** UPDATE SAVE */
      document.getElementById("scopeSave" + i).disabled = true;
      document.getElementById("scopeSave" + i).innerHTML = "Kaydediliyor...";

      let scopeName = document.querySelector(
        "input[name=scopeName" + i + "]"
      ).value;
      let scopeLocation = document.querySelector(
        "select[name=scopeLocation" + i + "]"
      ).value;

      if (scopeName == "") {
        this.$toast("Kapsam başlığı boş olamaz", {
          timeout: 1000,
        });
        document.getElementById("scopeSave" + i).disabled = false;
        document.getElementById("scopeSave" + i).innerHTML = "Kaydet";
      } else {
        let menuInfo = {
          title: scopeName,
          location: scopeLocation,
          id: id,
        };
        this.$store.dispatch("menuScopeUpdate", menuInfo).then((value) => {
          document.getElementById("scopeSave" + i).disabled = false;
          document.getElementById("scopeSave" + i).innerHTML = "Kaydet";
          this.$toast("Menü kayıt başarılı", {
            timeout: 2000,
          });
        });
      }
    },
    removeScope(index, id) {
      Swal.fire({
        title: "Kapsam silinecek?",
        text: "Bu işlem geri dönüşü olmayan sonuçlar ortaya çıkarabilir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sil",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          let menuInfo = {
            id: id,
          };
          this.$store.dispatch("menuScopeRemove", menuInfo).then((value) => {
            Swal.fire("Silindi!", "Menü silme işlemi tamamlandı", "success");
            this.getMenuScopeList();
            this.getMenuContentList();
          });
        }
      });
    },
    removeItem(parentId, id) {
      Swal.fire({
        title: "Kapsam Objesi silinecek?",
        text: "Bu işlem geri dönüşü olmayan sonuçlar ortaya çıkarabilir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sil",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          let menuInfo = {
            id: id,
          };
          this.$store.dispatch("menuScopeRemove", menuInfo).then((value) => {
            Swal.fire("Silindi!", "Makale silme işlemi tamamlandı", "success");
            this.getMenuContentList(parentId);
          });
        }
      });
    },
  },
};
</script>
