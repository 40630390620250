<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card m-b-20">
                <div class="card-header">
                    <h3 class="card-title">Kategori Güncelle</h3>
                </div>
                <div class="card-body" v-if="data != null">
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="categoryName">Kategori
                                    Adı</label>
                                <input type="text" class="form-control" id="categoryName" :value="data.category_name"
                                    @keyup="shortLinkGenerator" name="categoryName" placeholder="Kategori adı"
                                    autocomplete="off">
                            </div>
                        </div>
                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="shortName">URL</label>
                                <input type="text" class="form-control" :value="data.short_name" id="shortName"
                                    name="shortName" placeholder="URL" disabled>
                            </div>
                        </div>
                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="categoryDescription">Açıklama</label>
                                <textarea type="text" class="form-control" id="categoryDescription"
                                    name="categoryDescription" placeholder="Kategori Açıklama" autocomplete="off">{{data.category_description}}</textarea>
                            </div>
                        </div>
                        <div class="form-group col-md-12">
                            <label for="sort" class="col-form-label">SIRA</label>
                            <input type="number" :value="data.sort" class="form-control" id="sort" name="sort"
                                placeholder="Sıra">
                        </div>
                    </div>
                    <div class="form-row">
                        <FindInSelectComponent @response="handleData($event)" title="Üst Kategori"
                            :placeholder="title" action="categoryList" :selected="data.parent_id" showItem="category_name"
                            valueItem="id" searchItem="category_name" />
                    </div>
                    <div class="btn-list">
                        <button class="btn btn-primary" @click="save" id="saveButton">Güncelle</button>
                        <button class="btn btn-danger m-b-20" @click="remove" id="removeButton">Sil</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FindInSelectComponent from '../elementComponents/FindInSelectComponent.vue';
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
export default {
    name: 'CategoryUpdate',
    components: {
        FindInSelectComponent
    },
    created() {
        document.title = "Kategori Güncelle"
        this.$store.state.header = true;
        this.$store.dispatch("categoryGet", { short_name: this.$route.params.shortname, id: this.$route.params.id }).then((value) => {
            if (value.length == 0) {
                this.$router.push("/category/list");
            } else {
                this.data = value[0];
                this.$store.state.bread_crumb_title = "Kategori Güncelle"
                this.$store.state.bread_crumb_items = []
                this.$store.state.bread_crumb_items.push({ url: '/dashboard', name: "Gösterge Paneli" });
                this.$store.state.bread_crumb_items.push({ url: '/category/list', name: "Kategori Listesi" });
                this.$store.state.bread_crumb_items.push({ url: '', name: value[0].category_name });

            }
        })
    },
    data() {
        return {
            data: [],
            subCategory:0,
            title:'Üst Kategori Seçin'
        }
    },
    methods: {
        handleData: function (e) {
            this.subCategory=e[0];
            this.title=e[1];
        },
        save() {
            /** CATEGORY SAVE */
            document.getElementById('saveButton').disabled = true
            document.getElementById('saveButton').innerHTML = 'Değişiklikler Kaydediliyor...'
            let categoryName = document.querySelector("input[name=categoryName]").value;
            let shortName = document.querySelector("input[name=shortName]").value;
            let categoryDescription=document.querySelector("textarea[name=categoryDescription]").value;
            let sort = document.querySelector("input[name=sort]").value;
            if (categoryName == "") {
                this.$toast("Kategori adı boş olamaz", {
                    timeout: 1000
                });
                document.getElementById('saveButton').disabled = false
                document.getElementById('saveButton').innerHTML = 'Güncelle'
            }
            else if (shortName == "") {
                this.$toast("URL boş olamaz", {
                    timeout: 1000
                });
                document.getElementById('saveButton').disabled = false
                document.getElementById('saveButton').innerHTML = 'Güncelle'
            }
            else {
                let categoryInfo = {
                    category_name: categoryName,
                    short_name: shortName,
                    category_description:categoryDescription,
                    sort: sort,
                    parent_id: this.subCategory,
                    id: this.$route.params.id
                }
                this.$store.dispatch("categoryUpdate", categoryInfo).then((value) => {
                    document.getElementById('saveButton').disabled = false
                    document.getElementById('saveButton').innerHTML = 'Güncelle'
                    this.$toast("Kategori güncelleme başarılı", {
                        timeout: 2000
                    });
                    setTimeout(() => {
                        this.$router.push("/category/update/" + value.short_name + "/" + value.updated_id);
                    }, 2000);
                })
            }
        },
        remove() {
            Swal.fire({
                title: 'Kategori silinecek?',
                text: "Bu işlem geri dönüşü olmayan sonuçlar ortaya çıkarabilir!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sil',
                cancelButtonText: 'İptal'
            }).then((result) => {
                if (result.isConfirmed) {
                    let categoryInfo = {
                        id: this.$route.params.id
                    }
                    this.$store.dispatch("categoryRemove", categoryInfo).then((value) => {
                        Swal.fire(
                            'Silindi!',
                            'Kategori silme işlemi tamamlandı',
                            'success'
                        )
                        setTimeout(() => {
                            this.$router.push("/category/list");
                        }, 2000);
                    })

                }
            })

        },
        shortLinkGenerator() {
            let categoryName = document.querySelector("input[name=categoryName]").value;
            categoryName = categoryName.toLowerCase();
            document.querySelector("input[name=shortName]").value = categoryName.replaceAll('ğ', 'g')
                .replaceAll('ü', 'u')
                .replaceAll('ş', 's')
                .replaceAll('ı', 'i')
                .replaceAll('ö', 'o')
                .replaceAll('ç', 'c')
                .replaceAll(' ', '-')
        }
    },
}
</script>
