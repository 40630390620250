<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="panel panel-primary">
        <div>
          <div class="user-tabs mb-4">
            <!-- Tabs -->
            <ul class="nav panel-tabs">
              <li class="" @click="filter(0)">
                <a class="btn btn-secondary btn-sm"
                  >Tümü ({{ totalsize[0] }})</a
                >
              </li>
              <li class="" @click="filter(1)">
                <a class="btn btn-success btn-sm"
                  >Ödendi ({{ totalsize[1] }})</a
                >
              </li>
              <li class="" @click="filter(2)">
                <a class="btn btn-warning btn-sm"
                  >Ödeme Bekliyor ({{ totalsize[2] }})</a
                >
              </li>
              <li class="" @click="filter(3)">
                <a class="btn btn-danger btn-sm"
                  >İptal Edilen ({{ totalsize[3] }})</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="tab-content">
            <div class="tab-pane active" id="tab1">
              <div class="mail-option">
                <ul class="unstyled inbox-pagination">
                  <li>
                    <div
                      class="form-inline nav-search"
                      style="
                        padding: 0 !important;
                        padding-right: 10px !important;
                      "
                    >
                      <input
                        type="search"
                        class="form-control header-search"
                        placeholder="Faturalarda Ara"
                        aria-label="Ara"
                        v-model="search"
                      />
                      <button
                        class="btn btn-primary"
                        @click="getBillingList"
                        type="button"
                      >
                        <i class="fa fa-search"></i>
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="table-responsive border-top">
                <table
                  class="
                    table
                    card-table
                    table-bordered table-hover table-vcenter
                    text-nowrap
                  "
                >
                  <tbody>
                    <tr>
                      <th class="w-1">Profil</th>
                      <th class="w-1">Kullanıcı Bilgisi</th>
                      <th class="w-1">Cari Hesap</th>
                      <th>Tutar</th>
                      <th>Vergi</th>
                      <th>Durum</th>
                      <th>Oluşturulma</th>
                      <th>İşlem</th>
                    </tr>
                    <tr v-for="(item, i) in list" :key="i">
                      <td>
                        <img
                          :src="
                            'https://storage.terapivitrini.com/' +
                            item.profile_image_url
                          "
                          class="avatar avatar-lg brround"
                        />
                      </td>
                      <td>
                        {{ item.fullname }}<br /><small>{{ item.email }}</small>
                      </td>
                      <td>{{ item.account_title }}</td>
                      <td>
                        {{ item.price }}
                      </td>
                      <td>{{ item.tax_price }}</td>
                      <td><template v-if="item.payment_status == 1"
                          ><span class="status-icon bg-success"></span>
                          Ödeme Yapıldı</template
                        >
                        <template v-if="item.payment_status == 2"
                          ><span class="status-icon bg-warning"></span>
                          Ödeme Bekliyor</template
                        >
                        <template v-if="item.payment_status == 3"
                          ><span class="status-icon bg-danger"></span>
                          Fatura İptal Edildi</template
                        ></td>
                      <td>{{ item.created_at }}</td>
                      <td>
                        <div class="btn-list">
                          <button
                            class="btn bg-primary-transparent text-primary"
                            @click="update(item.billing_id)"
                          >
                            Fatura Detayları
                          </button>
                          <button
                            class="btn bg-danger-transparent text-primary"
                            @click="remove(item.id)"
                          >
                            İptal
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <ul class="unstyled inbox-pagination">
                  <li>
                    <span
                      >{{ (currentpage - 1) * perpage }}-{{
                        (currentpage - 1) * perpage + perpage
                      }}
                      arasında, Toplam {{ totalsize[billingType] }} kayıt</span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul class="pagination mb-5">
        <li
          :class="'page-item page-prev ' + (currentpage == 1 ? 'disabled' : '')"
        >
          <a class="page-link" href="#" tabindex="-1">Önceki</a>
        </li>
        <li
          v-for="(s, i) in pagesize"
          :key="i"
          :class="'page-item ' + (i + 1 == currentpage ? 'active' : '')"
        >
          <button class="page-link">{{ i + 1 }}</button>
        </li>
        <li
          :class="
            'page-item page-next ' +
            (currentpage == pagesize || currentpage == 1 ? 'disabled' : '')
          "
        >
          <a class="page-link" href="#">Sonraki</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "BillingList",
  components: {},
  created() {
    document.title = "Fatura Listesi";
    this.$store.state.header = true;
    this.$store.state.bread_crumb_title = "Fatura Listesi";
    this.$store.state.bread_crumb_items = [];
    this.$store.state.bread_crumb_items.push({
      url: "/dashboard",
      name: "Gösterge Paneli",
    });
    this.$store.state.bread_crumb_items.push({
      url: "",
      name: "Fatura Listesi",
    });
    this.getBillingList();
  },
  data() {
    return {
      list: [],
      currentpage: 1,
      perpage: 50,
      totalsize: 0,
      pagesize: 0,
      billingType: 0,
      search: "",
    };
  },
  methods: {
    filter(item) {
      this.billingType = item;
      this.getBillingList();
    },
    getBillingList() {
        this.list=[]
      let billingInfo = {
        begin: (this.currentpage - 1) * this.perpage,
        perpage: this.perpage,
        type: this.billingType,
        search: this.search,
      };
      this.$store.dispatch("billingList", billingInfo).then((value) => {
        this.list = value.list;
        this.totalsize = value.size;
        this.pagesize = value.pages;
      });
    },
    update(id) {
      this.$router.push("/billing/detail/" + id);
    },
    remove(id) {
      Swal.fire({
        title: "Fatura iptal edilsin mi?",
        text: "Bu işlem üyeliği sonlandırır faturayı siler, devam etmek istiyor musun?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sil",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          let memberInfo = {
            id: id,
          };
          this.$store.dispatch("billingRemove", memberInfo).then((value) => {
            Swal.fire("Silindi!", "Üye silme işlemi tamamlandı", "success");
            this.getBillingList();
          });
        }
      });
    },
  },
};
</script>
