<template>
    <div class="page-header">
        <h4 class="page-title">{{$store.state.bread_crumb_title}}</h4>
        <ol class="breadcrumb">
            <li v-for="item,i in $store.state.bread_crumb_items" :key="i" class="breadcrumb-item">
                <router-link :to="item.url">{{item.name}}</router-link>
            </li>
        </ol>
    </div>
</template>
<script>
export default {

}
</script>