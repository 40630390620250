<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card m-b-20">
                <div class="card-header">
                    <h3 class="card-title">Yeni Kullanıcı Ekle</h3>
                </div>
                <div class="card-body">
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="fullname">Tam Adı</label>
                                <input type="text" class="form-control" id="fullname" name="fullName" placeholder="Tam Adı"
                                    autocomplete="off">
                            </div>
                        </div>
                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="email">Email</label>
                                <input type="email" class="form-control" id="email" name="email" placeholder="Email"
                                    autocomplete="off">
                            </div>
                        </div>
                        <div class="col-12">
                            <span>
                                <h4 v-if="generatePassword">Türetilen Parola:</h4> <a style="cursor:pointer"
                                    title="Kopyala" @click="copyPassword">
                                    <h3>{{ generatePassword }}</h3>
                                </a>
                            </span>
                        </div>
                        <div class="col-12">
                            <small v-if="generatePassword">Lütfen parolayı kopyalamayı unutmayın! Parola kopyalandıktan
                                sonra textler otomatik değişecektir.</small>
                        </div>
                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="password">Parola</label>
                                <input type="password" class="form-control" id="password" name="password"
                                    placeholder="Parola" autocomplete="off">
                            </div>
                        </div>
                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="re-password">Parola Yeniden</label>
                                <input type="password" class="form-control" id="re-password" name="re-password"
                                    placeholder="Parola Yeniden" autocomplete="off">
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-primary" @click="passwordGenerator">Parola Türet</button>

                    <hr />
                    <div class="form-label">
                        <h3>Kullanıcı Yetkileri</h3>
                    </div>
                    <div class="form-row">

                        <div class="form-group col-md-4">
                            <div class="form-group">
                                <div class="form-label">Üyelik Durumu</div>
                                <label class="form-switch">
                                    <input type="checkbox" name="form-switch-checkbox" class="form-switch-input">
                                    <span class="form-switch-indicator"></span>
                                    <span class="form-switch-description">Kullanıcı Onaysız/Onaylı</span>
                                </label>
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <div class="form-group">
                                <div class="form-label">Üyelik Durumu</div>
                                <label class="form-switch">
                                    <input type="checkbox" name="form-switch-checkbox" class="form-switch-input">
                                    <span class="form-switch-indicator"></span>
                                    <span class="form-switch-description">Kullanıcı Onaysız/Onaylı</span>
                                </label>
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <div class="form-group">
                                <div class="form-label">Üyelik Durumu</div>
                                <label class="form-switch">
                                    <input type="checkbox" name="form-switch-checkbox" class="form-switch-input">
                                    <span class="form-switch-indicator"></span>
                                    <span class="form-switch-description">Kullanıcı Onaysız/Onaylı</span>
                                </label>
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <div class="form-group">
                                <div class="form-label">Üyelik Durumu</div>
                                <label class="form-switch">
                                    <input type="checkbox" name="form-switch-checkbox" class="form-switch-input">
                                    <span class="form-switch-indicator"></span>
                                    <span class="form-switch-description">Kullanıcı Onaysız/Onaylı</span>
                                </label>
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <div class="form-group">
                                <div class="form-label">Üyelik Durumu</div>
                                <label class="form-switch">
                                    <input type="checkbox" name="form-switch-checkbox" class="form-switch-input">
                                    <span class="form-switch-indicator"></span>
                                    <span class="form-switch-description">Kullanıcı Onaysız/Onaylı</span>
                                </label>
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <div class="form-group">
                                <div class="form-label">Üyelik Durumu</div>
                                <label class="form-switch">
                                    <input type="checkbox" name="form-switch-checkbox" class="form-switch-input">
                                    <span class="form-switch-indicator"></span>
                                    <span class="form-switch-description">Kullanıcı Onaysız/Onaylı</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-primary" @click="save" id="saveButton">Kaydet</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    name: 'UserAdd',
    created() {
        document.title = "Yeni Kullanıcı Ekle"
        this.$store.state.header = true;
        this.$store.state.bread_crumb_title = "Yeni Kullanıcı"
        this.$store.state.bread_crumb_items = []
        this.$store.state.bread_crumb_items.push({ url: '/dashboard', name: "Gösterge Paneli" });
        this.$store.state.bread_crumb_items.push({ url: '', name: "Yeni Kullanıcı Ekle" });
    },
    data() {
        return {
            generatePassword: "",
        }
    },
    methods: {
        copyPassword() {
            if (!navigator.clipboard) {
                fallbackCopyTextToClipboard(this.generatePassword);
                return;
            }
            let a = this
            navigator.clipboard.writeText(this.generatePassword).then(function () {
                a.$toast("Parola kopyalandı", {
                    timeout: 1000
                });
                document.querySelector("input[name=password]").value = a.generatePassword;
                document.querySelector("input[name=re-password]").value = a.generatePassword;
            }, function (err) {
                console.error('Async: Could not copy text: ', err);
            });
        },
        save() {
            /** USER SAVE */
            document.getElementById('saveButton').disabled = true
            document.getElementById('saveButton').innerHTML = 'Kaydediliyor...'
            let fullName = document.querySelector("input[name=fullName]").value;
            let email = document.querySelector("input[name=email]").value;
            let password = document.querySelector("input[name=password]").value;
            let rePassword = document.querySelector("input[name=re-password]").value;
            if (email == "") {
                this.$toast("Email boş olamaz!", {
                    timeout: 1000
                });
                document.getElementById('saveButton').disabled = false
                document.getElementById('saveButton').innerHTML = 'Kaydet'
            }
            else if (password == "") {
                this.$toast("Parola boş olamaz!", {
                    timeout: 1000
                });
                document.getElementById('saveButton').disabled = false
                document.getElementById('saveButton').innerHTML = 'Kaydet'
            }
            else if (password == "") {
                this.$toast("Parola tekrarı boş olamaz!", {
                    timeout: 1000
                });
                document.getElementById('saveButton').disabled = false
                document.getElementById('saveButton').innerHTML = 'Kaydet'
            }
            else if (password != rePassword) {
                this.$toast("Parolalar uyuşmuyor!", {
                    timeout: 1000
                });
                document.getElementById('saveButton').disabled = false
                document.getElementById('saveButton').innerHTML = 'Kaydet'
            }
            else {
                let userInfo = {
                    fullname: fullName,
                    email: email,
                    password: password,
                }
                this.$store.dispatch("userAdd", userInfo).then((value) => {
                    document.getElementById('saveButton').disabled = false
                    document.getElementById('saveButton').innerHTML = 'Kaydet'
                    this.$toast("Kullanıcı ekleme başarılı", {
                        timeout: 2000
                    });
                    setTimeout(() => {
                        this.$router.push("/user/list");
                    }, 2000);
                })
            }
        },
        passwordGenerator() {
            var length = 10,
                charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
                retVal = "";
            for (var i = 0, n = charset.length; i < length; ++i) {
                retVal += charset.charAt(Math.floor(Math.random() * n));
            }
            this.generatePassword = retVal;
        }
    }
}
</script>
