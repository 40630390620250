<template>
  <div class="row">
    <div class="col-md-9">
      <div class="card m-b-20">
        <div class="card-header">
          <h3 class="card-title">Makale Güncelle</h3>
        </div>
        <div class="card-body">
          <div class="form-row">
            <div class="form-group col-md-12">
              <div class="form-group">
                <label class="form-label" for="blogName">Makale Başlığı</label>
                <input
                  type="text"
                  class="form-control"
                  id="blogName"
                  :value="data.title"
                  name="blogName"
                  placeholder="Makale Başlığı"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="form-group col-md-12">
              <div class="form-group">
                <label class="form-label" for="shortName">URL</label>
                <input
                  type="text"
                  class="form-control"
                  id="shortName"
                  :value="data.short_name"
                  name="shortName"
                  placeholder="URL"
                  disabled
                />
              </div>
            </div>
          </div>
          <vue-bottom-sheet max-width="95%" ref="myBottomSheet">
            <FileUploadComponent /> </vue-bottom-sheet
          ><br />
          <vue-bottom-sheet max-width="95%" ref="myCoverImageBottomSheet">
            <CoverImageComponent
              @input="updateCoverValue($event)"
            /> </vue-bottom-sheet
          ><br />
          <div class="row row-cards">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <div class="card-title">Makale İçeriği</div>
                </div>
                <div class="card-body">
                  <div id="content" v-html="data.content"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card card-body m-b-20 mb-5">
        <div class="row">
          <div class="col-lg-12">
            <div class="panel-group1" id="accordion2">
              <div class="card-header" style="border: none">
                <h4>Sık Sorulan Sorular</h4>
                <div class="card-options">
                  <button
                    class="btn btn-primary"
                    @click="newItem"
                    style="float: right"
                  >
                    Yeni Başlık
                  </button>
                </div>
              </div>
              <div
                class="panel panel-default mb-4 border p-0"
                v-for="(item, i) in faqlist"
                :key="'faq' + i"
              >
                <div class="panel-heading1">
                  <h4 class="panel-title1 input-group" style="margin: -1px">
                    <input
                      type="text"
                      class="form-control"
                      value="başlık"
                      v-model="item.title"
                    />
                    <button class="btn btn-primary" @click="removeItem(i)">
                      <i class="fa fa-trash-o"></i>
                    </button>
                    <a
                      class="btn btn-warning btn-sm accordion-toggle collapsed"
                      data-bs-toggle="collapse"
                      data-parent="#accordion2"
                      :href="'#collapse' + i"
                      aria-expanded="false"
                    ></a>
                  </h4>
                </div>
                <div
                  :id="'collapse' + i"
                  class="panel-collapse active collapse"
                  role="tabpanel"
                  aria-expanded="false"
                  style=""
                >
                  <div class="panel-body bg-white">
                    <div :id="'faqContent' + i" v-html="item.content"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card m-b-20">
        <div class="card-header">
          <h3 class="card-title">Makale Ayarları</h3>
        </div>
        <div class="card-body">
          <button class="btn btn-primary w-100" id="saveButton" @click="save">
            <i class="fa fa-save"></i> Kaydet
          </button>
          <a
            :href="'https://terapivitrini.com/blog/' + data.short_name"
            target="_blank"
            class="btn btn-secondary w-100 mt-2"
            id="saveButton"
          >
            Makaleye Git <i class="fa fa-angle-right"></i>
          </a>
          <div class="form-row">
            <FindInSelectComponent
              @response="handleData($event)"
              title="Kategori"
              :placeholder="title"
              action="categoryList"
              :selected="data.categories"
              multiple="true"
              showItem="category_name"
              valueItem="id"
              searchItem="category_name"
            />
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <div class="form-group">
                <label class="form-label" for="description">Açıklama</label>
                <textarea
                  type="text"
                  class="form-control"
                  id="description"
                  name="description"
                  v-model="data.description"
                  placeholder="Kısa Açıklama"
                ></textarea>
              </div>
            </div>
          </div>
          <TagListComponent :tags="tags" @tagList="tagL($event)" />
          <div class="form-row">
            <div class="form-group col-md-12">
              <div class="form-group">
                <div class="form-label">Makale Durumu</div>
                <label class="form-switch">
                  <input
                    type="checkbox"
                    name="publish"
                    class="form-switch-input"
                    :checked="data.publish == 1"
                  />
                  <span class="form-switch-indicator"></span>
                  <span class="form-switch-description"
                    >Makale Taslak/Yayınlandı</span
                  >
                </label>
              </div>
            </div>
            <div class="form-group col-md-12">
              <div class="form-group">
                <div class="form-label">Yorum Durumu</div>
                <label class="form-switch">
                  <input
                    type="checkbox"
                    name="comment"
                    class="form-switch-input"
                    v-model="data.comment_permission"
                  />
                  <span class="form-switch-indicator"></span>
                  <span class="form-switch-description"
                    >Yorumları açık/kapalı</span
                  >
                </label>
              </div>
            </div>
          </div>
          <img
            v-if="imagelink != ''"
            :src="'https://storage.terapivitrini.com/' + imagelink"
          />
          <button class="btn btn-primary w-100" @click="openCoverBottom">
            Kapak Resmi
          </button>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="exampleModal3"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="example-Modal3">İçerik Başlığı</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for="recipient-name" class="form-control-label"
                  >Başlık:</label
                >
                <input type="text" class="form-control" name="toc_title" />
              </div>
              <div class="form-group">
                <label for="message-text" class="form-control-label"
                  >İçerik:</label
                >
                <textarea class="form-control" name="toc_content"></textarea>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Kapat
            </button>
            <button type="button" class="btn btn-primary" @click="addToC">
              Ekle
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.panel-heading1 a.collapsed:before {
  right: 5px;
  top: 7px;
}
.panel-heading1 a:before {
  right: 5px;
  top: 7px;
}
</style>
<script>
import FindInSelectComponent from "../elementComponents/FindInSelectComponent.vue";
import FileUploadComponent from "../elementComponents/FileUploadComponent.vue";
import CoverImageComponent from "../elementComponents/CoverImageComponent.vue";
import TagListComponent from "../elementComponents/TagListComponent.vue";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "BlogUpdate",
  components: {
    FileUploadComponent,
    FindInSelectComponent,
    CoverImageComponent,
    TagListComponent,
  },
  created() {
    document.title = "Makale Güncelle";
    this.$store.state.header = true;
    this.$store
      .dispatch("blogGet", {
        short_name: this.$route.params.shortname,
        id: this.$route.params.id,
      })
      .then((value) => {
        if (value.length == 0) {
          this.$router.push("/blog/list");
        } else {
          this.data = value[0];
          this.imagelink = this.data.thumbnail;
          this.$store.state.bread_crumb_title = "Makale Güncelle";
          this.$store.state.bread_crumb_items = [];
          this.$store.state.bread_crumb_items.push({
            url: "/dashboard",
            name: "Gösterge Paneli",
          });
          this.$store.state.bread_crumb_items.push({
            url: "/blog/list",
            name: "Makale Listesi",
          });
          this.$store.state.bread_crumb_items.push({
            url: "",
            name: value[0].title,
          });
          this.tags = value[0].tags.toString();
          this.faqlist = value[0].faqcontent;
          this.faqlist.forEach((e, i) => {
            setTimeout(() => {
              this.runContent(i);
            }, 100);
          });
        }
      });
  },
  data() {
    return {
      tags: "",
      data: [],
      subCategory: 0,
      imagelink: "",
      title: "Kategori Seçin",
      faqlist: [],
    };
  },
  methods: {
    newItem() {
      this.faqlist.push({ title: "Yeni Başlık", content: "Yeni İçerik" });
      $(function (e) {
        $(".demo-accordion").accordionjs();
      });
      setTimeout(() => {
        this.runContent(this.faqlist.length - 1);
      }, 500);
    },
    runContent(id) {
      $("#faqContent" + id).summernote({
        toolbar: [
          [
            "style",
            ["highlight", "bold", "italic", "underline", "clear", "style"],
          ],
          ["font", ["strikethrough", "superscript", "subscript", "fontname"]],
          ["color", ["color"]],
          ["table", ["table"]],
          ["para", ["ul", "ol", "paragraph", "hr"]],
          ["insert", ["tableofcontent"]],
          ["link", ["linkDialogShow", "unlink"]],
          ["fontsize", ["fontsize"]],
          ["height", ["height"]],
          ["view", ["fullscreen", "codeview", "help"]],
        ],
      });
    },
    removeItem(id) {
      Swal.fire({
        title: "Soru silinecek?",
        text: "Bu işlem geri dönüşü olmayan sonuçlar ortaya çıkarabilir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sil",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          /**Listeden sil */
          this.faqlist.splice(id, 1);
        }
      });
    },
    handleData: function (e) {
      this.subCategory = e[0];
      this.title = e[1].toString();
    },
    tagL: function (e) {
      this.tags = e;
    },
    updateCoverValue(item) {
      this.imagelink = item;
      this.$refs.myCoverImageBottomSheet.close();
    },
    openCoverBottom() {
      this.$refs.myCoverImageBottomSheet.open();
    },
    open() {
      this.$refs.myBottomSheet.open();
    },
    close() {
      this.$refs.myBottomSheet.close();
    },
    closed() {
      this.$refs.myCoverImageBottomSheet.close();
    },
    save() {
      /** BLOG UPDATE */
      document.getElementById("saveButton").disabled = true;
      document.getElementById("saveButton").innerHTML =
        "Değişiklikler Kaydediliyor...";
      let blogName = document.querySelector("input[name=blogName]").value;
      let categoryId = this.subCategory.toString();
      let shortName = document.querySelector("input[name=shortName]").value;
      let description = document.querySelector(
        "textarea[name=description]"
      ).value;
      let publish = document.querySelector("input[name=publish]").checked
        ? 1
        : 0;
      let comment = document.querySelector("input[name=comment]").checked
        ? 1
        : 0;
      let content = $("#content").summernote("code");

      this.faqlist.forEach((element, index) => {
        element.content = $("#faqContent" + index).summernote("code");
      });
      if (blogName == "") {
        this.$toast("Makale adı boş olamaz", {
          timeout: 1000,
        });
        document.getElementById("saveButton").disabled = false;
        document.getElementById("saveButton").innerHTML = "Kaydet";
      } else if (shortName == "") {
        this.$toast("URL boş olamaz", {
          timeout: 1000,
        });
        document.getElementById("saveButton").disabled = false;
        document.getElementById("saveButton").innerHTML = "Kaydet";
      } else {
        let blogInfo = {
          blog_name: blogName,
          category_id: categoryId,
          short_name: shortName,
          description: description,
          tags: this.tags.toString(),
          publish: publish,
          comment: comment,
          content: content,
          imagelink: this.imagelink,
          faqcontent: JSON.stringify(this.faqlist),
          id: this.$route.params.id,
        };
        this.$store.dispatch("blogUpdate", blogInfo).then((value) => {
          document.getElementById("saveButton").disabled = false;
          document.getElementById("saveButton").innerHTML = "Kaydet";
          this.$toast("Makale güncelleme başarılı", {
            timeout: 2000,
          });
          setTimeout(() => {
            this.$router.push(
              "/blog/update/" + value.short_name + "/" + value.updated_id
            );
          }, 2000);
        });
      }
    },
    remove() {
      Swal.fire({
        title: "Makale silinecek?",
        text: "Bu işlem geri dönüşü olmayan sonuçlar ortaya çıkarabilir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sil",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          let blogInfo = {
            id: this.$route.params.id,
          };
          this.$store.dispatch("blogRemove", blogInfo).then((value) => {
            Swal.fire("Silindi!", "Makale silme işlemi tamamlandı", "success");
            setTimeout(() => {
              this.$router.push("/blog/list");
            }, 2000);
          });
        }
      });
    },
    addToC() {
      let toc_title = document.querySelector("input[name=toc_title]").value;
      let toc_content = document.querySelector(
        "textarea[name=toc_content]"
      ).value;
      if (
        toc_title != null &&
        toc_title != "" &&
        toc_content != null &&
        toc_content != ""
      ) {
        if (
          document.querySelectorAll("div.note-editable>div#toc_top").length == 0
        ) {
          var node = document.createElement("div");
          let id = "toc_top";
          node.id = id;
          $("#content").summernote("insertNode", node);
          //$editable.find("div[id='toc_top']").remove();
        }
        if (
          document.querySelectorAll("div.note-editable>div#toc_content")
            .length == 0
        ) {
          var node2 = document.createElement("div");
          let id2 = "toc_content";
          node2.id = id2;
          $("#content").summernote("insertNode", node2);
          //$editable.find("div[id='toc_content']").remove();
        }
        var count = document.querySelectorAll(
          "div.note-editable>div#toc_top a"
        ).length;
        var list = document.querySelector("div.note-editable>div#toc_top");
        var content = document.querySelector(
          "div.note-editable>div#toc_content"
        );
        var hList = document.querySelector(
          "div.note-editable>div#toc_top"
        ).innerHTML;
        var mContent = document.querySelector(
          "div.note-editable>div#toc_content"
        ).innerHTML;
        hList +=
          "<li><a href='#go_" + (count + 1) + "'>" + toc_title + "</a></li>";
        mContent +=
          "<div id='go_" +
          (count + 1) +
          "'><h2>" +
          toc_title +
          "</h2><p>" +
          toc_content +
          "</p></div>";

        list.innerHTML = hList;
        content.innerHTML = mContent;
        document.querySelector("input[name=toc_title]").value = "";
        document.querySelector("textarea[name=toc_content]").value = "";
      } else {
        this.$toast("Alanlar boş geçilemez!", {
          timeout: 1000,
        });
      }
    },
    shortLinkGenerator() {
      let blogName = document.querySelector("input[name=blogName]").value;
      blogName = blogName.toLowerCase();
      document.querySelector("input[name=shortName]").value = blogName
        .replaceAll("ğ", "g")
        .replaceAll("ü", "u")
        .replaceAll("ş", "s")
        .replaceAll("ı", "i")
        .replaceAll("i̇", "i")
        .replaceAll("İ", "i")
        .replaceAll("ö", "o")
        .replaceAll("ç", "c")
        .replaceAll(" ", "-");
    },
  },
  watch: {
    data() {
      let _this = this;
      var MediaButton = function (context) {
        var ui = $.summernote.ui;
        var button = ui.button({
          contents: '<i class="fa fa-image"/>',
          tooltip: "Highlight text with red color",
          click: function () {
            _this.$refs.myBottomSheet.open();
          },
        });

        return button.render();
      };
      $.extend($.summernote.plugins, {
        tableofcontent: function (context) {
          var ui = $.summernote.ui;
          var $note = context.layoutInfo.note;
          var $editable = context.layoutInfo.editable;
          context.memo("button.tableofcontent", function () {
            var button = ui.button({
              contents: "İçindekiler",
              click: function () {
                $("#exampleModal3").modal("show");
                _this.$editable = $editable;
                _this.$note = $note;
              },
            });
            var $tableofcontent = button.render();
            return $tableofcontent;
          });
        },
      });

      $(document).ready(function () {
        $("#content").summernote({
          toolbar: [
            [
              "style",
              ["highlight", "bold", "italic", "underline", "clear", "style"],
            ],
            ["font", ["strikethrough", "superscript", "subscript", "fontname"]],
            ["color", ["color"]],
            ["table", ["table"]],
            ["para", ["ul", "ol", "paragraph", "hr"]],
            ["insert", ["tableofcontent"]],
            ["link", ["linkDialogShow", "unlink"]],
            ["view", ["fullscreen", "codeview", "help"]],
            ["fontsize", ["fontsize"]],
            ["height", ["height"]],
          ],
          buttons: {
            highlight: MediaButton,
          },
        });
      });
    },
  },
};
</script>
