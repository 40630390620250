<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="panel panel-primary">
        <div>
          <div class="user-tabs mb-4">
            <!-- Tabs -->
            <ul class="nav panel-tabs">
              <li @click="approvedProfileChange(-1)">
                <a
                  :class="
                    'btn btn-primary btn-sm ' +
                    (approved_profile == -1 ? 'selected_item' : '')
                  "
                  data-bs-toggle="tab"
                >
                  Tümü ({{ totalsize[0] }})
                </a>
              </li>
              <li class="" @click="approvedProfileChange(1)">
                <a
                  :class="
                    'btn btn-success btn-sm ' +
                    (approved_profile == 1 ? 'selected_item' : '')
                  "
                  data-bs-toggle="tab"
                >
                  Onaylı ({{ totalsize[1] }})
                </a>
              </li>
              <li class="" @click="approvedProfileChange(2)">
                <a
                  :class="
                    'btn btn-warning btn-sm ' +
                    (approved_profile == 2 ? 'selected_item' : '')
                  "
                  data-bs-toggle="tab"
                >
                  Onay Bekleyen ({{ totalsize[2] }})
                </a>
              </li>

              <li class="" @click="approvedProfileChange(3)">
                <a
                  :class="
                    'btn btn-danger btn-sm ' +
                    (approved_profile == 3 ? 'selected_item' : '')
                  "
                  data-bs-toggle="tab"
                >
                  Reddedilen ({{ totalsize[3] }})
                </a>
              </li>
              <li class="" @click="approvedProfileChange(0)">
                <a
                  :class="
                    'btn btn-secondary btn-sm ' +
                    (approved_profile == 0 ? 'selected_item' : '')
                  "
                  data-bs-toggle="tab"
                >
                  Talep Edilmemiş ({{ totalsize[4] }})
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="tab-content">
            <div class="tab-pane active" id="tab1">
              <div class="mail-option">
                <div class="btn-group">
                  <div class="form-group">
                    <label>Başlangıç:</label>
                    <input
                      v-model="begin"
                      style="border-radius: 5px 0px 0px 5px"
                      type="date"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <label>Bitiş:</label>
                    <input
                      v-model="end"
                      :min="begin"
                      style="
                        border-radius: 0px;
                        border-left: 0px;
                        border-right: 0px;
                      "
                      type="date"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      style="
                        border-radius: 0px 5px 5px 0px;
                        margin-top: 29px !important;
                        height: -webkit-fill-available;
                      "
                      type="buton"
                      class="btn btn-primary mt-5"
                      value="Filtrele"
                      @click="getMemberList"
                    />
                  </div>
                </div>

                <ul class="unstyled inbox-pagination">
                  <li>
                    <div
                      class="form-inline nav-search"
                      style="
                        padding: 0 !important;
                        padding-right: 10px !important;
                      "
                    >
                      <input
                        type="search"
                        class="form-control header-search"
                        placeholder="Uzmanlarda Ara"
                        aria-label="Ara"
                        @keyup="getPageFilter"
                        v-model="search"
                      />
                      <button
                        class="btn btn-primary"
                        @click="getMemberList"
                        type="button"
                      >
                        <i class="fa fa-search"></i>
                      </button>
                    </div>
                  </li>
                  <li>
                    <router-link
                      to="/member/create"
                      class="btn bg-primary-transparent text-primary"
                    >
                      <i class="fa fa-plus"></i> Yeni Üye Ekle</router-link
                    >
                  </li>
                </ul>
              </div>
              
              <div class="table-responsive border-top">
                <table
                  class="table card-table table-bordered table-hover table-vcenter text-nowrap"
                >
                  <tbody>
                    <tr>
                      <th class="w-1">Profil</th>
                      <th class="w-1">Email</th>
                      <th class="w-1">Tam Adı</th>
                      <th>Mail Durum</th>
                      <th>Üyelik Türü</th>
                      <th>Son İşlem</th>
                      <th>Oluşturulma</th>
                      <th>Profil Durumu</th>
                      <th>İşlem</th>
                    </tr>

                    <tr v-for="(item, i) in list" :key="i">
                      <td>
                        <img
                          :src="
                            'https://storage.terapivitrini.com/' +
                            item.profile_image_url
                          "
                          class="avatar avatar-lg brround"
                        />
                      </td>
                      <td>{{ item.email }}</td>
                      <td>{{ item.fullname }}</td>
                      <td>
                        <template v-if="item.approved == 0"
                          ><span class="status-icon bg-danger"></span>
                          Onaysız</template
                        >
                        <template v-if="item.approved == 1"
                          ><span class="status-icon bg-success"></span>
                          Onaylı</template
                        >
                      </td>
                      <td>
                        <template v-if="item.type == 'EXPERT'">
                          UZMAN
                        </template>
                        <template v-if="item.type == 'MEMBER'">
                          NORMAL ÜYE
                        </template>
                      </td>
                      <td>{{ item.updated_at }}</td>
                      <td>{{ item.created_at }}</td>
                      <td>
                        <template v-if="item.approved_profile == 0">
                          <span class="status-icon bg-black"></span> Talep Yok
                        </template>
                        <template v-if="item.approved_profile == 1">
                          <span class="status-icon bg-success"></span> Onaylı
                        </template>
                        <template v-if="item.approved_profile == 2">
                          <span class="status-icon bg-warning"></span> Onay
                          Bekliyor
                        </template>
                        <template v-if="item.approved_profile == 3">
                          <span class="status-icon bg-danger"></span>
                          Reddedildi
                        </template>
                      </td>
                      <td>
                        <div class="btn-list">
                          <button
                            class="btn bg-primary-transparent text-primary"
                            @click="preview(item.id)"
                            title="Profili İncele"
                          >
                            <i class="fa fa-search"></i>
                          </button>
                          <button
                            class="btn bg-primary-transparent text-primary"
                            @click="update(item.id)"
                          >
                            Güncelle
                          </button>
                          <button
                            class="btn bg-danger-transparent text-primary"
                            @click="remove(item.id)"
                          >
                            Sil
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <ul class="unstyled inbox-pagination">
                  <li>
                    <span
                      >{{ (currentpage - 1) * perpage }}-{{
                        (currentpage - 1) * perpage + perpage
                      }}
                      arasında, Toplam
                      {{ totalsize[approved_profile] }} kayıt</span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul class="pagination mb-5">
        <li
          :class="'page-item page-prev ' + (currentpage == 1 ? 'disabled' : '')"
        >
          <a class="page-link" href="#" tabindex="-1">Önceki</a>
        </li>
        <li
          v-for="(s, i) in pagesize"
          :key="i"
          :class="'page-item ' + (i + 1 == currentpage ? 'active' : '')"
        >
          <a v-on:click="changePage(i + 1)" class="page-link">{{ i + 1 }}</a>
        </li>
        <li
          :class="
            'page-item page-next ' +
            (currentpage == pagesize || currentpage == 1 ? 'disabled' : '')
          "
        >
          <a class="page-link" href="#">Sonraki</a>
        </li>
      </ul>
    </div>
  </div>
</template>
<style scoped>
.memberItem {
  width: 100%;
  background: #ddd;
  border-radius: 10px;
  min-height: 80px;
  border-left: 15px solid orange;
  box-shadow: 0px 3px 10px -5px #474747;
}
.selected_item {
  box-shadow: 0 0px 0px 4px #5a338082;
}
.panel-tabs > li {
  border-left: 0;
  margin: 2px;
}
</style>
<script>
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "MemberList",
  components: {},
  created() {
    document.title = "Uzman Listesi";
    this.$store.state.header = true;
    this.$store.state.bread_crumb_title = "Uzman Listesi";
    this.$store.state.bread_crumb_items = [];
    this.$store.state.bread_crumb_items.push({
      url: "/dashboard",
      name: "Gösterge Paneli",
    });
    this.$store.state.bread_crumb_items.push({
      url: "",
      name: "Uzman Listesi",
    });
    if (this.$route.query.page && this.$route.query.page != null) {
      this.currentpage = this.$route.query.page;
    }
    if (this.$route.query.begin && this.$route.query.begin != null) {
      this.begin = this.$route.query.begin;
    }
    if (this.$route.query.end && this.$route.query.end != null) {
      this.end = this.$route.query.end;
    }
    if (this.$route.query.search && this.$route.query.search != null) {
      this.search = this.$route.query.search;
    }
    if (
      this.$route.query.approved_profile &&
      this.$route.query.approved_profile != null
    ) {
      this.approved_profile = this.$route.query.approved_profile;
    }
    this.getMemberList();
  },
  data() {
    return {
      list: [],
      currentpage: 1,
      perpage: 50,
      totalsize: 0,
      pagesize: 0,
      userType: "EXPERT",
      approved_profile: 1,
      search: "",
      begin: "",
      end: "",
    };
  },
  methods: {
    changePage(i) {
      this.$router.replace({
        query: {
          search: this.search,
          page: i,
          begin: this.begin,
          end: this.end,
          approved_profile: this.approved_profile,
        },
      });
    },
    approvedProfileChange(i) {
      this.currentpage = 1;
      this.approved_profile = i;
      this.getMemberList();
    },
    filter(item) {
      this.userType = item;
      this.getMemberList();
    },
    getMemberList() {
      this.list = [];
      this.$router.replace({
        query: {
          search: this.search,
          page: this.currentpage,
          begin: this.begin,
          end: this.end,
          approved_profile: this.approved_profile,
        },
      });
      let memberInfo = "";
      if (this.approved_profile < 0) {
        memberInfo = {
          begin: (this.currentpage - 1) * this.perpage,
          perpage: this.perpage,
          type: this.userType,
          search: this.search,
          beginD: this.begin,
          endD: this.endD,
        };
      } else {
        memberInfo = {
          begin: (this.currentpage - 1) * this.perpage,
          perpage: this.perpage,
          type: this.userType,
          approved_profile: this.approved_profile,
          search: this.search,
          beginD: this.begin,
          endD: this.endD,
        };
      }

      this.$store.dispatch("memberList", memberInfo).then((value) => {
        this.list = value.list;
        this.totalsize = value.size;
        this.pagesize = value.pages;
      });
    },
    preview(id) {
      this.$router.push("/member/expert/preview/" + id);
    },
    update(id) {
      this.$router.push("/member/update/" + id);
    },
    getPageFilter() {
      if (event.key === "Enter") {
        this.getMemberList();
      }
    },
    remove(id) {
      Swal.fire({
        title: "Kullanıcı silinecek?",
        text: "Bu işlem geri dönüşü olmayan sonuçlar ortaya çıkarabilir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sil",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          let memberInfo = {
            id: id,
          };
          this.$store.dispatch("memberRemove", memberInfo).then((value) => {
            Swal.fire("Silindi!", "Üye silme işlemi tamamlandı", "success");
            this.getMemberList();
          });
        }
      });
    },
  },
  watch: {
    "$route.query.page": function () {
      if (this.$route.query.page && this.$route.query.page != null) {
        this.currentpage = this.$route.query.page;
      }
      this.getMemberList();
    },
  },
};
</script>
