<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="panel panel-primary">
            <div class="card-body">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <div class="form-group">
                    <label class="form-label" for="title">Soru Cümlesi</label>
                    <input
                      type="text"
                      v-model="job_question"
                      class="form-control"
                      placeholder="Sorunuzu girin"
                      autocomplete="off"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <button id="saveButton" class="btn btn-primary" @click="save">
                Kaydet
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "JobAdd",
  created() {
    document.title = "Görev Güncelle";
    this.$store.state.header = true;
    this.$store.state.search = false;
    this.$store.state.bread_crumb_title = "Görev Güncelle";
    this.$store.state.bread_crumb_items = [];
    this.$store.state.bread_crumb_items.push({
      url: "/dashboard",
      name: "Gösterge Paneli",
    });
    this.$store.state.bread_crumb_items.push({
      url: "",
      name: "Yeni Görev Ekle",
    });
    this.$store
      .dispatch("jobGet", { id: this.$route.params.id })
      .then((value) => {
        this.job_question = value.question;
        this.status=value.status
      });
  },
  data() {
    return {
      job_question: "",
      status:0,
    };
  },
  methods: {
    save() {
      /** JOB UPDATE */
      if (this.job_question == "") {
        this.$toast("Soru cümlesi boş olamaz", {
          timeout: 1000,
        });
      } else {
        document.getElementById("saveButton").disabled = true;
        document.getElementById("saveButton").innerHTML = "Kaydediliyor...";
        let jobCreate = {
          id:this.$route.params.id,
          job: this.job_question
        };

        this.$store.dispatch("jobUpdate", jobCreate).then((value) => {
          document.getElementById("saveButton").disabled = false;
          document.getElementById("saveButton").innerHTML = "Kaydet";
          this.$toast("Görev kayıt başarılı", {
            timeout: 2000,
          });
        });
      }
    },
  },
};
</script>