<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="tab-content">
            <!--Create New Appointment Button-->
            <div class="form-group">
              <router-link
                
                type="button"
                :to="'/member/expert/list/appointment'"
                class="form-control btn btn-primary"
              >Yeni Randevu Oluştur</router-link>
            </div>
            <div class="tab-pane active" id="tab1">
              <div class="mail-option">
                <div class="btn-group">
                  <div class="form-group">
                    <label>Başlangıç:</label>
                    <input
                      v-model="begin"
                      style="border-radius: 5px 0px 0px 5px"
                      type="date"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <label>Bitiş:</label>
                    <input
                      v-model="end"
                      :min="begin"
                      style="
                        border-radius: 0px;
                        border-left: 0px;
                        border-right: 0px;
                      "
                      type="date"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <label>Durum:</label>
                    <select
                      v-model="status"
                      style="
                        border-radius: 0px;
                        border-left: 1px solid #e9ebf3;
                        border-right: 0px;
                      "
                      type="date"
                      class="form-control"
                    >
                      <option disabled value="">Seçiniz</option>
                      <option value="0">Bekliyor</option>
                      <option value="1">Onaylandı</option>
                      <option value="2">İptal Edildi</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label>Görüşme Yöntemi:</label>
                    <select
                      v-model="type"
                      style="
                        border-radius: 0px;
                        border-left: 1px solid #e9ebf3;
                        border-right: 0px;
                      "
                      type="date"
                      class="form-control"
                    >
                      <option disabled value="">Seçiniz</option>
                      <option value="3">Online</option>
                      <option value="1">Yüz Yüze</option>
                      <option value="2">Evde</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label>Görüşme Durumu:</label>
                    <select
                      v-model="doneStatus"
                      style="
                        border-radius: 0px;
                        border-left: 1px solid #e9ebf3;
                        border-right: 0px;
                      "
                      type="date"
                      class="form-control"
                    >
                      <option disabled value="">Seçiniz</option>
                      <option value="0">Bekliyor</option>
                      <option value="1">Tamamlandı</option>
                      <option value="2">Tamamlanmadı</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <input
                      style="
                        border-radius: 0px 5px 5px 0px;
                        margin-top: 29px !important;
                        height: -webkit-fill-available;
                      "
                      type="buton"
                      class="btn btn-primary mt-5"
                      value="Filtrele"
                      @click="getMemberAppointmentList"
                    />
                  </div>
                </div>

                <ul class="unstyled inbox-pagination">
                  <li>
                    <div
                      class="form-inline nav-search"
                      style="
                        padding: 0 !important;
                        padding-right: 10px !important;
                      "
                    >
                      <input
                        type="search"
                        class="form-control header-search"
                        placeholder="Randevularda Ara"
                        @keyup="getPageFilter"
                        aria-label="Ara"
                        v-model="search"
                      />
                      <button
                        class="btn btn-primary"
                        @click="getMemberAppointmentList"
                        type="button"
                      >
                        <i class="fa fa-search"></i>
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="table-responsive border-top">
                <div>
                  <li><b>Genel Toplam:</b> {{ generalTotal.toFixed(2) }}</li>
                  <li>
                    <b>Aktarım Toplamı:</b>
                    {{ generalTransferredTotal.toFixed(2) }}
                  </li>
                  <li>
                    <b>Komisyon Toplamı:</b>
                    {{ generalCommissionTotal.toFixed(2) }}
                  </li>
                  <li>
                    <b>İşlem Ücreti Toplamı:</b>
                    {{ generalProcessPriceTotal.toFixed(2) }}
                  </li>
                </div>
                <table style="width: 100%">
                  <tbody>
                    <tr>
                      <th></th>
                      <th>Uzman Kartı</th>
                      <th>Danışan Kartı</th>
                      <th>Tarih & Saat</th>
                      <th>Hizmet</th>
                      <th>Fiyat</th>
                      <th>Açıklama</th>
                      <th>Yöntem</th>
                      <th>Görüşme Durumu</th>
                      <th>Tarih</th>
                    </tr>
                    <tr
                      v-for="(item, i) in list"
                      :key="i"
                      :class="
                        item.status == 0
                          ? 'wait'
                          : item.status == 1
                          ? 'green'
                          : 'red'
                      "
                    >
                      <td>
                        <div class="item-action dropdown">
                          <a
                            href="javascript:void(0)"
                            data-bs-toggle="dropdown"
                            class="icon"
                            ><i class="fe fe-more-vertical fs-20 text-dark"></i
                          ></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a
                              href="javascript:void(0)"
                              @click="
                                openNewAppointment(item.expert.id, item.id)
                              "
                              class="dropdown-item"
                              >Randevuyu Yenile</a
                            >
                            <a href="javascript:void(0)" class="dropdown-item"
                              >İptal Et</a
                            >
                          </div>
                        </div>
                      </td>
                      <td>
                        <router-link :to="'../preview/' + item.expert.id">
                          <div
                            class="card p-2"
                            style="text-align: center; width: 120px"
                          >
                            <div class="title">
                              <img
                                :src="
                                  'https://storage.terapivitrini.com/' +
                                  item.expert.profile_image_url
                                "
                                class="avatar avatar-lg brround"
                              />
                            </div>
                            <div class="body">
                              {{ item.expert.fullname }}
                              <br />
                              <small>{{ item.expert.email }}</small>
                            </div>
                          </div>
                        </router-link>
                      </td>
                      <td v-if="item.member!=null">
                        <router-link  :to="'../preview/' + item.member.id">
                          <div
                            class="card p-2"
                            style="text-align: center; width: 120px"
                          >
                            <div class="title">
                              <img
                                :src="
                                  'https://storage.terapivitrini.com/' +
                                  item.member.profile_image_url
                                "
                                class="avatar avatar-lg brround"
                              />
                            </div>
                            <div class="body">
                              {{ item.member.fullname }}<br />
                              <small>{{ item.member.email }}</small>
                            </div>
                          </div>
                        </router-link>
                      </td>
                      <td>{{ item.date }}</td>
                      <td>
                        {{ item.experience_name }}
                      </td>
                      <td>
                        {{ item.description }}
                      </td>
                      <td>
                        <b>Tam Fiyat:</b><br />
                        <small>{{ item.price.toFixed(2) }} TL</small><br />
                        <b>Aktarılacak Fiyat:</b><br />
                        <small>{{ item.tranfered.toFixed(2) }} TL</small><br />
                        <b>Komisyon:</b><br />
                        <small>{{ item.commission.toFixed(2) }} TL</small><br />
                        <b>İşlem Ücreti:</b><br />
                        <small>{{ item.processprice.toFixed(2) }} TL</small
                        ><br />
                      </td>
                      <td>
                        <b>Ödeme Yöntemi:</b><br />
                        <small>{{ item.payment.title }}</small
                        ><br />
                        <b>Görüşme Yöntemi:</b><br />
                        <template v-if="item.selected_methods == 3">
                          Online
                        </template>
                        <template v-if="item.selected_methods == 1">
                          Yüz yüze
                        </template>
                        <template v-if="item.selected_methods == 2">
                          Evde
                        </template>
                      </td>
                      <td>
                        <template v-if="item.is_appointment_done == 0">
                          <span class="status-icon bg-black"></span> Bekliyor
                        </template>
                        <template v-if="item.is_appointment_done == 1">
                          <span class="status-icon bg-success"></span>
                          Tamamlandı
                        </template>
                        <template v-if="item.is_appointment_done == 2">
                          <span class="status-icon bg-danger"></span>
                          Tamamlanmadı
                        </template>
                        <template
                          v-if="
                            item.transport_id != null || item.transport_id > 0
                          "
                        >
                          <span class="status-icon bg-black"></span>
                          Randevu Yenilendi
                        </template>
                      </td>
                      <td>
                        <b>Oluşturma:</b><br />
                        <small>{{ item.created_at }}</small
                        ><br />
                        <b>Güncelleme:</b><br />
                        <small>{{ item.updated_at }}</small>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <ul class="unstyled inbox-pagination">
                  <li>
                    <span
                      >{{ (currentpage - 1) * perpage }}-{{
                        (currentpage - 1) * perpage + perpage
                      }}
                      arasında, Toplam
                      {{ totalsize }} kayıt</span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul class="pagination mb-5">
        <li
          :class="'page-item page-prev ' + (currentpage == 1 ? 'disabled' : '')"
        >
          <a class="page-link" href="#" tabindex="-1">Önceki</a>
        </li>
        <li
          v-for="(s, i) in pagesize"
          :key="i"
          :class="'page-item ' + (i + 1 == currentpage ? 'active' : '')"
        >
          <a v-on:click="changePage(i + 1)" class="page-link">{{ i + 1 }}</a>
        </li>
        <li
          :class="
            'page-item page-next ' +
            (currentpage == pagesize || currentpage == 1 ? 'disabled' : '')
          "
        >
          <a class="page-link" href="#">Sonraki</a>
        </li>
      </ul>
    </div>
  </div>
</template>
    <style>
.green {
  border-left: 5px solid green !important;
  background: #00800012;
}
.wait {
  border-left: 5px solid grey !important;
  background: #57575712;
}
.red {
  border-left: 5px solid red !important;
  background: #80002912;
}
</style>
    <script>
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "MemberList",
  components: {},
  created() {
    document.title = "Randevu Listesi";
    this.$store.state.header = true;
    this.$store.state.bread_crumb_title = "Randevu Listesi";
    this.$store.state.bread_crumb_items = [];
    this.$store.state.bread_crumb_items.push({
      url: "/dashboard",
      name: "Gösterge Paneli",
    });
    this.$store.state.bread_crumb_items.push({
      url: "",
      name: "Randevu Listesi",
    });
    if (this.$route.query.page && this.$route.query.page != null) {
      this.currentpage = this.$route.query.page;
    }
    if (this.$route.query.begin && this.$route.query.begin != null) {
      this.begin = this.$route.query.begin;
    }
    if (this.$route.query.end && this.$route.query.end != null) {
      this.end = this.$route.query.end;
    }
    if (this.$route.query.search && this.$route.query.search != null) {
      this.search = this.$route.query.search;
    }
    if (this.$route.query.type && this.$route.query.type != null) {
      this.type = this.$route.query.type;
    }
    if (this.$route.query.doneStatus && this.$route.query.doneStatus != null) {
      this.doneStatus = this.$route.query.doneStatus;
    }
    if (this.$route.query.status && this.$route.query.status != null) {
      this.status = this.$route.query.status;
    }

    this.getMemberAppointmentList();
  },
  data() {
    return {
      list: [],
      currentpage: 1,
      perpage: 50,
      totalsize: 0,
      pagesize: 0,
      doneStatus: "",
      status: "",
      type: "",
      search: "",
      begin: "",
      end: "",
      generalTotal: 0,
      generalTransferredTotal: 0,
      generalCommissionTotal: 0,
      generalProcessPriceTotal: 0,
    };
  },
  methods: {
    openNewAppointment(id, appointmentId) {
      if (appointmentId > 0) {
        this.$router.push(
          "/member/expert/appointment/create?uid=" +
            id +
            "&aid=" +
            appointmentId
        );
      } else {
        this.$router.push("/member/expert/appointment/create?uid=" + id);
      }
    },
    approvedProfileChange(i) {
      this.status = i;
      this.getMemberAppointmentList();
    },
    filter(item) {
      this.getMemberAppointmentList();
    },
    changePage(i) {
      this.$router.replace({
        query: {
          search: this.search,
          page: i,
          begin: this.begin,
          end: this.end,
        },
      });
    },
    getMemberAppointmentList() {
      this.list = [];
      let memberInfo;
      this.$router.replace({
        query: {
          search: this.search,
          page: this.page,
          begin: this.begin,
          end: this.end,
          status: this.status,
          type: this.type,
          doneStatus: this.doneStatus,
        },
      });
      memberInfo = {
        begin: (this.currentpage - 1) * this.perpage,
        perpage: this.perpage,
        status: parseInt(this.status),
        search: this.search,
        beginD: this.begin,
        endD: this.end,
        type: this.type,
        doneStatus: parseInt(this.doneStatus),
      };

      this.$store
        .dispatch("memberExpertAppointments", memberInfo)
        .then((value) => {
          this.list = value.list;
          this.totalsize = value.size;
          this.pagesize = value.pages;
          this.generalTotal = value.generalTotal;
          this.generalTransferredTotal = value.generalTransferredTotal;
          this.generalCommissionTotal = value.generalCommissionTotal;
          this.generalProcessPriceTotal = value.generalProcessPriceTotal;
        });
    },
    remove(id) {
      Swal.fire({
        title: "Randevu silinecek?",
        text: "Bu işlem geri dönüşü olmayan sonuçlar ortaya çıkarabilir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sil",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          let memberInfo = {
            id: id,
          };
          this.$store
            .dispatch("appointmentRemove", memberInfo)
            .then((value) => {
              Swal.fire(
                "Silindi!",
                "Eğitim silme işlemi tamamlandı",
                "success"
              );
              this.getMemberAppointmentList();
            });
        }
      });
    },
    getPageFilter() {
      if (event.key === "Enter") {
        this.getMemberAppointmentList();
      }
    },
  },
  watch: {
    "$route.query.page": function () {
      if (this.$route.query.page && this.$route.query.page != null) {
        this.currentpage = this.$route.query.page;
      }
      this.getMemberAppointmentList();
    },
  },
};
</script>
    