<template>
  <div>
    <div class="row">
      <div class="col-xl-3 col-lg-6 col-md-12">
        <div class="card">
          <div class="card-body text-center">
            <h5>Üye</h5>
            <div class="text-center">
              <div class="spinner-border" role="status" v-if="!isLoad">
                <span class="sr-only">Loading...</span>
              </div>
            </div>

            <h2 class="counter" v-if="isLoad">{{ list.totalMember }}</h2>
            <router-link to="/member/list" class="btn btn-outline-primary btn-sm">Daha Fazla</router-link>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-6 col-md-12">
        <div class="card">
          <div class="card-body text-center">
            <h5>Uzman</h5>
            <div class="text-center">
              <div class="spinner-border" role="status" v-if="!isLoad">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <h2 class="counter" v-if="isLoad">{{ list.totalExpert }}</h2>
            <router-link to="/member/expert/list" class="btn btn-outline-warning btn-sm">Daha Fazla</router-link>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-6 col-md-12">
        <div class="card">
          <div class="card-body text-center">
            <h5>Yorum</h5>
            <div class="text-center">
              <div class="spinner-border" role="status" v-if="!isLoad">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <h2 class="counter" v-if="isLoad">{{ list.totalComment }}</h2>
            <router-link to="/comment/blog/list" class="btn btn-outline-success btn-sm">Daha Fazla</router-link>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-6 col-md-12">
        <div class="card">
          <div class="card-body text-center">
            <h5>Blog</h5>
            <div class="text-center">
              <div class="spinner-border" role="status" v-if="!isLoad">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <h2 class="counter" v-if="isLoad">{{ list.totalBlog }}</h2>
            <router-link to="/blog/list" class="btn btn-outline-danger btn-sm">Daha Fazla</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-md-12">
        <div class="card shadow">
          <div class="card-header">
            <h3 class="card-title">Randevu İstatistiği</h3>
          </div>
          <div class="card-body">
            <div class="text-center">
              <div class="spinner-border" role="status" v-if="!isLoad">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div class="row" v-if="isLoad">
              <div class="col-xl-6">
                <div class="card shadow">
                  <div class="card-body">
                    <div class="widget text-center">
                      <p class="text-muted">Toplam</p>
                      <h2 class="text-xxl text-primary">
                        {{ list.totalAppointments }}
                      </h2>
                      <p class="mb-0">Randevu</p>
                      <router-link to="/member/expert/appointment/list"
                       class="btn btn-outline-primary btn-sm">Göster</router-link>
                    </div>
                  </div>
                </div>
                <div class="card shadow mb-xl-0">
                  <div class="card-body">
                    <div class="widget text-center">
                      <p class="text-muted">Onaylanan</p>
                      <h2 class="text-xxl text-primary">
                        {{ list.approvedAppointment }}
                      </h2>
                      <p class="mb-0">Randevu</p>
                      <router-link to="/member/expert/appointment/list?status=1"
                       class="btn btn-outline-primary btn-sm">Göster</router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="card shadow">
                  <div class="card-body">
                    <div class="widget text-center">
                      <p class="text-muted">Tamamlanan</p>
                      <h2 class="text-xxl text-primary">
                        {{ list.appointmentDone }}
                      </h2>
                      <p class="mb-0">Randevu</p>
                      <router-link to="/member/expert/appointment/list?status=1&doneStatus=1"
                       class="btn btn-outline-primary btn-sm">Göster</router-link>
                    </div>
                  </div>
                </div>
                <div class="card shadow mb-0">
                  <div class="card-body">
                    <div class="widget text-center">
                      <p class="text-muted">İptal Edilen</p>
                      <h2 class="text-xxl text-primary">
                        {{ list.appointmentCancel }}
                      </h2>
                      <p class="mb-0">Randevu</p>
                      <router-link to="/member/expert/appointment/list?status=2"
                       class="btn btn-outline-primary btn-sm">Göster</router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6 mt-5">
                <div class="card shadow mb-0">
                  <div class="card-body">
                    <div class="widget text-center">
                      <p class="text-muted">Onaylananlardan İptal Edilen</p>
                      <h2 class="text-xxl text-primary">
                        {{ list.cancelFromApproved }}
                      </h2>
                      <p class="mb-0">Randevu</p>
                      <router-link to="/member/expert/appointment/list?status=1&doneStatus=2"
                       class="btn btn-outline-primary btn-sm">Göster</router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6 mt-5">
                <div class="card shadow">
                  <div class="card-body">
                    <div class="widget text-center">
                      <p class="text-muted">Bekleyen</p>
                      <h2 class="text-xxl text-primary">
                        {{ list.preProcess }}
                      </h2>
                      <p class="mb-0">Randevu</p>
                      <router-link to="/member/expert/appointment/list?doneStatus=0"
                       class="btn btn-outline-primary btn-sm">Göster</router-link>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Yıllık Ödeme Bilgisi</h3>
          </div>
          <div class="card-body">
            <div class="text-center">
              <div class="spinner-border" role="status" v-if="!isLoad">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <apexchart
              v-if="isLoad"
              width="100%"
              type="line"
              :options="chartOptions"
              :series="[list.montlyBillingList]"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title">Yıllık Uzman Kaydı Durumu</div>
          </div>
          <div class="card-body overflow-hidden">
            <div class="text-center">
              <div class="spinner-border" role="status" v-if="!isLoad">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <apexchart
              v-if="isLoad"
              width="100%"
              type="line"
              :options="chartOptions"
              :series="[list.montlyExpertList]"
            ></apexchart>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-md-12">
        <div class="card shadow">
          <div class="card-header">
            <h3 class="card-title">Yeni Uzman</h3>
          </div>
          <div class="card-body">
            <div class="text-center">
              <div class="spinner-border" role="status" v-if="!isLoad">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div class="row" v-if="isLoad">
              <div class="col-xl-6">
                <div class="card shadow">
                  <div class="card-body">
                    <div class="widget text-center">
                      <p class="text-muted">Bugün</p>
                      <h2 class="text-xxl text-primary">
                        {{ list.totalExpertToday }}
                      </h2>
                      <p class="mb-0">Uzman</p>
                      <router-link :to="'/member/expert/list?begin='+list.today+'&end='+list.today+'&approved_profile=-1'"
                       class="btn btn-outline-primary btn-sm">Göster</router-link>
                    </div>
                  </div>
                </div>
                <div class="card shadow mb-xl-0">
                  <div class="card-body">
                    <div class="widget text-center">
                      <p class="text-muted">Dün</p>
                      <h2 class="text-xxl text-primary">
                        {{ list.totalExpertYesterDay }}
                      </h2>
                      <p class="mb-0">Uzman</p>
                      <router-link :to="'/member/expert/list?begin='+list.yesterday+'&end='+list.today+'&approved_profile=-1'"
                       class="btn btn-outline-primary btn-sm">Göster</router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="card shadow">
                  <div class="card-body">
                    <div class="widget text-center">
                      <p class="text-muted">Hafta</p>
                      <h2 class="text-xxl text-primary">
                        {{ list.totalExpertWeek }}
                      </h2>
                      <p class="mb-0">Uzman</p>
                      <router-link :to="'/member/expert/list?begin='+list.week+'&end='+list.today+'&approved_profile=-1'"
                       class="btn btn-outline-primary btn-sm">Göster</router-link>
                    </div>
                  </div>
                </div>
                <div class="card shadow mb-0">
                  <div class="card-body">
                    <div class="widget text-center">
                      <p class="text-muted">Ay</p>
                      <h2 class="text-xxl text-primary">
                        {{ list.totalExpertMonth }}
                      </h2>
                      <p class="mb-0">Uzman</p>
                      <router-link :to="'/member/expert/list?begin='+list.month+'&end='+list.today+'&approved_profile=-1'"
                       class="btn btn-outline-primary btn-sm">Göster</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 col-md-12">
        <div class="card shadow">
          <div class="card-header">
            <h3 class="card-title">Yeni Kayıtlar</h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6">
                <div class="card shadow">
                  <div class="card-body">
                    <div class="widget text-center">
                      <p class="text-muted">Bugün</p>
                      <div class="text-center">
                        <div
                          class="spinner-border"
                          role="status"
                          v-if="!isLoad"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                      <h2 class="text-xxl text-primary" v-if="isLoad">
                        {{ list.totalMemberToday }}
                      </h2>
                      <p class="mb-0">Üye</p>
                      <router-link :to="'/member/list?begin='+list.today+'&end='+list.today"
                       class="btn btn-outline-primary btn-sm">Göster</router-link>
                    </div>
                  </div>
                </div>
                <div class="card shadow mb-xl-0">
                  <div class="card-body">
                    <div class="widget text-center">
                      <p class="text-muted">Dün</p>
                      <div class="text-center">
                        <div
                          class="spinner-border"
                          role="status"
                          v-if="!isLoad"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                      <h2 class="text-xxl text-primary" v-if="isLoad">
                        {{ list.totalMemberYesterDay }}
                      </h2>
                      <p class="mb-0">Üye</p>
                      <router-link :to="'/member/expert/list?begin='+list.yesterday+'&end='+list.today"
                       class="btn btn-outline-primary btn-sm">Göster</router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="card shadow">
                  <div class="card-body">
                    <div class="widget text-center">
                      <p class="text-muted">Hafta</p>
                      <div class="text-center">
                        <div
                          class="spinner-border"
                          role="status"
                          v-if="!isLoad"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                      <h2 class="text-xxl text-primary" v-if="isLoad">
                        {{ list.totalMemberWeek }}
                      </h2>
                      <p class="mb-0">Üye</p>
                      <router-link :to="'/member/list?begin='+list.week+'&end='+list.today"
                       class="btn btn-outline-primary btn-sm">Göster</router-link>
                    </div>
                  </div>
                </div>
                <div class="card shadow mb-0">
                  <div class="card-body">
                    <div class="widget text-center">
                      <p class="text-muted">Ay</p>
                      <div class="text-center">
                        <div
                          class="spinner-border"
                          role="status"
                          v-if="!isLoad"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                      <h2 class="text-xxl text-primary" v-if="isLoad">
                        {{ list.totalMemberMonth }}
                      </h2>
                      <p class="mb-0">Üye</p>
                      <router-link :to="'/member/list?begin='+list.month+'&end='+list.today"
                       class="btn btn-outline-primary btn-sm">Göster</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title">Yıllık Üyelik Durumu</div>
          </div>
          <div class="card-body overflow-hidden">
            <div class="text-center">
              <div class="spinner-border" role="status" v-if="!isLoad">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <apexchart
              v-if="isLoad"
              width="100%"
              type="line"
              :options="chartOptions"
              :series="[list.montlyMemberList]"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card table-responsive border-top">
          <table
            class="
              table
              card-table
              table-bordered table-hover table-vcenter
              text-nowrap
            "
          >
            <tbody>
              <tr>
                <th class="w-1">Profil</th>
                <th class="w-1">Kullanıcı Bilgisi</th>
                <th class="w-1">Cari Hesap</th>
                <th>Tutar</th>
                <th>Vergi</th>
                <th>Durum</th>
                <th>Oluşturulma</th>
                <th>İşlem</th>
              </tr>
              <tr v-for="(item, i) in list.waitBilling" :key="i">
                <td>
                  <img
                    :src="
                      'https://storage.terapivitrini.com/' +
                      item.profile_image_url
                    "
                    class="avatar avatar-lg brround"
                  />
                </td>
                <td>
                  {{ item.fullname }}<br /><small>{{ item.email }}</small>
                </td>
                <td>{{ item.account_title }}</td>
                <td>
                  {{ item.price }}
                </td>
                <td>{{ item.tax_price }}</td>
                <td>
                  <template v-if="item.payment_status == 1"
                    ><span class="status-icon bg-success"></span> Ödeme
                    Yapıldı</template
                  >
                  <template v-if="item.payment_status == 2"
                    ><span class="status-icon bg-warning"></span> Ödeme
                    Bekliyor</template
                  >
                  <template v-if="item.payment_status == 3"
                    ><span class="status-icon bg-danger"></span> Fatura İptal
                    Edildi</template
                  >
                </td>
                <td>{{ item.created_at }}</td>
                <td>
                  <div class="btn-list">
                    <button
                      class="btn bg-primary-transparent text-primary"
                      @click="update(item.billing_id)"
                    >
                      Fatura Detayları
                    </button>
                    <button
                      class="btn bg-danger-transparent text-primary"
                      @click="remove(item.id)"
                    >
                      İptal
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dashboard",
  components: {},
  created() {
    document.title = "Terapi Vitrini Dashboard";
    this.$store.state.header = true;
    this.$store.state.bread_crumb_title = "Gösterge Paneli";
    this.$store.state.bread_crumb_items = [];
    this.$store.state.bread_crumb_items.push({
      url: "",
      name: "Gösterge Paneli",
    });
    this.$store.dispatch("dashboardGet").then((value) => {
      this.list = value;
      this.isLoad = true;
    });
  },
  data() {
    return {
      list: [],
      isLoad: false,
      chartOptions: {
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [
            "Ocak",
            "Şubat",
            "Mart",
            "Nisan",
            "Mayıs",
            "Haziran",
            "Temmuz",
            "Ağustos",
            "Eylül",
            "Ekim",
            "Kasım",
            "Aralık",
          ],
        },
      },
    };
  },
  mounted() {},
};
</script>
