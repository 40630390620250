<template>
  <div class="page-content z-index-10">
    <div class="container">
      <div class="row">
        <div class="col-xl-4 col-md-12 col-md-12 d-block mx-auto">
          <div class="card mb-0">
            <div class="card-header">
              <h3 class="card-title">Parolamı Unuttum</h3>
            </div>
            <div class="card-body">
              <div class="form-group">
                <label class="form-label text-dark" for="email">Email</label>
                <input
                  type="email"
                  name="email"
                  class="form-control"
                  id="email"
                  placeholder="Email girin"
                />
              </div>
              <div class="form-footer">
                <button
                  type="button"
                  id="sendButton"
                  @click="send"
                  class="btn btn-primary btn-block"
                >
                  Bağlantıyı Gönder
                </button>
              </div>
              <div class="text-center text-dark mt-3">
                Oturum açma ekranına
                <router-link :to="'login'">geri dön </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ForgotPassword",
  components: {},
  created() {
    document.title = "Parolamı Unuttum";
    this.$store.state.header = false;
  },
  methods: {
    send() {
      let email = document.getElementById("email").value;
      if (email == "") {
        this.$toast("Mail Adresi girilmedi", {
          timeout: 1000,
        });
      } else {
        document.getElementById("sendButton").disabled = true;
        document.getElementById("sendButton").innerHTML =
          "Sıfırlama bağlantısı gönderiliyor..";
        let pageInfo = {
          email: email,
        };
        this.$store.dispatch("passwordReset", pageInfo).then((value) => {
          document.getElementById("sendButton").disabled = true;
          document.getElementById("sendButton").innerHTML = "Bağlantıyı Gönder";
          if (value.type == "success") {
            this.$toast("Sıfırlama Bağlantısı Gönderildi", {
              timeout: 1000,
            });
          }
          if (value.type == "error") {
            this.$toast("Mail Adresi Bulunamadı", {
              timeout: 1000,
            });
          }
        });
      }
    },
  },
};
</script>
