<template>
  <div class="row" style="width: 100%; margin: 0 auto">
    <div class="panel panel-primary">
      <div class="tab_wrapper first_tab">
        <ul class="tab_list">
          <li class="">Dosya Yükle</li>
          <li>Dosyalar</li>
        </ul>
        <div class="content_wrapper">
          <div class="tab_content active">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title">Dosya Yükle</h3>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-12 col-sm-12">
                      <input
                        type="file"
                        @change="fileChange"
                        class="dropify"
                        name="file"
                        data-height="180"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title">Dosya Bilgileri</h3>
                </div>
                <div class="card-body">
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <div class="form-group">
                        <label
                          @click="copyItem"
                          class="form-label"
                          for="exampleInputEmail1"
                          >{{ link }}</label
                        >
                      </div>
                    </div>
                    <div class="form-group col-md-12">
                      <div class="form-group">
                        <label class="form-label" for="exampleInputEmail1"
                          >Dosya Adı</label
                        >
                        <input
                          type="text"
                          :value="name"
                          class="form-control"
                          disabled
                          id="test"
                          name="test"
                          placeholder="Dosya Adı"
                          autocomplete="off"
                        />
                      </div>
                    </div>
                    <div class="form-group col-md-12">
                      <div class="form-group">
                        <label class="form-label" for="exampleInputEmail1"
                          >Türü</label
                        >
                        <input
                          type="text"
                          :value="type"
                          class="form-control"
                          disabled
                          id="test"
                          name="test"
                          placeholder="Dosya Türü"
                          autocomplete="off"
                        />
                      </div>
                    </div>
                    <div class="form-group col-md-12">
                      <div class="form-group">
                        <label class="form-label" for="exampleInputEmail1"
                          >Boyut</label
                        >
                        <input
                          type="text"
                          :value="(size / 1024 / 1024).toFixed(2)"
                          disabled
                          class="form-control"
                          id="test"
                          name="test"
                          placeholder="Boyut"
                          autocomplete="off"
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    class="btn btn-primary"
                    id="saveButton"
                    @click="fileUpload"
                  >
                    Yükle
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="tab_content">
            <div class="side-app">
              <div class="row">
                <div class="col-9">
                  <div class="row">
                    <div v-for="(item, i) in list" class="col-2" :key="i">
                      <div class="card" @click="openImageInfos(item, i)">
                        <div :class="selectedImageIndex == i ? ' select' : ''">
                          <div class="image gallery gallery-first">
                            <img
                              :src="
                                'https://storage.terapivitrini.com/' + item.url
                              "
                              width="100"
                              alt="image"
                            />
                            <div class="mask"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-3" style="position: fixed; right: 0">
                  <h4>Resim Bilgileri</h4>
                  <div class="form-group">
                    <label class="form-label" for="description"
                      >Dosya Adı</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="filename"
                      v-model="selectedImage.title"
                      name="filename"
                      placeholder="Dosya Adı"
                    />
                  </div>
                  <div class="form-group">
                    <label class="form-label" for="description"
                      >Dosya Açıklaması</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="filename"
                      v-model="selectedImage.description"
                      name="filename"
                      placeholder="Dosya Açıklaması"
                    />
                  </div>
                  <div class="form-group">
                    <label class="form-label" for="description"
                      >Dosya Açıklaması</label
                    >
                    <input
                      type="button"
                      class="btn btn-primary w-100"
                      @click="saveImageInfo"
                      value="Kaydet ve İçe Aktar"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.select {
  background-color: green !important;
}
</style>
<script>
export default {
  name: "FileUpload",
  components: {},
  created() {
    this.getFileList();
  },
  data() {
    return {
      name: "",
      type: "",
      size: "",
      link: "",
      list: [],
      currentpage: 1,
      perpage: 50,
      totalsize: 0,
      pagesize: 0,
      selectedImage: [],
      selectedImageIndex: -1,
    };
  },
  methods: {
    saveImageInfo() {
      this.$store
        .dispatch("mediaInfoSave", this.selectedImage)
        .then((value) => {
          if (value.type == "success") {
            $('#content').summernote('insertImage', 'https://storage.terapivitrini.com/' +this.selectedImage.url, this.selectedImage.title);
            this.$parent.close();
          }
        });
    },
    openImageInfos(item, i) {
      this.selectedImage = item;
      this.selectedImageIndex = i;
    },
    getFileList() {
      let mediaInfo = {
        begin: (this.currentpage - 1) * this.perpage,
        perpage: this.perpage,
      };
      this.$store.dispatch("mediaList", mediaInfo).then((value) => {
        this.list = value.list;
        this.totalsize = value.size;
        this.pagesize = value.pages;
      });
    },
    copyItem() {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(this.link);
        return;
      }
      let a = this;
      navigator.clipboard.writeText(this.link).then(
        function () {
          a.$toast("Link kopyalandı", {
            timeout: 1000,
          });
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
    fileChange() {
      this.name = document.querySelector("input[name=file]").files[0].name;
      this.size = document.querySelector("input[name=file]").files[0].size;
      this.type = document.querySelector("input[name=file]").files[0].type;
    },
    fileUpload() {
      document.getElementById("saveButton").innerText = "Dosya Yükleniyor...";
      document.getElementById("saveButton").disabled = true;
      let file = document.querySelector("input[name=file]").files[0];
      let formData = new FormData();
      formData.append("file", file);
      formData.append("name", this.name);
      formData.append("size", this.size);
      formData.append("type", this.type);
      this.$store.dispatch("mediaUpload", formData).then((response) => {
        this.link = response.link;
        document.getElementById("saveButton").innerText = "Yükle";
        document.getElementById("saveButton").disabled = false;
        this.getFileList();
      });
    },
  },
  mounted() {
    $(function (e) {
      $(".first_tab").champ();
      $(".accordion_example").champ({
        plugin_type: "accordion",
        side: "left",
        active_tab: "3",
        controllers: "true",
      });

      $(".second_tab").champ({
        plugin_type: "tab",
        side: "right",
        active_tab: "1",
        controllers: "false",
      });
    });
    $(function () {
      "use strict";
      $(".dropify").dropify({
        messages: {
          default: "Dosyayı sürükle bırak yada seç",
          replace: "Değişiklik için sürükle bırak yada seç",
          remove: "İptal",
          error: "Ooops, bişeyler yanlış.",
        },
        error: {
          fileSize: "Dosya çok büyük (2M max).",
        },
      });
    });
  },
};
</script>
