<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card m-b-20">
        <div class="card-header">
          <h3 class="card-title">Eğitim İnceleme</h3>
        </div>
        <div class="card-body">
          <div
            class="alert alert-warning w-100"
            role="alert"
            v-if="data.status == 0"
          >
            Eğitim için henüz işlem yapılmadı
          </div>
          <div
            class="alert alert-warning w-100"
            role="alert"
            v-if="data.status == 2"
          >
            Eğitim Onay Bekliyor
          </div>
          <div
            class="alert alert-danger w-100"
            role="alert"
            v-if="data.status == 3"
          >
            Eğitim Reddedildi
          </div>
          <div
            class="alert alert-success w-100"
            role="alert"
            v-if="data.status == 1"
          >
            Eğitim Onaylandı
          </div>
          <hr />
          <div class="form-label">
            <h3>İşlem Gerçekleştir</h3>
          </div>

          <div class="row">
            <div class="col-12">
              <li
                v-for="(item, i) in data.decline_list"
                :key="'decline-' + i"
                class="text-danger"
              >
                Red Sebebi:{{ item.content }} Tarihi:{{ item.created_at }}
              </li>
            </div>
            <div class="col-12">
              <textarea
                class="form-control"
                placeholder="Açıklama Girin"
                name="description"
              ></textarea>
              <div class="input-group">
                <button
                  class="btn btn-primary w-100 mt-3 mb-3 col-6"
                  @click="process(1)"
                  id="saveButton"
                >
                  Onayla
                </button>
                <button
                  class="btn btn-danger w-100 mt-3 mb-3 col-6"
                  @click="process(3)"
                  id="saveButton"
                >
                  Reddet
                </button>
              </div>
            </div>
            <hr />
            <div class="form-label">
              <h3>Uzman Bilgileri</h3>
            </div>
            <hr />
            <template v-if="data.user != null">
              <div class="col-4">
                <div class="form-label">Profil Resmi:</div>
                <img
                  v-if="data.user.profile_image_url != null"
                  width="100"
                  :src="
                    'https://storage.terapivitrini.com/' +
                    data.user.profile_image_url
                  "
                />
              </div>
              <div class="col-4">
                <div class="form-label">Uzman Adı Soyadı:</div>
                {{ data.user.fullname }}
              </div>

              <div class="col-4">
                <div class="form-label">Üyelik Türü:</div>
                {{
                  data.user.selected_billing
                    ? data.selected_billing[0].title
                    : ""
                }}
              </div>
              <div class="col-4">
                <div class="form-label">Uzmanlık:</div>
                {{ data.user.expert }}
              </div>
              <div class="col-4">
                <div class="form-label">Yorum:</div>
                {{ data.user.total_comment }}
              </div>
              <div class="col-4">
                <div class="form-label">Puan:</div>
                {{ Math.ceil(data.user.total_score / 5) }}
              </div>

              <hr />
              <div class="col-12">
                <div class="form-label">Hakkında:</div>
                {{ data.user.about }}
              </div>
            </template>
            <hr />
            <div class="form-label">
              <h3>Eğitim Bilgileri</h3>
            </div>
            <hr />
            <div class="col-4">
              <div class="form-label">Eğitim Başlığı:</div>
              <input type="text" class="form-control" v-model="data.title" />
            </div>
            
            <div class="col-4">
              <div class="form-label">Kategorisi:</div>
              <template v-if="data.category_name != null">
                <select class="form-control" v-model="data.category">
                  <template v-for="(item, i) in serviceList">
                    <option :key="i" :value="item.id">
                      {{ item.experience_name }}
                    </option>
                  </template>
                </select>
              </template>
              <template v-else> Kategori Seçilmedi </template>
            </div>
            <div class="col-4">
              <div class="form-label">Süre:</div>
              <input
                type="number"
                class="form-control"
                v-model="data.duration"
              />
            </div>
            
            <div class="col-12">
              <div class="form-label">Kısa Açıklama:</div>
              <textarea type="text" class="form-control" v-model="data.short_description"></textarea>
            </div>
            <div class="col-4">
              <div class="form-label">Başlangıç:</div>
              <input
                type="date"
                class="form-control"
                v-model="data.begin_date"
              />
            </div>
            <div class="col-4">
              <div class="form-label">Normal Fiyat:</div>
              <input
                type="number"
                class="form-control"
                v-model="data.price"
              />
            </div>
            <div class="col-4">
              <div class="form-label">İndirimli Fiyat:</div>
              <input
                type="number"
                class="form-control"
                v-model="data.dumping_price"
              />
            </div>
            <div class="col-4">
              <div class="form-label">Bitiş:</div>
              <input type="date" class="form-control" v-model="data.end_date" />
            </div>
            <div class="col-4">
              <div class="form-label">Türü:</div>
              <select class="form-control" :v-model="data.type">
                <option value="1">YÜZ YÜZE</option>
                <option value="2">ONLİNE</option>
                <option value="3">VİDEO</option>
              </select>
            </div>
            <div class="col-4">
              <div class="form-label">Katılımcı Sayısı:</div>
              <input
                type="number"
                class="form-control"
                v-model="data.participant_size"
              />
            </div>
            <div class="col-4">
              <div class="form-label">Şehir:</div>
              <input
                type="text"
                class="form-control"
                v-model="data.city"
              />
            </div>
            <div class="col-4">
              <div class="form-label">İlçe:</div>
              <input
                type="text"
                class="form-control"
                v-model="data.district"
              />
            </div>
            <div class="col-4">
              <div class="form-label">Sertifika:</div>
              <input
                type="checkbox"
                v-model="data.participant_size"
              />
            </div>
            <hr class="mt-5" />
            <template v-if="data.images != null">
              <div class="form-label">Eğitim Resimleri:</div>
              <div class="col-3" v-for="(i, a) in data.images" :key="a">
                <div class="form-label">Resim {{ a + 1 }}:</div>
                <img
                  width="100"
                  :src="'https://storage.terapivitrini.com/' + i.path"
                /></div
            ></template>
            <hr />
            <div class="col-12">
              <div class="form-label">Eğitim Açıklaması:</div>
              <p id="content">{{ data.content }}</p>
            </div>
            <div class="col-12 mt-3">
              <button class="btn btn-primary" id="saveButton" @click="saveChange">
                Düzenlemeleri Kaydet
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "UserUpdate",
  components: {},
  created() {
    document.title = "Eğitim İnceleme";
    this.$store.state.header = true;
    this.$store.dispatch("serviceList", {}).then((value) => {
      this.serviceList = value.list;
    });
    this.get();
  },
  data() {
    return {
      data: [],
      serviceList: [],
      generatePassword: "",
    };
  },
  mounted() {
    $(document).ready(function () {
      $("#content").summernote({
        toolbar: [
          [
            "style",
            ["highlight", "bold", "italic", "underline", "clear", "style"],
          ],
          ["font", ["strikethrough", "superscript", "subscript", "fontname"]],
          ["color", ["color"]],
          ["table", ["table"]],
          ["para", ["ul", "ol", "paragraph", "hr"]],
          ["insert", ["tableofcontent"]],
          ["link", ["linkDialogShow", "unlink"]],
          ["view", ["fullscreen", "codeview", "help"]],
        ],
      });
    });
  },
  methods: {
    saveChange() {
      Swal.fire({
        title: "Değişiklikleri kaydetmek istiyor musun?",
        text: "Eğitimde yaptığın değişiklikler kaydedilecek, işleme devam etmek istediğine emin misiniz?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Onayla",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          /**KAYIT İŞLEMİNE BAŞLA*/

          let description = $("#content").summernote("code");

          if (this.data.title == "") {
            this.$toast("Eğitim başlığı boş olamaz", {
              timeout: 1000,
            });

          }else if (description == "") {
            this.$toast("Eğitim açıklaması boş olamaz", {
              timeout: 1000,
            });
          } else {
            this.data.content=description
            let educationInfo = this.data;
            document.getElementById("saveButton").disabled = true;
            document.getElementById("saveButton").innerHTML ="Eğitim Güncelleniyor...";
            this.$store.dispatch("educationUpdate", educationInfo).then((value) => {
              document.getElementById("saveButton").disabled = false;
              document.getElementById("saveButton").innerHTML = "Düzenlemeleri Kaydet";
              this.$toast("Eğitim güncelleme başarılı", {
                timeout: 2000,
              });
            });
          }
        }
      });
    },
    get() {
      this.$store
        .dispatch("memberEducationGet", { id: this.$route.params.id })
        .then((value) => {
          if (value.length == 0) {
            this.$router.push("/member/education/list");
          } else {
            this.data = value;
            $("#content").summernote("code", this.data.content);
            this.$store.state.bread_crumb_title = "Eğitim İnceleme";
            this.$store.state.bread_crumb_items = [];
            this.$store.state.bread_crumb_items.push({
              url: "/dashboard",
              name: "Gösterge Paneli",
            });
            this.$store.state.bread_crumb_items.push({
              url: "/member/education/list",
              name: "Eğitim Listesi",
            });
            this.$store.state.bread_crumb_items.push({
              url: "",
            });
          }
        });
    },
    process(type) {
      /** MEMBER SAVE */

      let description = document.querySelector(
        "textarea[name=description]"
      ).value;
      if (description == "" && type == 3) {
        this.$toast("Lütfen reddedilme açıklaması girin", {
          timeout: 1000,
        });
      } else {
        if (type == 1) {
          Swal.fire({
            title: "Eğitim onaylanacak",
            text: "Onay işlemini gerçekleştirmek istediğinize emin misiniz?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Onayla",
            cancelButtonText: "İptal",
          }).then((result) => {
            if (result.isConfirmed) {
              /**ONAYLMA İŞLEMİ BAŞLAT */
              let userInfo = {
                type: type,
                description: "",
                id: this.$route.params.id,
              };
              this.$store
                .dispatch("memberExpertEducationApproved", userInfo)
                .then((value) => {
                  if (value.type == "success") {
                    this.$toast("İşlem Başarılı", {
                      timeout: 2000,
                    });
                    this.get();
                  } else if (value.type == "error") {
                    /**HATALI İŞLEM VARSA */
                  }
                });
            }
          });
        } else if (type == 3) {
          Swal.fire({
            title: "Eğitim reddedilecek",
            text: "Eğitim reddetme işlemini tamamlamak istediğinize emin misiniz?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Onayla",
            cancelButtonText: "İptal",
          }).then((result) => {
            if (result.isConfirmed) {
              /**İPTAL İŞLEMİ BAŞLAT */
              let userInfo = {
                type: type,
                description: description,
                id: this.$route.params.id,
              };
              this.$store
                .dispatch("memberExpertEducationApproved", userInfo)
                .then((value) => {
                  if (value.type == "success") {
                    this.$toast("İşlem Başarılı", {
                      timeout: 2000,
                    });
                    this.get();
                    document.querySelector("textarea[name=description]").value =
                      "";
                  } else if (value.type == "error") {
                    /**HATALI İŞLEM VARSA */
                  }
                });
            }
          });
        }
      }
    },
  },
};
</script>