export default {
    fullname(state,name){
        state.fullname=name
    },
    profile_image(state,image){
        state.profile_image=image
    },
    auth(state,auth){
        auth.forEach(element => {
            state.auth[element.auth_id]=element
        });
    }
}