<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="panel panel-primary">
        <div>
          <div class="user-tabs mb-4">
            <!-- Tabs -->
            <ul class="nav panel-tabs">
              <li class="">
                <button
                  @click="newItem"
                  data-bs-toggle="tab"
                  class="btn bg-primary-transparent text-primary"
                >
                  <i class="fa fa-plus"></i> Yeni Başlık
                </button>
              </li>
              <li class="">
                <button
                  class="btn btn-primary"
                  id="saveButton"
                  @click="saveData"
                >
                  Kaydet
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="tab-content">
            <div class="tab-pane active" id="tab1">
              <div class="row row-deck">
                <div
                  class="col-md-12"
                  v-for="(item, i) in list"
                  :key="'composition_' + i"
                >
                  <div class="card">
                    <div class="card-header">
                      <div class="card-title w-50">
                        <label>Üst Başlık:</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="item.title"
                        />
                      </div>
                      <div class="card-options">
                        <ul class="unstyled inbox-pagination">
                          <li>
                            <button
                              @click="newSubItem(i)"
                              class="btn bg-primary-transparent text-primary"
                            >
                              <i class="fa fa-plus"></i> Yeni Alt Başlık
                            </button>
                          </li>
                          <li>
                            <a
                              href="#"
                              class="card-options-collapse mt-1"
                              data-bs-toggle="card-collapse"
                              ><i class="fe fe-chevron-up"></i
                            ></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="card-body">
                      <button
                        class="
                          btn
                          bg-primary-transparent
                          text-primary
                          mt-2
                          mb-2
                          w-100
                        "
                        @click="remove(i)"
                      >
                        Sil
                      </button>
                      <!-- Accordion begin -->
                      <ul
                        class="demo-accordion accordionjs m-0"
                        data-active-index="false"
                        v-for="(a, b) in item.list"
                        :key="'sub_title_list' + b"
                      >
                        <!-- Section 1 -->
                        <li>
                          <div>
                            <h3>{{ a.title }}</h3>
                          </div>
                          <div>
                            <button
                              class="
                                btn
                                bg-primary-transparent
                                text-primary
                                mt-2
                                w-100
                              "
                              @click="removeSub(i, b)"
                            >
                              Sil
                            </button>

                            <label>Başlık:</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="a.title"
                            />
                            <label>İçerik:</label>
                            <textarea class="form-control" v-model="a.content">
                            </textarea>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "CategoryList",
  components: {},
  created() {
    document.title = "S.S.S. Listesi";
    this.$store.state.header = true;
    this.$store.state.bread_crumb_title = "S.S.S. Listesi";
    this.$store.state.bread_crumb_items = [];
    this.$store.state.bread_crumb_items.push({
      url: "/dashboard",
      name: "Gösterge Paneli",
    });
    this.$store.state.bread_crumb_items.push({
      url: "",
      name: "S.S.S. Listesi",
    });
    this.getList();
  },
  data() {
    return {
      list: [],
      currentpage: 1,
      perpage: 50,
      totalsize: 0,
      pagesize: 0,
    };
  },
  mounted: function () {
    $(function (e) {
      $(".demo-accordion").accordionjs();
    });
  },
  methods: {
    newItem() {
      this.list.push({ title: "newItem", list: [] });
      $(function (e) {
        $(".demo-accordion").accordionjs();
      });
    },
    newSubItem(i) {
      this.list[i].list.push({ title: "newItem", content: "newContent" });
      $(function (e) {
        $(".demo-accordion").accordionjs();
      });
    },
    saveData() {
      document.getElementById("saveButton").innerText = "Veriler Yükleniyor...";
      document.getElementById("saveButton").disabled = true;

      this.$store
        .dispatch("faqSave", { data: JSON.stringify(this.list) })
        .then(() => {
          document.getElementById("saveButton").innerText = "Kaydet";
          document.getElementById("saveButton").disabled = false;
        });
    },
    getList() {
      this.$store.dispatch("faqList").then((value) => {
        this.list = value.list;
        $(function (e) {
          $(".demo-accordion").accordionjs();
        });
      });
    },
    remove(id) {
      Swal.fire({
        title: "Soru silinecek?",
        text: "Bu işlem geri dönüşü olmayan sonuçlar ortaya çıkarabilir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sil",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          /**Listeden sil */
          this.list.splice(id, 1);
        }
      });
    },
    removeSub(topId, id) {
      Swal.fire({
        title: "Soru silinecek?",
        text: "Bu işlem geri dönüşü olmayan sonuçlar ortaya çıkarabilir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sil",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          /**Listeden sil */
          this.list[topId].list.splice(id, 1);
        }
      });
    },
  },
};
</script>
