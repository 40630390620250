<template>

    <div class="page-content z-index-10">
        <div class="container text-center text-dark">
            <div class="display-1 error-display   mb-5"> 503</div>
            <h1 class="h2  mb-3">Page Not Found</h1>
            <p class="h4 font-weight-normal mb-7 leading-normal">Oops!!!! you tried to access a page which is not
                available. go back to Home</p>
            <a class="btn btn-secondary" href="index.html">
                Back To Home
            </a>
        </div>
    </div>

</template>

<script>
export default {
    name: '503',
    components: {
    },
    created() {
        document.title = "503 Error"
        this.$store.state.header = false;
    }
}
</script>
