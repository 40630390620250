import { render, staticRenderFns } from "./MemberEducationList.vue?vue&type=template&id=aa0acbfa"
import script from "./MemberEducationList.vue?vue&type=script&lang=js"
export * from "./MemberEducationList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports