<template>
  <div class="col-12">
    <div class="mb-0" id="profile_sidebar">
      <div class="card-header">
        <h3 class="card-title">Randevu Takvimi</h3>
      </div>
      <div class="left text-primary p-0" @click="left">
        <i class="fa fa-angle-left"></i>
      </div>
      <div class="right text-primary p-0" @click="right">
        <i class="fa fa-angle-right"></i>
      </div>

      <div
        class="card-body"
        id="test"
        :style="
          'transition: all 1s ease 0s;overflow: hidden;height:' +
          height / bol +
          'px;'
        "
      >
        <div class="dateList box" id="box">
          <div v-for="(item, i) in list.dates" :key="i">
            <div class="topmenu">
              <span>{{ item }}</span
              ><br />
              <small>{{ list.list[i].date_link }}</small>
            </div>
            <template v-if="list.list[i].list.length == 0">
              <li style="background: #ccc">-</li>
            </template>
            <template v-for="(time, a) in list.list[i].list">
              <template v-if="time.available == 0">
                <li style="background: #ccc" :key="'hour' + a">
                  <del style="background: #ccc">{{
                    time.begin
                  }}</del>
                </li></template
              >
              <template v-else>
                <li
                  @click="
                    responseData(
                      list.list[i].date_link +
                        ' ' +
                        time.begin,
                        time.begin,
                      time.id
                    )
                  "
                  style="background: #bb7494; cursor: pointer"
                  :id="'select' + list.list[i].date_link + '-' + time.begin"
                  :class="'sList '"
                  :key="'hour' + a"
                >
                  {{ time.begin }}
                </li></template
              >
            </template>
          </div>
        </div>
      </div>
      <div @click="loadmore" class="loadmore">
        <i v-if="calenderToggleBool" class="fa fa-angle-down"></i>
        <i v-if="!calenderToggleBool" class="fa fa-angle-up"></i>
      </div>
    </div>
  </div>
</template>
  <style scoped>
.topmenu {
  padding: 0 !important;
  background: #eaeaea;
  margin-bottom: 10px;
  margin-left: -10px;
  margin-top: -10px;
  height: 52px;
}
.loadmore {
  text-align: center;
  font-size: 20px;
  box-shadow: 0px -16px 15px -14px #000000;
  position: inherit;
}
.loadmore:hover {
  cursor: pointer;
}
.selected {
  background: #923b62 !important;
  color: white;
}
.left {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  left: -35px;
  top: 60px;
  z-index: 999;
  padding: 11px !important;
  background: rgb(219, 219, 219);
  background: linear-gradient(
    270deg,
    rgb(255 255 255) 0%,
    rgb(241 241 241) 100%
  );

  box-shadow: -10px 0px 10px -5px #cfcfcf;
}
.right {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  right: -35px;
  top: 60px;
  z-index: 999;
  padding: 11px !important;
  background: rgb(219, 219, 219);
  background: linear-gradient(
    90deg,
    rgb(255 255 255) 0%,
    rgb(241 241 241) 100%
  );
  box-shadow: 10px 0px 10px -5px #cfcfcf;
}
.dateList {
  display: inline-flex;
  position: absolute;
  left: 0;
  top: 0;
}
.dateList div {
  width: 200px;
  text-align: center;
  padding: 10px;
}
.dateList li {
  padding: 5px;
  margin: 5px;
  font-size: 20px;
  color: white;
  border-radius: 10px;
  list-style: none;
}
.dateList li a {
  color: white;
  cursor: pointer;
}
.dateList li a:hover {
  color: white !important;
  cursor: pointer;
}
</style>
  <script>
export default {
  props: ["userid", "selectedDate"],
  created() {
    let appointmentsGet = {
      user_id: this.userid,
    };
    this.$store.dispatch("getAppointments", appointmentsGet).then((value) => {
      this.list = value;
      setTimeout(() => {
        this.height = document.getElementById("box").offsetHeight + 10;
        if (this.selectedDate) {
          document
            .getElementById(
              "select" +
                this.selectedDate.split(" ")[0] +
                "-"+this.selectedDate.split(" ")[1]
            )
            .classList.add("selected");
        }
      }, 50);
    });
  },
  watch: {
    userid: function () {
      let appointmentsGet = {
        user_id: this.userid,
      };
      this.$store.dispatch("getAppointments", appointmentsGet).then((value) => {
        this.list = value;
        setTimeout(() => {
          this.height = document.getElementById("box").offsetHeight + 10;
        }, 50);
      });
    },
  },
  data() {
    return {
      height: 0,
      bol: 2,
      list: [],
      calenderToggleBool: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.height = document.getElementById("box").offsetHeight + 10;
    }, 500);
  },
  methods: {
    responseData(date, i, id) {
      $(".sList").removeClass("selected");
      document
        .getElementById("select" + date.split(" ")[0] + "-" + i)
        .classList.add("selected");
      this.$emit("response", [date, id]);
    },
    left() {
      document.getElementById("test").scrollBy({
        top: 0,
        left: -200,
        behavior: "smooth",
      });
    },
    right() {
      document.getElementById("test").scrollBy({
        top: 0,
        left: +200,
        behavior: "smooth",
      });
    },
    loadmore() {
      if (this.bol == 1) {
        this.calenderToggleBool = true;
        this.bol = 2;
      } else {
        this.calenderToggleBool = false;
        this.bol = 1;
      }
    },
  },
};
</script>