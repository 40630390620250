<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <ul class="unstyled inbox-pagination mb-5">
            <li>
              <router-link
                to="/mobile/slider/survey/create"
                class="btn bg-primary-transparent text-primary"
              >
                <i class="fa fa-plus"></i> Yeni Test Slider Ekle</router-link
              >
            </li>
          </ul>
          <div class="table-responsive border-top">
            <table
              class="table card-table table-bordered table-hover table-vcenter text-nowrap"
            >
              <tbody>
                <tr>
                  <th>Resim</th>
                  <th>Yönlendirme</th>
                  <th>Yönlendirme Bağlantısı</th>
                  <th>Durum</th>
                  <th>İşlem</th>
                </tr>
                <tr v-for="(item, i) in list" :key="i">
                  <td><img :src="item.path" width="200"></td>
                  <td>{{ item.button_type }}</td>
                  <td>{{ item.button_type_id }}</td>
                  <td>{{ item.is_show==1?'Aktif':'Pasif' }}</td>
                  <td>
                    <div class="btn-list">
                      <button
                        class="btn bg-primary-transparent text-primary"
                        @click="update(item.id)"
                      >
                        Güncelle
                      </button>
                      <button
                        class="btn bg-danger-transparent text-primary"
                        @click="remove(item.id)"
                      >
                        Sil
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <ul class="unstyled inbox-pagination">
              <li>
                <span
                  >{{ (currentpage - 1) * perpage }}-{{
                    (currentpage - 1) * perpage + perpage
                  }}
                  arasında, Toplam {{ totalsize }} kayıt</span
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ul class="pagination mb-5">
        <li
          :class="'page-item page-prev ' + (currentpage == 1 ? 'disabled' : '')"
        >
          <a class="page-link" href="#" tabindex="-1">Önceki</a>
        </li>
        <li
          v-for="(s, i) in pagesize"
          :key="i"
          :class="'page-item ' + (i + 1 == currentpage ? 'active' : '')"
        >
          <router-link :to="'/service/list/' + (i + 1)" class="page-link">{{
            i + 1
          }}</router-link>
        </li>
        <li
          :class="
            'page-item page-next ' +
            (currentpage == pagesize || currentpage == 1 ? 'disabled' : '')
          "
        >
          <a class="page-link" href="#">Sonraki</a>
        </li>
      </ul>
    </div>
  </div>
</template>
  
  <script>
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "MobileSurveySliderList",
  components: {},
  created() {
    document.title = "Mobil Slider Listesi";
    this.$store.state.header = true;
    this.$store.state.bread_crumb_title = "Mobil Slider Listesi";
    this.$store.state.bread_crumb_items = [];
    this.$store.state.bread_crumb_items.push({
      url: "/dashboard",
      name: "Gösterge Paneli",
    });
    this.$store.state.bread_crumb_items.push({
      url: "",
      name: "Mobil Slider Listesi",
    });
    if (this.$route.params.page && this.$route.params.page != null) {
      this.currentpage = this.$route.params.page;
    }
    this.getMobileSliderList();
  },
  data() {
    return {
      list: [],
      currentpage: 1,
      perpage: 25,
      totalsize: 0,
      pagesize: 0,
      search: "",
    };
  },
  methods: {
    getMobileSliderList() {
      let mobile = {
        begin: (this.currentpage - 1) * this.perpage,
        perpage: this.perpage,
        type:'survey'
      };
      this.$store.dispatch("mobileSliderList", mobile).then((value) => {
        this.list = value.result;
        this.totalsize = value.size;
        this.pagesize = value.pages;
      });
    },
    update(id) {
      this.$router.push("/mobile/slider/survey/update/" + id);
    },
    remove(id) {
      Swal.fire({
        title: "Slider silinecek?",
        text: "Bu işlem geri dönüşü olmayan sonuçlar ortaya çıkarabilir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sil",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          let blogInfo = {
            id: id,
          };
          this.$store.dispatch("mobileSliderRemove", blogInfo).then((value) => {
            Swal.fire("Silindi!", "Hizmet silme işlemi tamamlandı", "success");
            this.getMobileSliderList();
          });
        }
      });
    },
  },
  watch: {
    "$route.params.page": function () {
      if (this.$route.params.page && this.$route.params.page != null) {
        this.currentpage = this.$route.params.page;
      }
      this.getMobileSliderList();
    },
  },
};
</script>
  