<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card m-b-20">
                <div class="card-header">
                    <h3 class="card-title">Yeni Kullanıcı Ekle</h3>
                </div>
                <div class="card-body">
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="fullname">Tam Adı</label>
                                <input type="text" class="form-control" id="fullname" :value="data.fullname"
                                    name="fullName" placeholder="Tam Adı" autocomplete="off">
                            </div>
                        </div>
                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="email">Email</label>
                                <input type="email" class="form-control" id="email" :value="data.email" name="email"
                                    placeholder="Email" autocomplete="off">
                            </div>
                        </div>
                        <div class="col-12">
                            <span>
                                <h4 v-if="generatePassword">Türetilen Parola:</h4> <a style="cursor:pointer"
                                    title="Kopyala" @click="copyPassword">
                                    <h3>{{ generatePassword }}</h3>
                                </a>
                            </span>
                        </div>
                        <div class="col-12">
                            <small v-if="generatePassword">Lütfen parolayı kopyalamayı unutmayın! Parola kopyalandıktan
                                sonra textler otomatik değişecektir.</small>
                        </div>
                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="password">Parola</label>
                                <input type="password" class="form-control" id="password" value="" name="password"
                                    placeholder="Parola" autocomplete="off">
                            </div>
                        </div>
                        <div class="form-group col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="re-password">Parola Yeniden</label>
                                <input type="password" class="form-control" id="re-password" value="" name="re-password"
                                    placeholder="Parola Yeniden" autocomplete="off">
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-primary" @click="passwordGenerator">Parola Türet</button>

                    <hr />
                    <div class="form-label">
                        <h3>Kullanıcı Yetkileri</h3>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-4" v-for="auth,i in data.auths" :key="i">
                            <div class="form-group">
                                <div class="form-label">{{ auth.auth_name }}</div>
                                <label class="form-switch">
                                    <input type="checkbox" name="form-switch-checkbox" v-model="auth.status" :checked="auth.status==1?true:false" class="form-switch-input">
                                    <span class="form-switch-indicator"></span>
                                    <span class="form-switch-description">{{ auth.auth_name }}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-primary" @click="save" id="saveButton">Kaydet</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
export default {
    name: 'UserUpdate',
    components: {
    },
    created() {
        document.title = "Kullanıcı Güncelle"
        this.$store.state.header = true;
        this.$store.dispatch("userGet", { id: this.$route.params.id }).then((value) => {
            if (value.length == 0) {
                this.$router.push("/user/list");
            } else {
                this.data = value[0];
                this.$store.state.bread_crumb_title = "Kullanıcı Güncelle"
                this.$store.state.bread_crumb_items = []
                this.$store.state.bread_crumb_items.push({ url: '/dashboard', name: "Gösterge Paneli" });
                this.$store.state.bread_crumb_items.push({ url: '/user/list', name: "Kullanıcı Listesi" });
                this.$store.state.bread_crumb_items.push({ url: '', name: value[0].email });
            }
        })
    },
    data() {
        return {
            data: [],
            generatePassword: ""
        }
    },
    methods: {
        save() {
            /** USER SAVE */
            document.getElementById('saveButton').disabled = true
            document.getElementById('saveButton').innerHTML = 'Kaydediliyor...'
            let fullName = document.querySelector("input[name=fullName]").value;
            let email = document.querySelector("input[name=email]").value;
            let password = document.querySelector("input[name=password]").value;
            let rePassword = document.querySelector("input[name=re-password]").value;
            if (email == "") {
                this.$toast("Email boş olamaz!", {
                    timeout: 1000
                });
                document.getElementById('saveButton').disabled = false
                document.getElementById('saveButton').innerHTML = 'Kaydet'
            }
            else if (password != "" && password == "") {
                this.$toast("Parola tekrarı boş olamaz!", {
                    timeout: 1000
                });
                document.getElementById('saveButton').disabled = false
                document.getElementById('saveButton').innerHTML = 'Kaydet'
            }
            else if (password != rePassword) {
                this.$toast("Parolalar uyuşmuyor!", {
                    timeout: 1000
                });
                document.getElementById('saveButton').disabled = false
                document.getElementById('saveButton').innerHTML = 'Kaydet'
            }
            else {
                let userInfo = {
                    fullname: fullName,
                    email: email,
                    password: password,
                    id:this.$route.params.id,
                    auths:JSON.stringify(this.data.auths)
                }
                this.$store.dispatch("userUpdate", userInfo).then((value) => {
                    setTimeout(() => {
                        document.getElementById('saveButton').disabled = false
                        document.getElementById('saveButton').innerHTML = 'Kaydet'
                    }, 2000);
                    this.$toast("Kullanıcı güncelleme başarılı", {
                        timeout: 2000
                    });
                })
            }
        },
        remove() {
            Swal.fire({
                title: 'Kullanıcı silinecek?',
                text: "Bu işlem geri dönüşü olmayan sonuçlar ortaya çıkarabilir!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sil',
                cancelButtonText: 'İptal'
            }).then((result) => {
                if (result.isConfirmed) {
                    let pageInfo = {
                        id: this.$route.params.id
                    }
                    this.$store.dispatch("userRemove", pageInfo).then((value) => {
                        Swal.fire(
                            'Silindi!',
                            'Kullanıcı silme işlemi tamamlandı',
                            'success'
                        )
                        setTimeout(() => {
                            this.$router.push("/user/list");
                        }, 2000);
                    })

                }
            })

        },
        passwordGenerator() {
            var length = 10,
                charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
                retVal = "";
            for (var i = 0, n = charset.length; i < length; ++i) {
                retVal += charset.charAt(Math.floor(Math.random() * n));
            }
            this.generatePassword = retVal;
        },
        copyPassword() {
            if (!navigator.clipboard) {
                fallbackCopyTextToClipboard(this.generatePassword);
                return;
            }
            let a = this
            navigator.clipboard.writeText(this.generatePassword).then(function () {
                a.$toast("Parola kopyalandı", {
                    timeout: 1000
                });
                document.querySelector("input[name=password]").value = a.generatePassword;
                document.querySelector("input[name=re-password]").value = a.generatePassword;
            }, function (err) {
                console.error('Async: Could not copy text: ', err);
            });
        },
    },
}
</script>